import React from "react";
import { Doughnut } from "react-chartjs-2";
import boygirl from "../../../Assets/Svg/boygirl.svg";
import { Select, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

import "./donutchart.css";

const { Option } = Select;
const { Title } = Typography;

const GenderChart = () => {
  const boysData = {
    datasets: [
      {
        data: [50],
        backgroundColor: ["#FAE27C", "#F1F1F1"],
        borderWidth: 0,
        // borderRadius: 15,
      },
    ],
  };

  const girlsData = {
    datasets: [
      {
        data: [50],
        backgroundColor: ["#ffff", "#ffff"],
        borderWidth: 0,
        borderRadius: 5,
      },
    ],
  };

  const overallData = {
    datasets: [
      {
        data: [50, 50],
        backgroundColor: ["#F1F1F1", "#C3EBFA"],
        // backgroundColor: ["#00000", "#C3EBFA"],
        borderWidth: 0,
        borderRadius: [-50, 50],
      },
    ],
  };

  const options = {
    cutout: "75%", // Adjusts the inner radius size
    plugins: {
      tooltip: { enabled: false },
    },
  };

  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <div
        className="class-student-data mt-3 d-md-flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Title
          level={5}
          style={{
            margin: 0,
            fontSize: "14px",
            fontFamily: "Poppins, sans-serif",
            color: "#333",
          }}
        >
          Total Student's
        </Title>
        <Select
          allowClear
          className="class-select-btn"
          placeholder="Choose Gender"
          suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
          style={{ width: "150px" }}
          dropdownStyle={{ width: "150px" }}
        >
          <Option value="Female">Girls</Option>
          <Option value="male">Boys</Option>
        </Select>
      </div>

      <div
        style={{
          position: "relative",
          display: "inline-block",
          marginTop: "2rem",
        }}
      >
        <div style={{ width: "10rem", height: "10rem" }}>
          <div
            style={{ position: "absolute", top: 0, left: 0, width: "10rem" }}
          >
            <Doughnut
              data={overallData}
              options={options}
              width={400}
              height={400}
            />
          </div>
          <div
            style={{ position: "absolute", top: 15, left: 15, width: "8rem" }}
          >
            <Doughnut
              data={girlsData}
              options={options}
              width={300}
              height={300}
            />
          </div>
          <div
            style={{ position: "absolute", top: 24, left: 23, width: "7rem" }}
          >
            <Doughnut
              data={boysData}
              options={options}
              width={200}
              height={200}
            />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "35%",
            left: "30%",
            width: "60px",
          }}
        >
          <img src={boygirl} alt="boy and girl icon" />
        </div>
      </div>
      <div className="chart-labels">
        <div className="label present">
          <span
            className="dot"
            style={{
              backgroundColor: "#FAE27C",
            }}
          ></span>
          <span>Girls</span> <br />
          <span className="percentage"></span>
        </div>
        <div className="label absent">
          <span
            className="dot"
            style={{
              backgroundColor: "#C3EBFA",
            }}
          ></span>
          <span>Boys</span>
          <span className="percentage"></span>
        </div>
      </div>
      <div className="chart-labelss">
        <span>Girls(50%)</span>
        <span>Boys(50%)</span>
      </div>
    </div>
  );
};

export default GenderChart;

// import React from "react";
// import { Doughnut } from "react-chartjs-2";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// ChartJS.register(ArcElement, Tooltip, Legend);

// const CircularProgressChart = () => {
//   const data = {
//     datasets: [
//       {
//         data: [45, 55], // Percentage values (filled and empty parts)
//         backgroundColor: ["#28a745", "#f1f1f1"], // Colors for the segments
//         borderWidth: 0,
//         borderRadius: [10, 0], // Rounded corners only for the first segment
//         cutout: "80%", // Adjust cutout for the donut thickness
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     plugins: {
//       tooltip: {
//         enabled: false, // Disable tooltips
//       },
//     },
//     rotation: -90, // Start position of the filled segment
//     circumference: 180, // Display only a half circle
//   };

//   return (
//     <div style={{ position: "relative", width: "200px", height: "200px" }}>
//       <Doughnut data={data} options={options} />
//       <div
//         style={{
//           position: "absolute",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           fontSize: "20px",
//           fontWeight: "bold",
//           color: "#333",
//         }}
//       >
//         45%
//       </div>
//     </div>
//   );
// };

// export default CircularProgressChart;
