import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import icon from "../Assets/png/icon.png";
import "./Login.css";
import { Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
  Input,
  Button,
  Layout,
  Form,
  Checkbox,
  Typography,
  notification,
  Spin,
} from "antd";
import { sendNotify, fetchApi } from "../helper";
import { checkLogged } from "../../src/store/auth/authSlice";
//let JWT_SECRET = "A7NJ!RJTWL9%jFbFxkOb777=kcJsWdPLUIcNP8hK"

const { Content } = Layout;
const { Title, Text } = Typography;

function App() {
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [isLoder, setIsLoder] = useState(false);

  const dispatch = useDispatch();

  const login = () => {
    setIsLoder(true);
    if (!name || !pass) {
      const Message =
        !name && !pass
          ? "Please enter username and password"
          : !name
          ? "Please enter username"
          : "Please enter password";

      notification.open({
        type: "error",
        message: Message,
        className: "",
      });
      return;
    }
    if (name && pass) {
      let obj = {
        name: name,
        password: pass,
      };

      let payload = {
        method: "post",
        url: "/auth/login",
        data: obj,
      };
      fetchApi(payload)
        .then((response) => {
          console.log(response, "dfghj");
          if (response?.data?.token) {
            setIsLoder(false);
            sendNotify("success", response.massage || "successfully Login");
            localStorage.setItem(
              process.env.REACT_APP_JWT_TOKEN,
              response.data.token
            );
            dispatch(checkLogged());
          } else {
            sendNotify("error", response.message || "Login Failed");
          }
          window.location.href = "/";
        })
        .catch((error) => sendNotify("error", JSON.stringify(error)));
    }
  };

  const loginPressed = (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      login();
    }
  };
  const userNameOnChange = (value) => {
    setName(value);
  };
  const passwordOnChange = (value) => {
    setPass(value);
  };
  return (
    <Spin spinning={isLoder}>
      <Layout style={{ height: "100vh", backgroundColor: "#f0f2f5" }}>
        <Content className="login-content" style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              backgroundColor: "#005f61",
              borderRadius: "8px",
              margin: "20px",
            }}
          ></div>

          <div
            className="data-section"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "0 50px",
            }}
          >
            <Title level={2} style={{ fontWeight: "bold" }}>
              Sign In
            </Title>
            <Form
              name="signin"
              layout="vertical"
              style={{ maxWidth: "400px", width: "100%" }}
            >
              {/* Email Field */}
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  className="field_input"
                  size="large"
                  placeholder="Enter your email"
                  style={{ width: "400px", border: "none" }}
                  id="username"
                  type="text"
                  onChange={(e) => userNameOnChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Password is required!" },
                  {
                    pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
                    message: "Enter valid password",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  onKeyDown={loginPressed}
                  className="password field_input"
                  style={{
                    borderRadius: "8px",
                    width: "400px",
                  }}
                  onChange={(e) => passwordOnChange(e.target.value)}
                />
              </Form.Item>

              {/* Remember Me and Forgot Password */}
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    color: "#00535E",
                  }}
                  className="forgetpassword"
                >
                  <Checkbox>Remember me</Checkbox>
                  <Text style={{ cursor: "pointer", color: "#00535E" }}>
                    <a href="/forgetpassword"> Forgot Password?</a>
                  </Text>
                </div>
              </Form.Item>

              {/* Submit Button */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#00ADC4",
                    borderRadius: "5px",
                    marginLeft: "40%",
                  }}
                  onClick={login}
                >
                  Log In
                </Button>
              </Form.Item>
            </Form>
            <Text>
              Don’t have an account? <Link to="/register" />
              <a href="/register" style={{ color: "#00535E" }}>
                Register Here
              </a>
            </Text>
          </div>
        </Content>
      </Layout>
    </Spin>
  );
}

export default App;
