import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import Acadamicfees from "../Component/Fesscomponent/Acadamicfees";
import Transport from "../Component/Fesscomponent/Transport";
import Consolidation from "../Component/Fesscomponent/Consolidation";
const { TabPane } = Tabs;

const alldatas = [
  {
    key: 46,
    name: "SUganthi",
    employeeID: "ENG00201",
    role: "Teacher",
    "Grade&sec": "II-A",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 47,
    name: "Aisha Fatima",
    employeeID: "ENG00202",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543211",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 48,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 49,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 50,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 51,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 52,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
];

function Fees() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState("Accadamicfee");
  const [userData, setUserData] = useState(alldatas);
  useEffect(() => {
    const source = searchParams.get("tab");
    if (source) {
      setTab(source);
    }
  }, [searchParams]);

  const changeTab = (value) => {
    navigate(`/Fees?tab=${value}`);
    setTab(value);
  };

  return (
    <div>
      <div className="Tabcontainer">
        <Tabs
          activeKey={tab}
          tabBarGutter={48}
          centered
          onChange={changeTab}
          className="tabviewsubcon"
        >
          <TabPane tab="Accadamic Fee" key="Accadamicfee"></TabPane>
          <TabPane tab="Transport" key="Transport"></TabPane>
          <TabPane tab="Total Consolidate Amount" key="Consolidation"></TabPane>
        </Tabs>

        {tab === "Accadamicfee" ? (
          <Acadamicfees />
        ) : tab === "Transport" ? (
          <Transport />
        ) : tab === "Consolidation" ? (
          <Consolidation />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Fees;
