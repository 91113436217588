import React, { useState, useEffect } from "react";
import { Table, Input, Space, Avatar, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Pagination from "../pagination/index";
import TableSortArrows from "../../Component/tableSortArrows";

const alldatas = [
  {
    key: 46,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Admin",
    "Grade&sec": "II-A",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 47,
    name: "Aisha Fatima",
    employeeID: "ENG00202",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543211",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 48,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Admin",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 49,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 50,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 51,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 52,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 53,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 54,
    name: "ajmal",
    employeeID: "ENG00203",
    role: "Admin",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 55,
    name: "jhgSingh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 1,
    name: "TSingh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 56,
    name: "Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 57,
    name: "jhone",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 58,
    name: "malathi",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 59,
    name: "suganthi",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
];

function Acadamictable() {
  const [tableData, setTableData] = useState([]);
  const [userArray] = useState(alldatas);
  const [search, setSearch] = useState("");
  const [totalcount, setTotalcount] = useState(userArray.length);

  useEffect(() => {
    setTableData(userArray); // Initialize the table data with all users
  }, [userArray]);

  const searchOnChange = (value) => {
    const filteredData = userArray.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.employeeID.toLowerCase().includes(value.toLowerCase())
    );
    setTableData(filteredData);
    setSearch(value);
    setTotalcount(filteredData.length);
  };

  const columns = [
    {
      title: "Teacher Name",
      dataIndex: "name",
      width: 350,
      render: (text, record) => (
        <Space>
          {record.avatarUrl ? (
            <Avatar src={record.avatarUrl} />
          ) : (
            <Avatar size={40} style={{ backgroundColor: record.color }}>
              {record.logo}
            </Avatar>
          )}
          {text}
        </Space>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "EmployeeID",
      dataIndex: "employeeID",
      sorter: (a, b) => a.employeeID.localeCompare(b.employeeID),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "#EC7C74"}`,
            color: "#fff",
            background:
              status === "Active"
                ? "linear-gradient(98.22deg, #00ADC4 -13.56%, #00535E 133.98%)"
                : "#EC7C74",
            width: "90px",
            textAlign: "center",
            padding: "5px 0",
            borderRadius: "4px",
          }}
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  return (
    <div>
      <div>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <div className="search-id">
            <Input
              onChange={(e) => searchOnChange(e.target.value)}
              value={search}
              placeholder="Search by Employee Name or ID"
              prefix={<SearchOutlined />}
              className="seachAccadamicemployee"
            />
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          tableLayout="fixed"
          className="table-datas"
          pagination={false}
        />
        <Pagination
          data={userArray}
          onPageChange={(data) => {
            setTableData(data);
          }}
          initialItemsPerPage={10}
        />
      </div>
    </div>
  );
}

export default Acadamictable;
