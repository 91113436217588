import React, { useState, useEffect } from "react";
import EmployeeDtitals from "../Component/EmployeeDtitals";

const alldatas = [
  {
    key: 46,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Admin",
    "Grade&sec": "II-A",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: null,
  },
  {
    key: 47,
    name: "Aisha Fatima",
    employeeID: "ENG00202",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543211",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 48,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Admin",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 49,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 50,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: null,
  },
  {
    key: 51,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 52,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 53,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 54,
    name: "ajmal",
    employeeID: "ENG00203",
    role: "Admin",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 55,
    name: "jhgSingh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 1,
    name: "TSingh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 56,
    name: "Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 57,
    name: "jhone",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 58,
    name: "malathi",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 59,
    name: "suganthi",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
];

function Acadamicemployee() {
  const [userData, setUserData] = useState(alldatas);

  return (
    <>
      <EmployeeDtitals
        userData={userData}
        isEdit={false}
        count={userData.length}
      />
    </>
  );
}

export default Acadamicemployee;
