import React, { useState } from "react";
import Studentopr from "../../Component/Student/studentopr.jsx";

function ViewStudent({ data, isOpen, setIsOpen,settabVisible }) {
  const onClose = () => {
    setIsOpen(false);
    settabVisible(true);
  };

  return (
    <div>
      {/* <button onClick={() => setIsOpen(!isOpen)}>Back</button> */}
      <div>
        <div>
          <Studentopr
            record={data}
            onClose={onClose}
            type={"view"}
            selectedId={data.key}
          />
        </div>
      </div>
    </div>
  );
}

export default ViewStudent;
