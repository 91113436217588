import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Upload,
  Row,
  Col,
  message,
  DatePicker,
  Switch,
  notification,
} from "antd";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import avatar from "../../Assets/png/avatar.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
const { Option } = Select;

const AddStudentForm = ({ selectedId, onClose, record, type }) => {
  const [form] = Form.useForm();
  const [gender, setGender] = useState("");
  const [showError, setShowError] = useState(false);
  const [imageSrc, setImageSrc] = useState(avatar);

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageSrc(e.target.result);
    };
    reader.readAsDataURL(file);
    return false;
  };

  const onFinish = (values) => {
    console.log("gender", gender);
    if (gender.length <= 0) {
      setShowError(true);
    } else {
    }

    message.success(
      `${type === "edit" ? "Employee updated" : "Employee added"} successfully!`
    );
    form.resetFields();
    onClose();
  };

  const handleGenderChange = (value) => {
    setGender(value);
    setShowError(false);
  };

  const specializations = [
    { label: "Engineering", value: "engineering" },
    { label: "Management", value: "management" },
    { label: "Arts", value: "arts" },
    { label: "Science", value: "science" },
    { label: "Information Technology", value: "it" },
    { label: "Other", value: "other" },
  ];

  return (
    <div className="Accodamicwholeopr">
      {/* <h2>
        {!selectedId
          ? 'Add Employee'
          : type === 'edit'
            ? 'Edit Employee'
            : 'View Employee'}
      </h2> */}
      {/* {type === "view" ? (
        <div className="imagedivcon">
          <div className="imagedivinnercon">
            <img src={imageSrc} alt="image" />
          </div>
        </div>
      ) : 
        type ==="edit" ?(
          <div className="imagewholecontainer">
            <div className="imagedivcon">
              <div className="imagedivinnercon">
                <img src={imageSrc} alt="image" />
              </div>
            </div>
            <div className="upload">
              <Upload
                maxCount={1}
                beforeUpload={handleImageUpload}
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />} className="uploadbackground">
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
        )
      } */}
      {type == "view" ? (
        <div className="imagedivcon">
          <div className="imagedivinnercon">
            <img src={imageSrc} alt="image" />
          </div>
        </div>
      ) : (
        <div className="imagewholecontainer">
          <div className="imagedivcon">
            <div className="imagedivinnercon">
              <img src={imageSrc} alt="image" />
            </div>
          </div>
          <div className="upload">
            <Upload
              maxCount={1}
              beforeUpload={handleImageUpload}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />} className="uploadbackground">
                Upload
              </Button>
            </Upload>
          </div>
        </div>
      )}

      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={record || {}}
      >
        <h3 className="textformat">Basic Information</h3>
        {/* {(type === 'view' || type === 'edit') && (
          <div style={{ width: 200, height: 200 }}>
            <img src="https://i.pravatar.cc/40?img=12" alt="ii" style={{ width: 200, height: 200 }} />
          </div>
        )} */}
        <div className="whitebagcontainer">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: "Please Enter First Name!" },
                ]}
              >
                <Input placeholder="First Name" className="inputbox" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item name="middlename" label="Middle Name">
                <Input placeholder="Middle Name" className="inputbox" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: "Please Enter Last Name!" }]}
              >
                <Input placeholder="Last Name" className="inputbox" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="dateofbirth"
                label="Date of Birth"
                rules={[
                  { required: true, message: "Please enter Date of Birth!" },
                ]}
              >
                <DatePicker style={{ width: "100%" }} className="inputbox" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="gender"
                label="Gender"
                rules={[{ required: true, message: "Please Enter Gender!" }]}
              >
                <Select
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Select Gender"
                  className="inputbox"
                  value={gender}
                  onChange={handleGenderChange}
                >
                  <Option value="female">Female</Option>
                  <Option value="male">Male</Option>
                </Select>
                {showError && (
                  <p className="errorlabelhandle">Select your gender!</p>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="mobilenumber"
                label="Mobile Number"
                rules={[
                  { required: true, message: "Please Enter Mobile Number!" },
                ]}
              >
                <Input placeholder="Mobile Number" className="inputbox" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="employeerole"
                label="Employee Role"
                rules={[
                  { required: true, message: "Please Enter Employee Role!" },
                ]}
              >
                <Select
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Select Role"
                  style={{ width: "100%" }}
                  className="inputbox"
                >
                  <Option value="Teacher">Teacher</Option>
                  <Option value="Admin">Admin</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please Enter Email!" }]}
              >
                <Input placeholder="Email" className="inputbox" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="nationality"
                label="Nationality"
                rules={[
                  { required: true, message: "Please Enter Nationality!" },
                ]}
              >
                <Input placeholder="Nationality" className="inputbox" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={24}>
              <Form.Item
                name="address"
                label="Address"
                rules={[{ required: true, message: "Please Enter Address!" }]}
              >
                <Input
                  placeholder="Address"
                  className="inputboxempadd"
                  style={{ height: "85px" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <h3 className="textformat">Employement Information</h3>
        <div className="whitebagcontainer">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="joiningdate"
                label="Joining Date"
                rules={[
                  { required: true, message: "Please Enter Joining Date!" },
                ]}
              >
                <DatePicker style={{ width: "100%" }} className="inputbox" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="employeeId"
                label="Employee ID"
                rules={[
                  { required: true, message: "Please Enter Employee ID!" },
                ]}
              >
                <Input placeholder="Employee ID" className="inputbox" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="assignedclass"
                label="Assigned Class"
                rules={[
                  { required: true, message: "Please Enter Assigned Class!" },
                ]}
              >
                <Select
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Assigned Class"
                  style={{ width: "100%" }}
                  className="inputbox"
                >
                  <Option value="11">11</Option>
                  <Option value="12">12</Option>
                  <Option value="10">10</Option>
                  <Option value="9">9</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="subjects"
                label="Subjects"
                rules={[{ required: true, message: "Please Enter Subjects!" }]}
              >
                <Select
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Subjects"
                  style={{ width: "100%" }}
                  className="inputbox"
                >
                  <Option value="11">Tamil</Option>
                  <Option value="12">English</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="employmenttype"
                label="Employment Type"
                rules={[
                  { required: true, message: "Please Enter Employment Type!" },
                ]}
              >
                <Select
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Employment Type"
                  style={{ width: "100%" }}
                  className="inputbox"
                >
                  <Option value="11">Full Time</Option>
                  <Option value="12">Part Time</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="department"
                label="Department"
                rules={[
                  { required: true, message: "Please Enter Department!" },
                ]}
              >
                <Select
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Department"
                  style={{ width: "100%" }}
                  className="inputbox"
                >
                  <Option value="11">Science</Option>
                </Select>{" "}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <h3 className="textformat">Educational and Professional Information</h3>
        <div className="whitebagcontainer">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="qualification"
                label="Qualification"
                rules={[
                  { required: true, message: "Please Enter Qualification!" },
                ]}
              >
                <Select
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Select Qualification"
                  className="inputbox"
                  style={{ width: "100%" }} // Ensures the dropdown is responsive
                >
                  <Option value="highschool">High School</Option>
                  <Option value="bachelor">Bachelor's Degree</Option>
                  <Option value="master">Master's Degree</Option>
                  <Option value="phd">PhD</Option>
                  <Option value="diploma">Diploma</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="Specialization"
                label="specialization"
                rules={[
                  { required: true, message: "Please Enter Specialization!" },
                ]}
              >
                <Select
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Select Specialization"
                  style={{ width: "100%" }}
                  className="inputbox"
                >
                  {specializations.map((special) => (
                    <Option key={special.value} value={special.value}>
                      {special.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                name="experience"
                label="Experience"
                rules={[
                  { required: true, message: "Please Enter Experience!" },
                ]}
              >
                <Input placeholder="Experience" className="inputbox" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        {type == "edit" ? (
          <Form.Item
            label="Allow Access"
            style={{ marginTop: "20px", marginLeft: "20px" }}
            valuePropName="checked"
          >
            <Switch defaultChecked={record?.accessAllowed} />
          </Form.Item>
        ) : null}
        <div className="form-buttons">
          <Form.Item>
            {type !== "view" && (
              <Button
                type="primary"
                className="modifybutton"
                htmlType="submit"
                style={{ marginRight: "10px", width: "90px" }}
              >
                {type === "edit" ? "Update" : "Add"}
              </Button>
            )}
            <Button htmlType="button" onClick={onClose}>
              Cancel
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddStudentForm;
