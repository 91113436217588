import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

const styles = {
  background: {
    position: "absolute",
    marginTop: "30px",
    padding: "2px",
    borderRadius: "5px",
    gap: "15px",
    // display: "flex",
    alignItems: "center",
    width: "-webkit-fill-available",
    justifyContent: "space-between",
    fontFamily: "Poppins",
  },
  paginationContainer: {
    display: "flex",
    // flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    // border: "solid 1px red",

    background: "#ebeff2",
    boxShadow: "18px 18px 30px 0px #d1d9e6, -18px -18px 30px 0px #ffffff",
    fontFamily: "Poppins",
    borderRadius: "5px",
    gap: "6px",
  },
  pageButton: {
    height: "26px",
    width: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "6px",
    margin: "0 4px",
    border: "none",
    color: "#4b5563",
    cursor: "pointer",
    fontFamily: "Poppins",
  },
  activePageButton: {
    backgroundColor: "#06b6d4",
    color: "white",
    border: "none",
  },
  navButton: {
    height: "32px",
    padding: "0 16px",
    borderRadius: "6px",
    border: "none",
    color: "#4b5563",
    cursor: "pointer",
    background: "#ebeff2",
  },
  ellipsis: {
    margin: "0 8px",
    color: "#4b5563",
  },
};

const Pagination = ({
  data,
  itemsPerPage = 10,
  onPageChange,
  onlyPagtination,
  customClass,
}) => {
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / currentItemsPerPage);
  const pageOptions = [5, 10, 20, 50];

  const handlePageChange = (page) => {
    const startIndex = (page - 1) * currentItemsPerPage;
    const endIndex = Math.min(startIndex + currentItemsPerPage, totalItems);
    const visibleData = data.slice(startIndex, endIndex);

    // console.log(
    //   `Page: ${page}, Start: ${startIndex}, End: ${endIndex}, Data:`,
    //   visibleData
    // );

    setCurrentPage(page); // State update happens asynchronously
    if (onPageChange) {
      onPageChange(visibleData, page);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          style={{
            ...styles.pageButton,
            ...(currentPage === i ? styles.activePageButton : {}),
          }}
        >
          {i}
        </button>
      );
    }

    return pages;
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = Number(e.target.value);
    setCurrentItemsPerPage(newItemsPerPage);
    handlePageChange(1); // Reset to the first page when items per page changes
  };
  useEffect(() => {
    handlePageChange(1);
  }, [data, currentItemsPerPage]);

  return (
    <div
      className={
        customClass
          ? customClass
          : "d-flex flex-sm-row flex-column  align-items-center"
      }
      style={!onlyPagtination ? styles.background : {}}
    >
      {!onlyPagtination && (
        <span className="d-none d-sm-flex text-sm text-gray-600">
          Showing {(currentPage - 1) * currentItemsPerPage + 1} -{" "}
          {Math.min(currentPage * currentItemsPerPage, totalItems)} of{" "}
          {totalItems}
        </span>
      )}
      <div className="classPageIn" style={styles.paginationContainer}>
        <button
          onClick={() => handlePageChange(1)}
          style={styles.navButton}
          disabled={currentPage === 1}
        >
          First
        </button>
        <div style={{ display: "flex" }}>
          {renderPageNumbers()}
          {currentPage < totalPages - 2 && (
            <span style={styles.ellipsis}>...</span>
          )}
        </div>
        <button
          onClick={() => handlePageChange(totalPages)}
          style={styles.navButton}
          disabled={currentPage === totalPages}
        >
          Last
        </button>
      </div>
      {!onlyPagtination && (
        <div className="classPages">
          <select
            value={currentItemsPerPage}
            onChange={handleItemsPerPageChange}
            style={{
              border: "1px solid #00ADC4",
              width: "90px",
              color: " #00535E",
              borderRadius: "5px",
            }}
          >
            {pageOptions.map((option) => (
              <option key={option} value={option}>
                {option} / page
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default Pagination;
