import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Upload,
  DatePicker,
  message,
} from "antd";
import { Row, Col } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import avatar from "../../Assets/png/avatar.jpg";
import { sendNotify, fetchApi } from "../../helper";

// import './studentopr.css';
import { icon } from "../../Assets/png/icon.png";

const { Option } = Select;

const AddStudentForm = ({ selectedId, onClose, record, type }) => {
  console.log(selectedId, type, record, "accadamic");
  const formRef = useRef(null);
  const [showError, setShowError] = useState(false);
  const [gender, setGender] = useState("");
  const [form] = Form.useForm();
  const [imgsrc, setImgsrc] = useState(avatar);
  const [errors, setErrors] = useState({});
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastName] = useState("");

  const nationality = [
    { code: "AF", name: "Afghan" },
    { code: "AL", name: "Albanian" },
    { code: "DZ", name: "Algerian" },
    { code: "AD", name: "Andorran" },
    { code: "AO", name: "Angolan" },
    { code: "AG", name: "Antiguan" },
    { code: "AR", name: "Argentine" },
    { code: "AM", name: "Armenian" },
    { code: "AU", name: "Australian" },
    { code: "AT", name: "Austrian" },
    { code: "AZ", name: "Azerbaijani" },
    { code: "BS", name: "Bahamian" },
    { code: "BH", name: "Bahraini" },
    { code: "BD", name: "Bangladeshi" },
    { code: "BB", name: "Barbadian" },
    { code: "BY", name: "Belarusian" },
    { code: "BE", name: "Belgian" },
    { code: "BZ", name: "Belizean" },
    { code: "BJ", name: "Beninese" },
    { code: "BT", name: "Bhutanese" },
    { code: "BO", name: "Bolivian" },
    { code: "BA", name: "Bosnian" },
    { code: "BW", name: "Botswanan" },
    { code: "BR", name: "Brazilian" },
    { code: "BN", name: "Bruneian" },
    { code: "BG", name: "Bulgarian" },
    { code: "BF", name: "Burkinabe" },
    { code: "BI", name: "Burundian" },
    { code: "KH", name: "Cambodian" },
    { code: "CM", name: "Cameroonian" },
    { code: "CA", name: "Canadian" },
    { code: "CV", name: "Cape Verdean" },
    { code: "CF", name: "Central African" },
    { code: "TD", name: "Chadian" },
    { code: "CL", name: "Chilean" },
    { code: "CN", name: "Chinese" },
    { code: "CO", name: "Colombian" },
    { code: "KM", name: "Comorian" },
    { code: "CG", name: "Congolese" },
    { code: "CR", name: "Costa Rican" },
    { code: "HR", name: "Croatian" },
    { code: "CU", name: "Cuban" },
    { code: "CY", name: "Cypriot" },
    { code: "CZ", name: "Czech" },
    { code: "DK", name: "Danish" },
    { code: "DJ", name: "Djiboutian" },
    { code: "DM", name: "Dominican" },
    { code: "DO", name: "Dominican" },
    { code: "EC", name: "Ecuadorian" },
    { code: "EG", name: "Egyptian" },
    { code: "SV", name: "Salvadoran" },
    { code: "GQ", name: "Equatorial Guinean" },
    { code: "ER", name: "Eritrean" },
    { code: "EE", name: "Estonian" },
    { code: "ET", name: "Ethiopian" },
    { code: "FJ", name: "Fijian" },
    { code: "FI", name: "Finnish" },
    { code: "FR", name: "French" },
    { code: "GA", name: "Gabonese" },
    { code: "GM", name: "Gambian" },
    { code: "GE", name: "Georgian" },
    { code: "DE", name: "German" },
    { code: "GH", name: "Ghanaian" },
    { code: "GR", name: "Greek" },
    { code: "GD", name: "Grenadian" },
    { code: "GT", name: "Guatemalan" },
    { code: "GN", name: "Guinean" },
    { code: "GW", name: "Bissau-Guinean" },
    { code: "GY", name: "Guyanese" },
    { code: "HT", name: "Haitian" },
    { code: "HN", name: "Honduran" },
    { code: "HU", name: "Hungarian" },
    { code: "IS", name: "Icelandic" },
    { code: "IN", name: "Indian" },
    { code: "ID", name: "Indonesian" },
    { code: "IR", name: "Iranian" },
    { code: "IQ", name: "Iraqi" },
    { code: "IE", name: "Irish" },
    { code: "IL", name: "Israeli" },
    { code: "IT", name: "Italian" },
    { code: "JM", name: "Jamaican" },
    { code: "JP", name: "Japanese" },
    { code: "JO", name: "Jordanian" },
    { code: "KZ", name: "Kazakh" },
    { code: "KE", name: "Kenyan" },
    { code: "KI", name: "I-Kiribati" },
    { code: "KP", name: "North Korean" },
    { code: "KR", name: "South Korean" },
    { code: "KW", name: "Kuwaiti" },
    { code: "KG", name: "Kyrgyz" },
    { code: "LA", name: "Lao" },
    { code: "LV", name: "Latvian" },
    { code: "LB", name: "Lebanese" },
    { code: "LS", name: "Basotho" },
    { code: "LR", name: "Liberian" },
    { code: "LY", name: "Libyan" },
    { code: "LI", name: "Liechtenstein" },
    { code: "LT", name: "Lithuanian" },
    { code: "LU", name: "Luxembourgish" },
    { code: "MG", name: "Malagasy" },
    { code: "MW", name: "Malawian" },
    { code: "MY", name: "Malaysian" },
    { code: "MV", name: "Maldivian" },
    { code: "ML", name: "Malian" },
    { code: "MT", name: "Maltese" },
    { code: "MH", name: "Marshallese" },
    { code: "MR", name: "Mauritanian" },
    { code: "MU", name: "Mauritian" },
    { code: "MX", name: "Mexican" },
    { code: "FM", name: "Micronesian" },
    { code: "MD", name: "Moldovan" },
    { code: "MC", name: "Monégasque" },
    { code: "MN", name: "Mongolian" },
    { code: "ME", name: "Montenegrin" },
    { code: "MA", name: "Moroccan" },
    { code: "MZ", name: "Mozambican" },
    { code: "NA", name: "Namibian" },
    { code: "NR", name: "Nauruan" },
    { code: "NP", name: "Nepalese" },
    { code: "NL", name: "Dutch" },
    { code: "NZ", name: "New Zealander" },
    { code: "NI", name: "Nicaraguan" },
    { code: "NE", name: "Nigerien" },
    { code: "NG", name: "Nigerian" },
    { code: "NO", name: "Norwegian" },
    { code: "OM", name: "Omani" },
    { code: "PK", name: "Pakistani" },
    { code: "PW", name: "Palauan" },
    { code: "PA", name: "Panamanian" },
    { code: "PG", name: "Papua New Guinean" },
    { code: "PY", name: "Paraguayan" },
    { code: "PE", name: "Peruvian" },
    { code: "PH", name: "Filipino" },
    { code: "PL", name: "Polish" },
    { code: "PT", name: "Portuguese" },
    { code: "QA", name: "Qatari" },
    { code: "RO", name: "Romanian" },
    { code: "RU", name: "Russian" },
    { code: "RW", name: "Rwandan" },
    { code: "KN", name: "Kittitian" },
    { code: "LC", name: "Saint Lucian" },
    { code: "VC", name: "Vincentian" },
    { code: "WS", name: "Samoan" },
    { code: "SM", name: "San Marinese" },
    { code: "ST", name: "Santomean" },
    { code: "SA", name: "Saudi" },
    { code: "SN", name: "Senegalese" },
    { code: "RS", name: "Serbian" },
    { code: "SC", name: "Seychellois" },
    { code: "SL", name: "Sierra Leonean" },
    { code: "SG", name: "Singaporean" },
    { code: "SK", name: "Slovak" },
    { code: "SI", name: "Slovene" },
    { code: "SB", name: "Solomon Islander" },
    { code: "SO", name: "Somali" },
    { code: "ZA", name: "South African" },
    { code: "ES", name: "Spanish" },
    { code: "LK", name: "Sri Lankan" },
    { code: "SD", name: "Sudanese" },
    { code: "SR", name: "Surinamese" },
    { code: "SZ", name: "Eswatini" },
    { code: "SE", name: "Swedish" },
    { code: "CH", name: "Swiss" },
    { code: "SY", name: "Syrian" },
    { code: "TJ", name: "Tajik" },
    { code: "TZ", name: "Tanzanian" },
    { code: "TH", name: "Thai" },
    { code: "TL", name: "Timorese" },
    { code: "TG", name: "Togolese" },
    { code: "TO", name: "Tongan" },
    { code: "TT", name: "Trinidadian" },
    { code: "TN", name: "Tunisian" },
    { code: "TR", name: "Turkish" },
    { code: "TM", name: "Turkmen" },
    { code: "TV", name: "Tuvaluan" },
    { code: "UG", name: "Ugandan" },
    { code: "UA", name: "Ukrainian" },
    { code: "AE", name: "Emirati" },
    { code: "GB", name: "British" },
    { code: "US", name: "American" },
    { code: "UY", name: "Uruguayan" },
    { code: "UZ", name: "Uzbek" },
    { code: "VU", name: "Ni-Vanuatu" },
    { code: "VE", name: "Venezuelan" },
    { code: "VN", name: "Vietnamese" },
    { code: "YE", name: "Yemeni" },
    { code: "ZM", name: "Zambian" },
    { code: "ZW", name: "Zimbabwean" },
  ];

  const onSearch = (value) => {
    console.log("search:", value);
  };
  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgsrc(e.target.result);
    };
    reader.readAsDataURL(file);
    return false;
  };

  console.log(selectedId, type, record, "accadamic");

  const onChangeHandler = (setIdentifierState, event) => {
    let errorObj = { ...errors };
    errorObj[event.target.name] = "";
    setIdentifierState(event.target.value);
    setErrors(errorObj);
  };

  // const handlesubit = () => {
  //     if (gender.length <= 0) {
  //         setShowError(true);
  //     } else {
  //     }

  //     message.success(`${type === 'edit' ? 'student updated' : 'student added'} successfully!`);
  //     form.resetFields();
  //     onClose();
  // }

  // const handleGenderChange = (value) => {
  //     setGender(value);
  //     setShowError(false);
  // };

  const handlesubit = () => {
    // let validate = formRef?.current?.validForm();

    if (true) {
      formRef.current
        .validateFields()
        .then((values) => {
          console.log(values, "Name");
          let payload = {
            method: "post",
            url: "/student",
            data: values,
          };
          fetchApi(payload)
            .then((response) => {
              if (response?.data) {
                sendNotify("success", response.massage || "successfully Login");
              } else {
                // console.log(response.data,response.error);
                sendNotify("error", response.message || "Login Failed");
              }
            })
            .catch((error) => sendNotify("error", JSON.stringify(error)));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div>
      {!selectedId ? (
        <div className="Accodamicwholeopr">
          <Form layout="vertical" ref={formRef}>
            <div className="imagewholecontainer">
              <div className="imagedivcon">
                <div className="imagedivinnercon">
                  <img src={imgsrc} alt="image" />
                </div>
              </div>
              <div className="upload">
                <Upload
                  maxCount={1}
                  beforeUpload={handleImageUpload}
                  showUploadList={false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    className="uploadbackground"
                  >
                    Upload
                  </Button>
                </Upload>
              </div>
            </div>
            <h3 className="textformat mt-4 mb-3">Student Information</h3>

            <div className="whitebagcontainer">
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      { required: true, message: "Please enter First Name!" },
                    ]}
                  >
                    <Input placeholder="First Name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="middleName"
                    label="Middle Name"
                    // rules={[
                    //   { required: true, message: "Please enter Middle Name!" },
                    // ]}
                  >
                    <Input placeholder="Middle Name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      { required: true, message: "Please enter Last Name!" },
                    ]}
                  >
                    <Input placeholder="Last Name" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="dateOfBirth"
                    label="Date of Birth"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[
                      { required: true, message: "Please enter Gender!" },
                    ]}
                  >
                    <Select
                      placeholder="Gender"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      <Option value="Male">Male</Option>
                      <Option value="FeMale">Female</Option>
                    </Select>
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    name="nationality"
                    label="Nationality"
                    rules={[
                      { required: true, message: "Please enter Nationality!" },
                    ]}
                  >
                    <Select
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      showSearch
                      onSearch={onSearch}
                      name="nationality"
                      placeholder="Nationality"
                      className="inputbox"
                    >
                      {nationality.map((item) => (
                        <Option key={item.code}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="DOA"
                    label="Date of Admission"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Admission!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="noOfSiblings"
                    label="No Of Siblings"
                    rules={[
                      {
                        required: true,
                        message: "Please enter No Of Siblings!",
                      },
                    ]}
                  >
                    <Input placeholder="No Of Siblings" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="schoolLocation"
                    label="School Location"
                    rules={[
                      {
                        required: true,
                        message: "Please enter School Location!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="School Location"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      <Option value="11">11</Option>
                      <Option value="12">12</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    label="Admission No"
                    name="admissionNo"
                    rules={[
                      { required: true, message: "Please enter Admission No!" },
                    ]}
                  >
                    <Input placeholder="Admission No" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="governmentID"
                    label="Government ID"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Government ID!",
                      },
                    ]}
                  >
                    <Input placeholder="Government ID" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="schoolLocation"
                    label="School Location"
                    rules={[
                      {
                        required: true,
                        message: "Please enter School Location!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="School Location"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      <Option value="Science">
                        44,Thambaram,chennai -600045
                      </Option>
                      <Option value="Mathematics">
                        57,Rspuram,Coimbatore-641002
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    label="Class"
                    name="class"
                    rules={[{ required: true, message: "Please enter Class!" }]}
                  >
                    <Select
                      placeholder="select class"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      <Option value="11">11</Option>
                      <Option value="12">12</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="section"
                    label="Section"
                    rules={[
                      { required: true, message: "Please enter Section!" },
                    ]}
                  >
                    <Select
                      placeholder="select Section"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      <Option value="A">A</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="department"
                    label="Department"
                    rules={[
                      { required: true, message: "Please enter Department!" },
                    ]}
                  >
                    <Select
                      placeholder="Department"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      <Option value="Science">Science</Option>
                      <Option value="Mathematics">Mathematics</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    label="Roll No"
                    name="rollNo"
                    rules={[
                      { required: true, message: "Please enter Roll No!" },
                    ]}
                  >
                    <Input placeholder="Roll No" className="inputbox" />
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    label="Blood Group"
                    name="bloodGroup"
                    rules={[
                      { required: true, message: "Please enter Blood Group!" },
                    ]}
                  >
                    <Select
                      placeholder="Blood Group"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      <Option value="A+">A+</Option>
                      <Option value="A-">A-</Option>
                      <Option value="B+">B+</Option>
                      <Option value="B-">B-</Option>
                      <Option value="AB+">AB+</Option>
                      <Option value="AB-">AB-</Option>
                      <Option value="O+">O+</Option>
                      <Option value="O-">O-</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="religion"
                    label="Religion"
                    rules={[
                      { required: true, message: "Please enter Religion!" },
                    ]}
                  >
                    <Input placeholder="Religion" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Form.Item
                    name="address"
                    label="Address"
                    rules={[
                      { required: true, message: "Please Enter Address!" },
                    ]}
                  >
                    <Input
                      placeholder="Address"
                      className="inputboxempadd"
                      style={{ height: "85px" }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <h3 className="textformat">Father Information</h3>

            <div className="whitebagcontainer">
              <div className="d-flex justify-content-end p-1">
                <Checkbox>Primary Parent</Checkbox>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="fatherfullName"
                    label="Full name"
                    rules={[
                      { required: true, message: "Please enter Full name!" },
                    ]}
                  >
                    <Input placeholder="Full name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="fatherAadharNo"
                    label="Father Aadhar No"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Father Aadhar No!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Father Aadhar No"
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="fathermobileNumber"
                    label="Mobile Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Mobile Number!",
                      },
                    ]}
                  >
                    <Input placeholder="Mobile Number" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="fatherEmailId"
                    label="Email ID"
                    rules={[
                      { required: true, message: "Please enter Email ID!" },
                    ]}
                  >
                    <Input placeholder="Email ID" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="fatherOccupation"
                    label="Occupation"
                    rules={[
                      { required: true, message: "Please enter Occupation!" },
                    ]}
                  >
                    <Input placeholder="Occupation" className="inputbox" />
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    name="fatherAddress"
                    label="Address"
                    rules={[
                      { required: true, message: "Please enter Address!" },
                    ]}
                  >
                    <Input placeholder="Address" className="inputbox" />
                  </Form.Item>
                </div>
              </div>

              <div className="addrescheckbox">
                <Checkbox>Address Sames as Student</Checkbox>
              </div>
            </div>
            <h3 className="textformat">Mother Information</h3>

            <div className="whitebagcontainer">
              <div className="d-flex justify-content-end p-1">
                <Checkbox>Primary Parent</Checkbox>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="motherfullName"
                    label="Full Name"
                    rules={[
                      { required: true, message: "Please enter Full Name!" },
                    ]}
                  >
                    <Input placeholder="Full Name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="motherAadharNo"
                    label="Mother Aadhar No"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Mother Aadhar No!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Mother Aadhar No"
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="motherMobileNumber"
                    label="Mobile Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Mobile Number!",
                      },
                    ]}
                  >
                    <Input placeholder="Mobile Number" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="motherEmailId"
                    label="Email ID"
                    rules={[
                      { required: true, message: "Please enter Email ID!" },
                    ]}
                  >
                    <Input placeholder="Email Id" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="motherOccupation"
                    label="Occupation"
                    rules={[
                      { required: true, message: "Please enter Occupation!" },
                    ]}
                  >
                    <Input placeholder="Occupation" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="motherAddress"
                    label="Address"
                    rules={[
                      { required: true, message: "Please enter Address!" },
                    ]}
                  >
                    <Input placeholder="Address" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="addrescheckbox">
                <Checkbox>Address Sames as Student</Checkbox>
              </div>
            </div>
            <h3 className="textformat">Guardian Information</h3>

            <div className="whitebagcontainer">
              <div className="d-flex justify-content-end p-1">
                <Checkbox>Primary Parent</Checkbox>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item name="guardianFullName" label="Full Name">
                    <Input placeholder="Full Name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item name="guardianAadharNo" label="Aadhar No">
                    <Input placeholder="Aadhar No" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item name="guardianMobileNumber" label="Mobile Number">
                    <Input placeholder="Mobile Number" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item name="guardianEmailId" label="Email ID">
                    <Input placeholder="Email ID" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item name="guardianOccupation" label="Occupation">
                    <Input placeholder="Occupation" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item name="guardianAddress" label="Address">
                    <Input placeholder="Address" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="addrescheckbox">
                <Checkbox>Address Sames as Student</Checkbox>
              </div>
            </div>

            <div className="form-buttons">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="modifybutton"
                  style={{ marginRight: "8px", width: "90px" }}
                  onClick={handlesubit}
                >
                  Add
                </Button>
                <Button htmlType="button" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      ) : (
        <div
          style={{
            padding: "20px",
            backgroundColor: "#ECF0F3",
            borderRadius: "8px",
          }}
        >
          <div className="imagedivcon">
            <div className="imagedivinnercon">
              <div>
                <img
                  src={"https://i.pravatar.cc/40?img=12"}
                  alt="ii"
                  style={{ width: 212 }}
                />
              </div>
            </div>
          </div>
          <Form layout="vertical">
            <h3 className="textformat mt-4 mb-3">Student Information</h3>
            <div
              style={{
                border: "1px solid #FFFFFF",
                borderRadius: "7px",
                background: "#F7F7F7",
                background: "#F7F7F7",
                padding: "15px",
              }}
            >
              <div className="row">
                <div className="col-md-3">
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      { required: true, message: "Please enter First Name!" },
                    ]}
                  >
                    <Input placeholder="First Name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-3">
                  <Form.Item
                    name="middleName"
                    label="Middle Name"
                    rules={[
                      { required: true, message: "Please enter MiddleName!" },
                    ]}
                  >
                    <Input placeholder="Middle Name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-3">
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-3">
                  <Form.Item
                    name="dateOfBirth"
                    label="Date of Birth"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      className="inputbox"
                      placeholder="Date of Birth"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    label="Gender"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Gender"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      <Option value="Male">Male</Option>
                      <Option value="FeMale">Female</Option>
                    </Select>
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    name="nationality"
                    label="Nationality"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Input placeholder="Nationality" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="dateOfAdmission"
                    label="Date of Admission"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Admission!",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Date of admission"
                      style={{ width: "100%" }}
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="naAddress"
                    label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Input placeholder="Address" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    label="Blood Group"
                    name="bloodGroup"
                    rules={[
                      { required: true, message: "Please enterBlood Group!" },
                    ]}
                  >
                    <Select
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      <Option value="A+">A+</Option>
                      <Option value="A-">A-</Option>
                      <Option value="B+">B+</Option>
                      <Option value="B-">B-</Option>
                      <Option value="AB+">AB+</Option>
                      <Option value="AB-">AB-</Option>
                      <Option value="O+">O+</Option>
                      <Option value="O-">O-</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="religion"
                    label="Religion"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Input placeholder="Religion" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="class"
                    label="select class"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Select
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      <Option>A</Option>
                      <Option>B</Option>
                      <Option>C</Option>
                    </Select>
                    {/* <Input placeholder="select class" className='inputbox' /> */}
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="section"
                    label="section"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Input placeholder="section" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="department"
                    label="Department"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Input placeholder="Department" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
            </div>

            <h3 className="textformat">Father Information</h3>

            <div
              style={{
                border: "1px solid #FFFFFF",
                borderRadius: "7px",
                background: "#F7F7F7",
                background: "#F7F7F7",
                padding: "15px",
              }}
            >
              <div
                style={{ width: "150px", marginLeft: "85%", padding: "2px" }}
              >
                <Checkbox>Primary Parent</Checkbox>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="fatherfullName"
                    label="Full name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Input placeholder="Full name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="fatherAadharNo"
                    label="Father Aadhar No"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Father Aadhar No"
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="fathermobileNumber"
                    label="Mobile Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Input placeholder="Mobile Number" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="fatherEmailId"
                    label="Email Id"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Input placeholder="Email Id" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="fatherOccupation"
                    label="Occupation"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Input placeholder="Occupation" className="inputbox" />
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    name="fatherAddress"
                    label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <Input placeholder="Address" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              {type == "edit" ? (
                <div className="addrescheckbox">
                  <Checkbox>Address Same as Student</Checkbox>
                </div>
              ) : null}
            </div>

            <h3 className="textformat">Mother Information</h3>

            <div
              style={{
                border: "1px solid #FFFFFF",
                borderRadius: "7px",
                background: "#F7F7F7",
                background: "#F7F7F7",
                padding: "15px",
              }}
            >
              <div
                style={{ width: "150px", marginLeft: "85%", padding: "2px" }}
              >
                <Checkbox>Primary Parent</Checkbox>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="motherFullName"
                    label="Full Name"
                    rules={[
                      { required: true, message: "Please enter Full Name!" },
                    ]}
                  >
                    <Input placeholder="Full Name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="motherAadharNo"
                    label="Mother Aadhar No"
                    rules={[
                      {
                        required: true,
                        message: "Please enterMother Aadhar No!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Mother Aadhar No"
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="motherMobileNumber"
                    label="Mobile Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Mobile Number!",
                      },
                    ]}
                  >
                    <Input placeholder="Mobile Number" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="motherEmailId"
                    label="Email ID"
                    rules={[
                      { required: true, message: "Please enter Email Id!" },
                    ]}
                  >
                    <Input placeholder="Email Id" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="motherOccupation"
                    label="Occupation"
                    rules={[
                      { required: true, message: "Please enter Occupation!" },
                    ]}
                  >
                    <Input placeholder="Occupation" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="motherAddress"
                    label="Address"
                    rules={[
                      { required: true, message: "Please enter Address!" },
                    ]}
                  >
                    <Input placeholder="Address" className="inputbox" />
                  </Form.Item>
                </div>
                {type == "edit" ? (
                  <div style={{ width: "200px", marginLeft: "750px" }}>
                    <Checkbox>Address Same as Student</Checkbox>
                  </div>
                ) : null}
              </div>
            </div>
            <h3 className="textformat">Guardian Information</h3>

            <div
              style={{
                border: "1px solid #FFFFFF",
                borderRadius: "7px",
                background: "#F7F7F7",
                padding: "15px",
                boxshadow: "0px 1px 4px 0px #00000040",
              }}
            >
              <div
                style={{ width: "150px", marginLeft: "85%", padding: "2px" }}
              >
                <Checkbox>Primary Parent</Checkbox>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item name="guardianFullName" label="Full Name">
                    <Input placeholder="Full Name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item name="guardianAadharNo" label="Guardian Aadhar No">
                    <Input
                      placeholder="Guardian Aadhar No"
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item name="guardianMobileNumber" label="Mobile Number">
                    <Input placeholder="Mobile Number" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item name="guardianEmailId" label="Email ID">
                    <Input placeholder="Email ID" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item name="guardianOccupation" label="Occupation">
                    <Input placeholder="Occupation" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item name="guardianAddress" label="Address">
                    <Input placeholder="Address" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              {type == "edit" ? (
                <div style={{ width: "200px", marginLeft: "750px" }}>
                  <Checkbox>Address Same as Student</Checkbox>
                </div>
              ) : null}
            </div>

            <div className="form-buttons ">
              {type == "edit" ? (
                <Button
                  type="primary"
                  className="modifybutton"
                  // onClick={() => { handlesubit() }}
                >
                  Update
                </Button>
              ) : null}
              <Button onClick={onClose}>Cancel</Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default AddStudentForm;
