import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend,
  Tooltip,
} from "chart.js";
import { Select, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);

const { Option } = Select;
const { Title } = Typography;

const PerformanceChart = () => {
  const data = {
    labels: ["Tam", "Eng", "Mat", "Phy", "Che", "Cs", "Bio", "Zol"],
    datasets: [
      {
        label: "Fail",
        data: [20, 15, 20, 15, 12, 10, 25, 30],
        backgroundColor: "#EC7C74",
        borderRadius: 20,
        barThickness: 10,
      },
      {
        label: "Pass",
        data: [80, 85, 80, 85, 88, 90, 75, 70],
        backgroundColor: "#00ADC4",
        borderRadius: 20,
        barThickness: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          color: "rgba(0, 0, 0, 0.1)", // Light gray grid lines
          lineWidth: 0.5, // Thin grid lines
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        max: 100,
        grid: {
          color: "rgba(0, 0, 0, 0.1)", // Light gray grid lines
          lineWidth: 0.5, // Thin grid lines
        },
        ticks: {
          stepSize: 20, // Optional: Adjust tick spacing for clarity
        },
      },
    },
  };

  return (
    <div
      style={{
        position: "relative",
        height: "300px",
        textAlign: "center",
      }}
    >
      <div
        className="class-student-data mt-3 d-md-flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        {/* <h4>Student's Performance</h4> */}
        <Title
          level={5}
          style={{
            margin: 0,
            fontSize: "14px",
            fontFamily: "Poppins, sans-serif",
            color: "#333",
          }}
        >
          Student's Performance
        </Title>
        <Select
          allowClear
          className="class-select-btn"
          placeholder="Choose Class"
          suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
          style={{ width: "150px" }}
          dropdownStyle={{ width: "150px" }}
          // style={{ width: 150, borderRadius: '50px' }}
        >
          <Option value="pass">Pass</Option>
          <Option value="fail">Fail</Option>
        </Select>
      </div>

      {/* Chart */}
      <div>
        <Bar data={data} options={options} style={{ height: "250px" }} />
      </div>

      {/* Custom Legend */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: "#00ADC4",
            }}
          ></span>
          <span
            style={{ fontSize: "14px", fontFamily: "Poppins", color: "#333" }}
          >
            Pass
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: "#EC7C74",
            }}
          ></span>
          <span
            style={{
              fontSize: "14px",
              fontFamily: "Poppins, sans-serif",
              color: "#333",
            }}
          >
            Fail
          </span>
        </div>
      </div>
    </div>
  );
};

export default PerformanceChart;
