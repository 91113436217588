import React from "react";
import {
  Table,
  Avatar,
  Tag,
  // Typography,
  Select,
  Layout,
  Card,
  Typography,
  Button,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

// const { Title } = Typography;
// const { Option } = Select;

const data = [
  {
    key: "1",
    LeaveType: "Medical Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    Description: "Viral Fever",
    status: "Approved",
  },
  {
    key: "2",
    LeaveType: "Medical Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    Description: "Viral Fever",
    status: "Approved",
  },
  {
    key: "3",
    LeaveType: "Medical Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    Description: "Viral Fever",
    status: "Approved",
  },
  {
    key: "4",
    LeaveType: "Medical Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    Description: "Viral Fever",
    status: "Approved",
  },

  // Add more data as needed
];

const columns = [
  {
    title: "LeaveType",
    dataIndex: "LeaveType",
    key: "LeaveType",
    // render: (text) => <Avatar src={text} size={48} />,
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Description",
    dataIndex: "Description",
    key: "Description",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => (
      <Tag color="cyan" style={{ borderRadius: "16px", padding: "0 12px" }}>
        {status}
      </Tag>
    ),
  },
];
const cardData = {
  AnnualLeave: "3/12",
  MedicalLeave: "2/6",
  CasualLeave: "1/6",
};
const Leave = () => {
  return (
    <div>
      <div className="row g-3 pt-4 pb-5">
        {/* Cards */}
        <div className="col-md-4 col-lg-4">
          <Card
            className="class-data"
            style={{
              background: "linear-gradient(160deg, #00ADC4 0%, #00535E 100%)",
              height: "242px !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <div
                className="carddetailscon"
                style={{
                  fontSize: "2rem",
                  fontWeight: "normal",
                  marginBottom: "8px",
                  color: "#fff",
                  fontFamily: "poppins",
                }}
              >
                {cardData.AnnualLeave}
              </div>
              <div
                className="carddetailscon"
                style={{
                  fontSize: "1rem",
                  fontWeight: "normal",
                  color: "#fff",
                  fontFamily: "poppins",
                }}
              >
                Annual Leave
              </div>
            </div>
            {/* <Statistic title="Total Student's" value={cardData.totalStudents} style={{ justifyContent: 'center', alignItems: 'center', marginTop: '20px' }} /> */}
          </Card>
        </div>
        <div className="col-md-4 col-lg-4">
          <Card
            className="class-data"
            style={{
              background: "#E89494",
              color: "white",
              height: "242px !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <div
                style={{
                  fontSize: "2rem",
                  fontWeight: "normal",
                  marginBottom: "8px",
                  color: "#fff",
                  fontFamily: "poppins",
                }}
              >
                {cardData.MedicalLeave}
              </div>
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "normal",
                  color: "#fff",
                  fontFamily: "poppins",
                }}
              >
                Medical Leave
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-4 col-lg-4">
          <Card
            className="class-data"
            style={{
              background: "#585858",
              color: "white",
              height: "242px !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ alignItems: "center" }}>
              <div
                style={{
                  fontSize: "2rem",
                  fontWeight: "normal",
                  marginBottom: "8px",
                  fontFamily: "poppins",
                }}
              >
                {cardData.CasualLeave}
              </div>
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "normal",
                  color: "#fff",
                  fontFamily: "poppins",
                }}
              >
                Casual sLeave
              </div>
            </div>
            {/* <Statistic title="Total Driver's" value={cardData.totalDrivers} style={{marginTop:'20px'}} /> */}
          </Card>
        </div>
      </div>
      <div style={{ borderRadius: "8px", height: "400px" }}>
        <div
          className="head-dept class-student-data mt-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        ></div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
          className="custom-table table"
          style={{ borderRadius: "8px", overflow: "hidden" }}
        />
      </div>
    </div>
  );
};

export default Leave;
