import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tabs, Breadcrumb } from "antd";
import Driver from "../Pages/Drivers.jsx";
import Student from "./Student.jsx";
import Parent from "./Parent.jsx";
import EmployeeDtitals from "../Component/EmployeeDtitals";
import Studentdetails from "./Studentdetails.jsx";
import DriverDetails from "./DriverDetails.jsx";

const { TabPane } = Tabs;

const alldatas = [
  {
    key: 46,
    name: "SUganthi",
    employeeID: "ENG00201",
    role: "Teacher",
    "Grade&sec": "II-A",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 47,
    name: "Aisha Fatima",
    employeeID: "ENG00202",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543211",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 48,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 49,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 50,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 51,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 52,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    "Grade&sec": "11-A",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
];

function Usermanagementcon() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState("teacher");
  const [userData, setUserData] = useState(alldatas);

  useEffect(() => {
    const source = searchParams.get("tab");
    if (source) {
      setTab(source);
    }
  }, [searchParams]);

  const changeTab = (value) => {
    navigate(`/user-management?tab=${value}`);
    setTab(value);
  };
  const breadcrumbItems = [
    { path: "/department-admin", label: "User Management" },
    { path: `/department-admin?tab=${tab}`, label: tab },
    // { label: activeTab === "class" ? "Class" : "Teacher", path: "" },
  ];

  return (
    <div>
      <Breadcrumb
        separator=">>"
        style={{ marginBottom: "16px" }}
        className="custom-breadcrumb"
      >
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item key={index}>
            {index < breadcrumbItems.length - 1 ? (
              <a href={item.path}>{item.label}</a>
            ) : (
              item.label
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <div className="Tabcontainer">
        <Tabs
          activeKey={tab}
          tabBarGutter={48}
          centered
          onChange={changeTab}
          className="tabviewsubcon"
        >
          <TabPane
            tab={<p style={{ margin: "0px" }}>Teacher</p>}
            key="teacher"
          ></TabPane>
          <TabPane tab="Student" key="student"></TabPane>
          <TabPane tab="Parent" key="parent"></TabPane>
          <TabPane tab="Driver" key="driver"></TabPane>
        </Tabs>

        {tab === "teacher" ? (
          <EmployeeDtitals
            userData={userData}
            isEdit={true}
            count={userData.length}
          />
        ) : tab === "student" ? (
          <Studentdetails
            userData={userData}
            isEdit={true}
            count={userData.length}
          />
        ) : tab === "parent" ? (
          <Parent />
        ) : tab === "driver" ? (
          <DriverDetails
            userData={userData}
            isEdit={true}
            count={userData.length}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Usermanagementcon;
