import React, { useEffect, useState } from "react";
import { Layout, Card, Typography, Calendar, Button } from "antd";
import dayjs from "dayjs";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import DonutChart from "../../layout/base/charts/donutchart.jsx";
import PerformanceChart from "../../layout/base/charts/barcharts.jsx";
import FeesRevenueChart from "../../layout/base/charts/feescharts.jsx";
import TeacherAttendanceChart from "../../layout/base/charts/TeacherAttenance.jsx";
import DepartmentTable from "../../layout/base/charts/Departmenthead.jsx";
import Leave from "../../Pages/Teacher/Leave.jsx";
import AttendanceChart from "../../layout/base/charts/studentattenancechart.jsx";
import Eventmanage from "../../layout/base/charts/Eventmanage.jsx";
import "./Dasboard.css";
import { decodeToken, token } from "../../helper";
import styleLine from "../../Assets/Svg/styleLine.svg";
import userAvatar from "../../Assets/png/userAvatar.png";

const { Content } = Layout;

function Dasboardcontent() {
  const [userRole, setUserRole] = useState(null);
  const [calendarDate, setCalendarDate] = useState(dayjs());
  useEffect(() => {
    const role = decodeToken(token)?.role;
    setUserRole(role);
  }, []);
  const cardData = {
    totalDrivers: "25k",
    totalTeachers: "20k",
    totalStudents: "50k",
    ClassManaged: "10 +",
    Attendance: "75%",
    totalStudent_s: "190 +",
  };

  const handlePrevMonth = () =>
    setCalendarDate(calendarDate.subtract(1, "month"));
  const handleNextMonth = () => setCalendarDate(calendarDate.add(1, "month"));

  return (
    <Content style={{ margin: "0px", background: "#f0f2f5" }}>
      {userRole === "super-admin" && (
        <>
          <div className="row g-3 pb-5">
            {/* Cards */}
            <div className="col-md-4 col-lg-3">
              <Card
                className="class-data"
                style={{
                  background:
                    "linear-gradient(160deg, #00ADC4 0%, #00535E 100%)",
                  height: "242px !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <div
                    className="carddetailscon"
                    style={{
                      fontSize: "2rem",
                      fontWeight: "normal",
                      marginBottom: "8px",
                      color: "#fff",
                      fontFamily: "poppins",
                    }}
                  >
                    {cardData.totalStudents}
                  </div>
                  <div
                    className="carddetailscon"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "normal",
                      color: "#fff",
                      fontFamily: "poppins",
                    }}
                  >
                    Total Student's
                  </div>
                </div>
                {/* <Statistic title="Total Student's" value={cardData.totalStudents} style={{ justifyContent: 'center', alignItems: 'center', marginTop: '20px' }} /> */}
              </Card>
            </div>
            <div className="col-md-4 col-lg-3">
              <Card
                className="class-data"
                style={{
                  background:
                    "linear-gradient(160deg, #00ADC4 0%, #00535E 100%)",
                  color: "white",
                  height: "242px !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "2rem",
                      fontWeight: "normal",
                      marginBottom: "8px",
                      color: "#fff",
                      fontFamily: "poppins",
                    }}
                  >
                    {cardData.totalTeachers}
                  </div>
                  <div
                    style={{
                      fontSize: "1rem",
                      fontWeight: "normal",
                      color: "#fff",
                      fontFamily: "poppins",
                    }}
                  >
                    Total Teacher's
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-md-4 col-lg-3">
              <Card
                className="class-data"
                style={{
                  background:
                    "linear-gradient(160deg, #00ADC4 0%, #00535E 100%)",
                  color: "white",
                  height: "242px !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ alignItems: "center" }}>
                  <div
                    style={{
                      fontSize: "2rem",
                      fontWeight: "normal",
                      marginBottom: "8px",
                      fontFamily: "poppins",
                    }}
                  >
                    {cardData.totalDrivers}
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "normal",
                      fontFamily: "poppins",
                    }}
                  >
                    Total Driver's
                  </div>
                </div>
                {/* <Statistic title="Total Driver's" value={cardData.totalDrivers} style={{marginTop:'20px'}} /> */}
              </Card>
            </div>
            <div className="col-md-5 col-lg-3">
              {/* Calendar */}

              <Card
                className="classdatafca"
                style={{
                  textAlign: "center",
                  overflow: "hidden",
                  fontfamily: "poppins",
                }}
              >
                <Calendar
                  fullscreen={false}
                  value={calendarDate}
                  className="custom-calendar"
                  onChange={(date) => setCalendarDate(date)}
                  headerRender={() => (
                    <div className="d-flex justify-content-around align-items-center">
                      <Button
                        icon={<LeftOutlined />}
                        onClick={handlePrevMonth}
                        className="calendar-nav-btn"
                        style={{
                          backgroundcolor: "rgb(255, 255, 255)",
                          borderRadius: "50%",
                          width: "10%",
                          height: "50%",
                          color: "#0c8292",
                          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px",
                          border: "none",
                        }}
                      />
                      <Typography.Title
                        level={4}
                        style={{ margin: 0, textAlign: "center" }}
                      >
                        {calendarDate.format("MMMM YYYY")}
                      </Typography.Title>
                      <Button
                        icon={<RightOutlined />}
                        onClick={handleNextMonth}
                        className="calendar-nav-btn"
                        style={{
                          backgroundcolor: "rgb(255, 255,255)",
                          borderRadius: "50%",
                          width: "10%",
                          height: "50%",
                          color: "#0c8292",
                          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px",
                          border: "none",
                        }}
                      />
                    </div>
                  )}
                />
              </Card>
            </div>
          </div>
          <div className="row g-3 pb-5">
            <div className=" col-md-6 col-lg-4">
              <Card style={{ height: "400px" }}>
                <DonutChart />
              </Card>
            </div>
            <div className="col-md-6 col-lg-4">
              <Card style={{ height: "400px" }}>
                <div>
                  <PerformanceChart />
                </div>
              </Card>
            </div>
            <div className="col-md-6 col-lg-4">
              <Card style={{ height: "400px" }}>
                <Eventmanage />
              </Card>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md-6">
              <Card className="tableChart-1">
                <FeesRevenueChart />
              </Card>
            </div>
            <div className="col-md-6">
              <Card className="chartatt">
                <TeacherAttendanceChart />
              </Card>
            </div>
            <div className="col-md-6">
              <Card>
                <AttendanceChart />
              </Card>
            </div>
            <div className="col-md-6">
              <Card>
                <DepartmentTable />
              </Card>
            </div>
          </div>
        </>
      )}
      {userRole === "Teacher" && (
        <div>
          <>
            <div className="class-user_name">
              <div className="d-md-flex justify-content-between align-items-center px-4">
                <div>
                  <p1>Welcome , Mrs . Maha</p1>
                  <p>Have a Good Day</p>
                </div>
                <div className="d-flex  gap-5 align-items-end">
                  <img src={styleLine} />
                  <img src={userAvatar} />
                </div>
              </div>
            </div>
            <div className="row g-3 pt-4 pb-5">
              {/* Cards */}
              <div className="col-md-4 col-lg-3">
                <Card
                  className="class-data"
                  style={{
                    background:
                      "linear-gradient(160deg, #00ADC4 0%, #00535E 100%)",
                    height: "242px !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <div
                      className="carddetailscon"
                      style={{
                        fontSize: "2rem",
                        fontWeight: "normal",
                        marginBottom: "8px",
                        color: "#fff",
                        fontFamily: "poppins",
                      }}
                    >
                      {cardData.totalStudent_s}
                    </div>
                    <div
                      className="carddetailscon"
                      style={{
                        fontSize: "1rem",
                        fontWeight: "normal",
                        color: "#fff",
                        fontFamily: "poppins",
                      }}
                    >
                      Total Student's
                    </div>
                  </div>
                  {/* <Statistic title="Total Student's" value={cardData.totalStudents} style={{ justifyContent: 'center', alignItems: 'center', marginTop: '20px' }} /> */}
                </Card>
              </div>
              <div className="col-md-4 col-lg-3">
                <Card
                  className="class-data"
                  style={{
                    background:
                      "linear-gradient(160deg, #00ADC4 0%, #00535E 100%)",
                    color: "white",
                    height: "242px !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <div
                      style={{
                        fontSize: "2rem",
                        fontWeight: "normal",
                        marginBottom: "8px",
                        color: "#fff",
                        fontFamily: "poppins",
                      }}
                    >
                      {cardData.ClassManaged}
                    </div>
                    <div
                      style={{
                        fontSize: "1rem",
                        fontWeight: "normal",
                        color: "#fff",
                        fontFamily: "poppins",
                      }}
                    >
                      Class Managed
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-md-4 col-lg-3">
                <Card
                  className="class-data"
                  style={{
                    background:
                      "linear-gradient(160deg, #00ADC4 0%, #00535E 100%)",
                    color: "white",
                    height: "242px !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <div
                      style={{
                        fontSize: "2rem",
                        fontWeight: "normal",
                        marginBottom: "8px",
                        fontFamily: "poppins",
                      }}
                    >
                      {cardData.Attendance}
                    </div>
                    <div
                      style={{
                        fontSize: "1rem",
                        fontWeight: "normal",
                        color: "#fff",
                        fontFamily: "poppins",
                      }}
                    >
                      Attendance
                    </div>
                  </div>
                  {/* <Statistic title="Total Driver's" value={cardData.totalDrivers} style={{marginTop:'20px'}} /> */}
                </Card>
              </div>
              <div className="col-md-5 col-lg-3">
                {/* Calendar */}

                <Card
                  className="classdatafca"
                  style={{
                    textAlign: "center",
                    overflow: "hidden",
                    fontfamily: "poppins",
                  }}
                >
                  <Calendar
                    fullscreen={false}
                    value={calendarDate}
                    className="custom-calendar"
                    onChange={(date) => setCalendarDate(date)}
                    headerRender={() => (
                      <div className="d-flex justify-content-around align-items-center">
                        <Button
                          icon={<LeftOutlined />}
                          onClick={handlePrevMonth}
                          className="calendar-nav-btn"
                          style={{
                            backgroundcolor: "rgb(255, 255, 255)",
                            borderRadius: "50%",
                            width: "10%",
                            height: "50%",
                            color: "#0c8292",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px",
                            border: "none",
                          }}
                        />
                        <Typography.Title
                          level={4}
                          style={{ margin: 0, textAlign: "center" }}
                        >
                          {calendarDate.format("MMMM YYYY")}
                        </Typography.Title>
                        <Button
                          icon={<RightOutlined />}
                          onClick={handleNextMonth}
                          className="calendar-nav-btn"
                          style={{
                            backgroundcolor: "rgb(255, 255,255)",
                            borderRadius: "50%",
                            width: "10%",
                            height: "50%",
                            color: "#0c8292",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px",
                            border: "none",
                          }}
                        />
                      </div>
                    )}
                  />
                </Card>
              </div>
            </div>

            <div className="row g-3 pb-5 ">
              <div className=" col-md-6 col-lg-3  class-punchin-time">
                <Card style={{ height: "400px" }}>
                  <p>Punch In & Out</p>
                  <button className="class-punchIn d-flex  justify-content-center align-items-center">
                    Punched In
                  </button>
                  <div>
                    Time-<input type="time"></input>
                  </div>
                  {/* <DonutChart /> */}
                </Card>
              </div>
              <div className="col-md-6 col-lg-3  class-punchin-time punchin-time-1">
                <Card style={{ height: "400px" }}>
                  <div>
                    <p>Work Type -</p>
                  </div>
                </Card>
              </div>
              <div className="col-md-6 col-lg-3  class-punchin-time">
                <Card style={{ height: "400px" }}>
                  <div className="d-flex flex-column align-items-center gap-1 mt-5 pt-5 class-punchInTime">
                    <button className="Btn-1 mt-5">Punch In</button>
                    <button className="Btn-2">Punch Out</button>
                  </div>
                  <div className="punchin-time-2">
                    <p>Total Work Hours -</p>
                  </div>
                </Card>
              </div>
              <br />
              <div className="col-md-6  col-lg-3 class-punchin-event">
                <Card style={{ height: "400px" }}>
                  <div className="d-flex  justify-content-center">
                    <p>Event</p>
                  </div>
                </Card>
              </div>
            </div>
            {/* <div className="row-3"></div> */}

            {/* <div className=" col-md-6 col-lg-3">
                  <Card style={{ height: "400px" }}>
                    <DonutChart />
                  </Card>
                </div>
                <div className="col-md-6 col-lg-3">
                  <Card style={{ height: "400px" }}>
                    <div>
                      <PerformanceChart />
                    </div>
                  </Card>
                </div>
                <div className="col-md-6 col-lg-3">
                  <Card style={{ height: "400px" }}>
                    <Eventmanage />
                  </Card>
                </div> */}
            {/* <div className="col-md-6 col-lg-3">
              <Card style={{ height: "400px" }}>
                <Eventmanage />
              </Card>
            </div> */}

            <div className="row g-3 class-all_datas">
              <div className="col-md-12">
                <Card className="tableChart-1">
                  <div className="class-timeTable">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex gap-5">
                        <div>
                          <p>Today’s Class</p>
                        </div>
                        <div className="d-flex gap-3">
                          <div>
                            <Button
                              icon={<LeftOutlined />}
                              // onClick={handlePrevMonth}
                              // className="calendar-nav-btn"
                              // style={{
                              //   backgroundcolor: "rgb(255, 255, 255)",
                              //   borderRadius: "50%",
                              //   width: "10%",
                              //   height: "50%",
                              //   color: "#0c8292",
                              //   boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px",
                              //   border: "none",
                              // }}
                            />
                          </div>
                          <div>
                            <Button
                              icon={<RightOutlined />}
                              // onClick={handleNextMonth}
                              // className="calendar-nav-btn"
                              // style={{
                              //   backgroundcolor: "rgb(255, 255,255)",
                              //   borderRadius: "50%",
                              //   width: "10%",
                              //   height: "50%",
                              //   color: "#0c8292",
                              //   boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px",
                              //   border: "none",
                              // }}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <Calendar
                          fullscreen={false}
                          value={calendarDate}
                          className="custom-calendar"
                          onChange={(date) => setCalendarDate(date)}
                          headerRender={() => (
                            <div className="d-flex justify-content-around align-items-center calender-date">
                              <Button
                                icon={<LeftOutlined />}
                                onClick={handlePrevMonth}
                                className="calendar-nav-btn"
                                style={{
                                  backgroundcolor: "rgb(255, 255, 255)",
                                  borderRadius: "50%",
                                  width: "10%",
                                  height: "50%",
                                  color: "#0c8292",
                                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px",
                                  border: "none",
                                }}
                              />
                              <Typography.Title
                                level={4}
                                style={{ margin: 0, textAlign: "center" }}
                              >
                                {calendarDate.format("MMMM YYYY")}
                              </Typography.Title>
                              <Button
                                icon={<RightOutlined />}
                                onClick={handleNextMonth}
                                className="calendar-nav-btn"
                                style={{
                                  backgroundcolor: "rgb(255, 255,255)",
                                  borderRadius: "50%",
                                  width: "10%",
                                  height: "50%",
                                  color: "#0c8292",
                                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px",
                                  border: "none",
                                }}
                              />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="row class-timeOfClass">
                      <div className="col-md-2">
                        <div className="class-TimeOver">
                          <p1>09:00 Am - 09:45 Am</p1>
                          <p2>Class V - B</p2>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="class-TimeOver">
                          <p1>09:00 Am - 09:45 Am</p1>
                          <p2>Class V - B</p2>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="class-TimeOver">
                          <p1>09:00 Am - 09:45 Am</p1>
                          <p2>Class V - B</p2>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="class-TimeIn">
                          <p1>09:00 Am - 09:45 Am</p1>
                          <p2>Class V - B</p2>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="class-TimeIn">
                          <p1>09:00 Am - 09:45 Am</p1>
                          <p2>Class V - B</p2>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="class-TimeIn">
                          <p1>09:00 Am - 09:45 Am</p1>
                          <p2>Class V - B</p2>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="class-TimeIn">
                          <p1>09:00 Am - 09:45 Am</p1>
                          <p2>Class V - B</p2>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="class-TimeIn">
                          <p1>09:00 Am - 09:45 Am</p1>
                          <p2>Class V - B</p2>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-md-4">
                <Card className="table-class_datas">
                  <div>
                    <p>Student Performance</p>
                  </div>
                </Card>
              </div>
              <div className="col-md-4">
                <Card table-class_datas>
                  <div>
                    <p>Top Performer</p>
                  </div>
                </Card>
              </div>
              <div className="col-md-4">
                <Card table-class_datas>
                  <div>
                    <p>Leave Status</p>
                    <Leave />
                  </div>
                </Card>
              </div>
            </div>
          </>
        </div>
      )}
    </Content>
  );
}

export default Dasboardcontent;
