import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Select,
  Row,
  notification,
  Form,
  Col,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { fetchApi, customPagination } from "../../helper";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../pagination/index";

// import { fetchApi, customPagination } from "../../helper";

const { confirm } = Modal;

const initialData = [
  {
    key: 46,
    name: "class 8",
    Totalsections: "4",
    Department: "primary",
    subject: "Science",
    Description: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 48,
    name: "class 3",
    Totalsections: "1",
    Department: "primary",
    subject: "Maths",
    Description: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 47,
    name: "class 7",
    Totalsections: "7",
    Department: "secondary",
    subject: "English",
    Description: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 47,
    name: "class 7",
    Totalsections: "7",
    Department: "secondary",
    subject: "Science",

    Description: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 47,
    name: "class 7",
    Totalsections: "7",
    Department: "secondary",
    subject: "Science",

    Description: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 47,
    name: "class 7",
    Totalsections: "7",
    Department: "secondary",
    Description: "1,2,3 group",
    subject: "Science",

    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 47,
    name: "class 7",
    Totalsections: "7",
    Department: "secondary",
    subject: "English",

    Description: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 47,
    name: "class 7",
    Totalsections: "7",
    Department: "secondary",
    Description: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 47,
    name: "class 7",
    Totalsections: "7",
    Department: "secondary",
    Description: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 47,
    name: "class 7",
    Totalsections: "7",
    Department: "secondary",
    Description: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
];

function Class() {
  const [userData, setUserData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);
  const [totalCount, setTotalCount] = useState(initialData.length);
  const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);

  const [openModal, setOpenModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [userArray, setUserArray] = useState([]);
  // const [userData, setUserData] = useState(initialData);
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");
  const [userDataList, setUserDataList] = useState(initialData);
  const [classdetails, setClassDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(false);

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const resetModalState = () => {
    setEditingRecord(null);
    form.resetFields();
    setOpenModal(false);
  };

  const openEditModal = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      name: record.name,
      subjectID: record.subjectID,
      Grade: record.Grade,
      group: record.group,
    });
    setOpenModal(true);
  };

  const confirmDelete = (record) => {
    confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      onOk() {
        setUserData((prev) => prev.filter((item) => item.key !== record.key));
        setTotalCount((prev) => prev - 1);
        notification.success({ message: "Record deleted successfully!" });
      },
    });
  };

  const handleSearch = (value) => {
    const filtered = initialData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setTotalCount(filtered.length);
  };
  const useronChange = (value) => {
    if (value) {
      const filtered = initialData.filter(
        (a) => a.Gradesec?.toLowerCase() === value?.toLowerCase()
      );
      setUserDataList(filtered);
      setTotalcount(filtered.length);
      setUserType(value);
    } else {
      setUserDataList(initialData);
      setTotalcount(initialData.length);
      setUserType("");
    }
  };
  const searchOnChange = (value) => {
    const data = initialData.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.admissionID.toLowerCase().includes(value.toLowerCase())
    );
    setUserDataList(data);
    setSearch(value);
    setTotalcount(data.length);
  };
  const handleclassChange = (e) => {
    const { name, value } = e.target;
    setClassDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSave = (values) => {
    const handleAddclass = () => {
      let payload = {
        method: "post",
        url: "/class",
        headers: { Authorization: `Bearer ${token}` },
        data: classdetails,
      };

      fetchApi(payload)
        .then((res) => {
          console.log(res, "fetchApisedrftgyhnjkml");
        })
        .catch((err) => {
          console.error("Add Subject Api Error : ", err);
        });
    };

    console.log("fghj", values);
    // if (editingRecord) {
    //   // Edit logic
    //   setUserData((prev) =>
    //     prev.map((item) =>
    //       item.key === editingRecord.key ? { ...item, ...values } : item
    //     )
    //   );
    //   notification.success({ message: "Record updated successfully!" });
    // } else {
    //   // Add logic
    //   const newRecord = { ...values, key: Date.now() };
    //   setUserData((prev) => [...prev, newRecord]);
    //   notification.success({ message: "Record added successfully!" });
    // }
    // resetModalState();
  };

  useEffect(() => {
    setFilteredData(userData);
    setTotalCount(userData.length);
  }, [userData]);

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    { title: "Class", dataIndex: "name" },
    { title: "Total Sections", dataIndex: "Totalsections" },
    { title: "Subject", dataIndex: "subject" },
    // { title: "Description", dataIndex: "Description" },
    {
      title: "Actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            onClick={() => openEditModal(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => confirmDelete(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
        </div>
      ),
    },
  ];
  const objectLength = Object.keys(initialData).length;
  const MAX_COUNT = 8;
  const suffix = (
    <>
      <span>
        {value.length} / {MAX_COUNT}
      </span>
      <DownOutlined />
    </>
  );
  // const objectLength = Object.keys(userArray).length;
  return (
    <>
      <div className="tablecontainer">
        <div className="tableinnercontainer ">
          <div className="d-md-flex justify-content-between align-items-center ">
            <div className="search-id ">
              <Input
                placeholder="Search by Class Name"
                prefix={<SearchOutlined />}
                onChange={(e) => handleSearch(e.target.value)}
                className="seachAccadamicemployee"
              />
            </div>

            <div className="d-lg-flex align-items-center ">
              <div className="d-flex justify-content-center px-3">
                <div className="btnacccon">
                  <div className="addbtn" onClick={() => setOpenModal(true)}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="lg"
                      className="icon"
                      style={{ fontSize: "22px" }}
                    />
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Add
                    </p>
                  </div>

                  <Pagination
                    customClass="d-none d-xl-flex"
                    // class-page-show.style.display="none";
                    onlyPagtination={true}
                    data={userDataList}
                    onPageChange={(data) => {
                      setTableData(data);
                    }}
                    initialItemsPerPage={10}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          className="table-datas"
          pagination={false}
          style={{ marginTop: "20px", borderRadius: "8px" }}
        />
        <Pagination
          data={filteredData}
          onPageChange={(data) => {
            setTableData(data);
          }}
          initialItemsPerPage={10}
        />
      </div>

      <Modal
        // title={editingRecord ? "Edit Subject" : "Add Subject"}
        open={openModal}
        onCancel={resetModalState}
        onOk={() => handleSave}
        maskClosable={false}
        okText="Save"
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <h3 style={{ color: "#00ADC4", textAlign: "center" }}>
          {editingRecord ? "Edit Class" : "Add Class"}
        </h3>
        <hr />
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Class"
                name="class_name"
                rules={[
                  { required: true, message: "Please enter class name!" },
                ]}
              >
                <Input
                  style={{ width: "14rem" }}
                  placeholder="Class"
                  className="inputbox"
                  onChange={(e) => handleclassChange(e)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Total Section"
                name="total_section"
                rules={[
                  { required: true, message: "Please enter Total Section!" },
                ]}
              >
                <Select
                  className="inputbox "
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Select Section"
                  allowClear
                  onChange={(e) => handleclassChange(e)}
                  options={[
                    {
                      value: "Primary",
                      label: "Primary",
                    },
                    {
                      value: "Secondary",
                      label: "Secondary",
                    },
                    {
                      value: "Higher Secondary",
                      label: "Higher Secondary",
                    },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[{ required: true, message: "Please enter Subject!" }]}
              >
                <Select
                  className="inputboxfms"
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Select Subject"
                  mode="multiple"
                  maxCount={MAX_COUNT}
                  allowClear
                  value={value}
                  onChange={(e) => handleclassChange(e)}
                  // style={{ width: "150px" }}
                  // dropdownStyle={{ width: "230px" }}
                  options={[
                    {
                      value: "Primary",
                      label: "science",
                    },
                    {
                      value: "Secondary",
                      label: "English",
                    },
                    {
                      value: "GK",
                      label: "GK",
                    },
                    {
                      value: "Environmental science",
                      label: "Environmental science",
                    },
                    {
                      value: "Higher Secondary",
                      label: "Maths",
                    },
                    {
                      value: "Computer Science",
                      label: "Computer Science",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default Class;
