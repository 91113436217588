import React, { useState, useRef } from "react";
import { Popover } from "antd";
import { Button } from "react-bootstrap";

function Classtimetableadd({ teb }) {
  const formRef = useRef(null);

  const [formData, setFormData] = useState({});
  const [selectedColor, setSelectedColor] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const formElements = formRef.current.elements;
    const formData = {
      subjectName: formElements.subjectName.value,
      subjectCode: formElements.subjectCode.value,
      subjectTeacher: formElements.subjectTeacher.value,
      timingFrom: formElements.timingFrom.value,
      timingTo: formElements.timingTo.value,
      color: selectedColor,
    };

    console.log("Form Data:", formData);
    // You can now use `formData` for further processing
  };

  const ontablecontent = (dayIndex, index) => {
    console.log(dayIndex, index, "ontablecontent");
  };

  const hours = [
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
  ];

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  console.log(teb, "type");

  const getPopoverContent = (_hour, _dayIndex) => (
    <div
      style={{
        position: "relative",
        background: "#F7FAFD",
        borderRadius: "10px",
        padding: "15px",
        maxWidth: "300px",
        // maxWidth: "400px",
        background: "#F7FAFD",
      }}
    >
      {/* Close Button */}
      <Button
        variant="light"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          fontSize: "10px",
          padding: "0",
          lineHeight: "1",
        }}
      >
        ✖
      </Button>

      {/* Form Section */}
      <form ref={formRef}>
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="subjectName" className="form-label">
              Subject Name
            </label>
            <input
              type="text"
              className="form-control"
              id="subjectName"
              style={{ fontSize: "12px", padding: "5px", height: "30px" }}
            />
          </div>
          <div className="col">
            <label htmlFor="subjectCode" className="form-label">
              Subject Code
            </label>
            <input type="text" className="form-control" id="subjectCode" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="subjectTeacher" className="form-label">
              Subject Teacher
            </label>
            <input type="text" className="form-control" id="subjectTeacher" />
          </div>
          <div className="col">
            <label htmlFor="timingFrom" className="form-label">
              Timing
            </label>
            <div className="d-flex">
              <input
                type="text"
                className="form-control me-2"
                id="timingFrom"
                placeholder="From"
              />
              <input
                type="text"
                className="form-control"
                id="timingTo"
                placeholder="To"
              />
            </div>
          </div>
        </div>

        {/* Color Picker */}
        <div className="mb-3">
          <label className="form-label">Set Color</label>
          <div className="d-flex gap-2">
            {[
              "#36C5F0",
              "#D148EB",
              "#FAE11B",
              "#0079FF",
              "#EF5D32",
              "#A4CF00",
            ].map((color, index) => (
              <button
                key={index}
                id={`color-${index}`}
                style={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: color,
                  borderRadius: "50%",
                  border: "none",
                  cursor: "pointer",
                }}
                type="button"
                onClick={() => setSelectedColor(color)}
              ></button>
            ))}
            <button
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#36C5F0",
                borderRadius: "50%",
                border: "none",
                color: "#fff",
                fontSize: "1.5rem",
                lineHeight: "1",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              +
            </button>
          </div>
        </div>

        <button
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
          className="btn btn-primary"
        >
          Submit
        </button>
      </form>
      <div
        style={{
          position: "absolute",
          bottom: "-10px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "20px",
          height: "20px",
          background: "#F7FAFD",
          clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
        }}
      ></div>
    </div>
  );
  return (
    <div>
      {teb == "add" ? (
        <>
          <div>
            <div className="container my-5">
              <div className="form-container bg-light p-4 rounded shadow">
                {/* <form> */}
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label">Class Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Class Name"
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Select Working Days</label>
                    <div className="d-flex flex-wrap gap-2">
                      {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                        (day) => (
                          <div key={day} className="form-check me-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`day-${day}`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`day-${day}`}
                            >
                              {day}
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label">
                      Start Timing and End Timing
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        type="time"
                        className="form-control"
                        placeholder="Start Time"
                      />
                      <input
                        type="time"
                        className="form-control"
                        placeholder="End Time"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      Display Time Table in the Duration (mins)
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Duration"
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-8">
                    <label className="form-label">Setup Break Time</label>
                    <div className="d-flex gap-2">
                      <input
                        type="time"
                        className="form-control"
                        placeholder="Start Break"
                      />
                      <input
                        type="time"
                        className="form-control"
                        placeholder="End Break"
                      />
                    </div>
                  </div>
                  <div className="text-end  ">
                    <button className="btn border rounded shadow modifybutton">
                      Create
                    </button>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
          <div>
            <div className="container my-5">
              <div className="timetable-container  p-3 rounded shadow">
                <div className="row mb-2">
                  <div className="col-1"></div>
                  {days.map((day, index) => (
                    <div className="col text-center " key={index}>
                      {day}
                    </div>
                  ))}
                </div>

                {hours.map((hour, index) => (
                  <div className="row mb-1" key={index}>
                    <div className="col-1 text-end  ">{hour}</div>
                    {days.map((_, dayIndex) => (
                      <div
                        className="col border rounded"
                        key={dayIndex}
                        style={{
                          height: "100px",
                          background: "#ECF0F3",
                          //   boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
                        }}
                      ></div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : teb == "edit" ? (
        <>
          <div>
            <div className="container my-5">
              <div className="form-container bg-light p-4 rounded shadow">
                {/* <form> */}
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label">Class Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Class Name"
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Select Working Days</label>
                    <div className="d-flex flex-wrap gap-2">
                      {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                        (day) => (
                          <div key={day} className="form-check me-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`day-${day}`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`day-${day}`}
                            >
                              {day}
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="form-label">
                      Start Timing and End Timing
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        type="time"
                        className="form-control"
                        placeholder="Start Time"
                      />
                      <input
                        type="time"
                        className="form-control"
                        placeholder="End Time"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      Display Time Table in the Duration (mins)
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Duration"
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-8">
                    <label className="form-label">Setup Break Time</label>
                    <div className="d-flex gap-2">
                      <input
                        type="time"
                        className="form-control"
                        placeholder="Start Break"
                      />
                      <input
                        type="time"
                        className="form-control"
                        placeholder="End Break"
                      />
                    </div>
                  </div>
                  <div className="text-end  ">
                    <button className="btn border rounded shadow modifybutton">
                      Create
                    </button>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
          <div>
            <div className="container my-5">
              <div className="timetable-container  p-3 rounded shadow">
                <div className="row mb-2">
                  <div className="col-1"></div>
                  {days.map((day, index) => (
                    <div className="col text-center " key={index}>
                      {day}
                    </div>
                  ))}
                </div>

                {hours.map((hour, index) => (
                  <div className="row mb-1" key={index}>
                    <div className="col-1 text-end  ">{hour}</div>
                    {days.map((_, dayIndex) => (
                      <Popover
                        key={dayIndex}
                        content={getPopoverContent(hour, dayIndex)}
                        trigger="hover"
                        overlayStyle={{
                          maxWidth: "500px",
                          height: "300px",
                        }}
                      >
                        <div
                          className="col border rounded"
                          style={{
                            height: "100px",
                            background: "#ECF0F3",
                          }}
                          onClick={() => ontablecontent(dayIndex, index)}
                        >
                          {/* {dayIndex}
                          {index} */}
                        </div>
                      </Popover>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default Classtimetableadd;
