import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Select,
  Button,
  Tabs,
  Typography,
  Divider,
  Tag,
  List,
  Modal,
} from "antd";
import { PlayCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import courseheader from "../../Assets/png/courseheader.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";

import "./Viewcourse.css";
import FormItem from "antd/es/form/FormItem";
import pdf from "../../Assets/png/pdf.png";

const { Title } = Typography;

const { TabPane } = Tabs;

const Viewcourse = ({ status, onClose }) => {
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [tab, setTab] = useState("About"); // Initialize with default tab, e.g., "About".
  // State to control modal visibility
  const [showpopup, setShowPopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);

  // Function to show the modal
  const successPopup = () => {
    setShowPopup(true);
  };

  // Function to handle "Ok" click
  const handleOk = () => {
    console.log("Modal OK clicked");
    setShowPopup(false); // Close the modal
  };

  // Function to handle "Cancel" click
  const handleCancel = () => {
    console.log("Modal Cancel clicked");
    setShowPopup(false); // Close the modal
  };

  // Function for the Reject button
  const onreject = () => {
    setRejectPopup(true);
    console.log("Reject button clicked");
    // Add your rejection logic here
    onClose();
  };

  //tab view should handle with key
  const onClick = (key) => {
    setTab(key);
  };
  console.log(status, "kufdewqert");
  const renderPDFIcon = () => (
    <img
      src={pdf} // Replace with your preferred PDF icon
      alt="PDF Icon"
      style={{ width: "24px", height: "24px" }}
    />
  );

  const resource = [
    {
      title: "Linear Equation Problem and solutions",
      fileType: "PDF",
    },
    {
      title: "Linear Equation Problem and solutions",
      fileType: "PDF",
    },
    {
      title: "Linear Equation Problem and solutions",
      fileType: "PDF",
    },
    {
      title: "Linear Equation Problem and solutions",
      fileType: "PDF",
    },
    {
      title: "Linear Equation Problem and solutions",
      fileType: "PDF",
    },
    {
      title: "Linear Equation Problem and solutions",
      fileType: "PDF",
    },
    {
      title: "Linear Equation Problem and solutions",
      fileType: "PDF",
    },
    {
      title: "Linear Equation Problem and solutions",
      fileType: "PDF",
    },
    {
      title: "Linear Equation Problem and solutions",
      fileType: "PDF",
    },
    {
      title: "Linear Equation Problem and solutions",
      fileType: "PDF",
    },
  ];

  console.log(status, "gfcgchghg");

  return (
    <div style={{ padding: "20px", maxWidth: "900px", margin: "0 auto" }}>
      {/* Cover Image */}
      <Card
        style={{
          borderRadius: "16px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="row">
          {/* Image Section */}
          <div className="col-md-12">
            <img
              src={courseheader}
              // Replace with actual image
              alt="Cover"
              style={{
                width: "100%",
                borderRadius: "12px",
                objectFit: "fit",
              }}
            />
          </div>
          <div style={{ marginTop: "30px" }}>
            {/* <div className="col-md-6">
              <FormItem label="Title">
                <Input
                  value="Algebra"
                  placeholder="Title"
                  label="Title"
                  disabled
                  style={{}}
                />
              </FormItem>
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // border: "solid 1px red",
                gap: "5px",
              }}
            >
              <div style={{ width: "100%", justifyContent: "space-between" }}>
                <label>Title</label>
                <br />
                <Input
                  value="Algebra"
                  placeholder="Title"
                  disabled
                  style={{ width: "400px" }}
                />
              </div>
              <div className="col-md-6">
                <label>Uploaded By</label>
                <br />

                <Input
                  value="Sam"
                  placeholder="Uploaded By"
                  disabled
                  style={{ width: "400px" }}
                />
              </div>
            </div>

            {/* <FormItem label="">
                <Input
                  value="Sam"
                  placeholder="Uploaded By"
                  disabled
                  style={{ marginLeft: "12rem" }}
                />
              </FormItem> */}
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Category</label>
              <Input
                value="Beginner"
                placeholder="Category"
                disabled
                style={{ width: "220px", marginTop: "5px" }}
              />
            </div>
            <div className="col-md-4">
              <label>Upload Date</label>

              <Input
                value="16, Aug 2024"
                placeholder="Upload Date"
                disabled
                style={{ width: "220px", marginTop: "5px" }}
              />
            </div>
            <div className="col-md-4">
              <label>Select Class</label>

              <Input
                value="7th Grade"
                placeholder="Select Class"
                disabled
                style={{ width: "220px", marginTop: "5px" }}
              />
            </div>
          </div>
        </div>

        {/* Course Details */}
        <Card
          style={{
            borderRadius: "16px",
            marginTop: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* Tabs Section */}
          <Tabs
            defaultActiveKey="1"
            className="class-view-btn"
            style={{ marginTop: "5px" }}
            onChange={(key) => onClick(key)}
          >
            <TabPane tab="About" key="About">
              <label style={{ marginTop: "5px" }}> Description </label>
              <br />
              <Input
                placeholder="What you'll learn?"
                value="Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations."
                autoSize={{ minRows: 3 }}
                disabled
                style={{ width: "90%", height: "40px" }}
              />
            </TabPane>
            <TabPane tab="Resources" key="Resources">
              {/* <p>No resources added yet.</p> */}
            </TabPane>
          </Tabs>

          {/* Topics Covered */}
          {tab == "About" ? (
            <div>
              <div style={{ marginTop: "20px", maxHeight: "200px" }}>
                <Title level={5} style={{ color: "#4A4A4A" }}>
                  Topics Covered
                </Title>
                <div className="row">
                  {[
                    "Definition",
                    "Problems",
                    "Methods",
                    "Formulas",
                    "Example Problem",
                    "Review",
                  ].map((topic) => (
                    <div className="col-md-4" key={topic}>
                      <Tag
                        color="blue"
                        style={{
                          borderRadius: "8px",
                          width: "200px",
                          background: "#F0F0F0",
                          color: "black",
                        }}
                      >
                        {topic}
                      </Tag>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="scrollable-container">
              <List
                itemLayout="horizontal"
                dataSource={resource}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <FontAwesomeIcon
                        icon={faCloudDownloadAlt}
                        style={{ fontSize: "18px", color: "#00ADC4" }}
                      />,
                    ]}
                  >
                    <List.Item.Meta
                      // avatar={pdf()}
                      avatar={renderPDFIcon()}
                      title={item.title}
                    />
                  </List.Item>
                )}
              />
            </div>
          )}
        </Card>
        {/* Content Section */}
        <Card
          style={{
            borderRadius: "16px",
            marginTop: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Title level={5} style={{ marginBottom: "20px" }}>
            Content
          </Title>
          <List
            className="scrollable-container"
            itemLayout="horizontal"
            dataSource={[
              { title: "Overview of course", duration: "01:20" },
              { title: "Overview of course", duration: "01:20" },
              { title: "Overview of course", duration: "01:20" },
              { title: "Overview of course", duration: "01:20" },
              { title: "Overview of course", duration: "01:20" },
            ]}
            renderItem={(item) => (
              <List.Item>
                <Row
                  gutter={[16, 16]}
                  style={{ width: "100%", alignItems: "center" }}
                >
                  <Col span={4}>
                    <PlayCircleOutlined
                      style={{ fontSize: "24px", color: "#1890ff" }}
                    />
                  </Col>
                  <Col span={14}>
                    <div>{item.title}</div>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <div>{item.duration}</div>
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </Card>

        {/* Action Buttons */}
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          {status === "Pending" ? (
            <div>
              <Button className="appbtn" onClick={successPopup}>
                Approve
              </Button>
              <Modal
                open={showpopup}
                className="modalcon"
                footer={[
                  <Button
                    key="close"
                    type="primary"
                    onClick={handleCancel}
                    className="cancelbtn"
                  >
                    Close
                  </Button>,
                ]}
              >
                <div>
                  <p className="popupmsg">The Course has been Published</p>
                  <p className="popupsuccmsg"> Successfully</p>
                </div>
              </Modal>
              <Button className="rejectbtn" onClick={onreject}>
                Reject
              </Button>
              <Modal
                open={rejectPopup}
                className="modalcon"
                footer={[
                  <Button
                    key="close"
                    type="primary"
                    onClick={handleCancel}
                    className="cancelbtn"
                  >
                    Close
                  </Button>,
                ]}
              >
                <div>
                  <p className="popupmsg">The Course has been Rejected</p>
                </div>
              </Modal>
            </div>
          ) : (
            <Button onClick={onClose}>Cancel</Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Viewcourse;
