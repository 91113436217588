import React from "react";
import { Table, Avatar, Tag, Typography, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
const { Title } = Typography;
const { Option } = Select;

const data = [
  {
    key: "1",
    profile: "https://randomuser.me/api/portraits/men/1.jpg",
    name: "Samuel M S",
    subject: "Mathematics",
    status: "Active",
  },
  {
    key: "2",
    profile: "https://randomuser.me/api/portraits/men/1.jpg",
    name: "Samuel M S",
    subject: "Mathematics",
    status: "Active",
  },
  {
    key: "3",
    profile: "https://randomuser.me/api/portraits/men/1.jpg",
    name: "Samuel M S",
    subject: "Mathematics",
    status: "Active",
  },
  {
    key: "4",
    profile: "https://randomuser.me/api/portraits/men/1.jpg",
    name: "Samuel M S",
    subject: "Mathematics",
    status: "Active",
  },

  // Add more data as needed
];

const columns = [
  {
    title: "Profile",
    dataIndex: "profile",
    key: "profile",
    render: (text) => <Avatar src={text} size={48} />,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => (
      <Tag color="cyan" style={{ borderRadius: "16px", padding: "0 12px" }}>
        {status}
      </Tag>
    ),
  },
];

const DepartmentTable = () => {
  return (
    <div style={{ borderRadius: "8px", height: "400px" }}>
      <div
        className="head-dept class-student-data mt-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Title level={5} style={{ margin: 0 }}>
          Head of Department
        </Title>
        <Select
          allowClear
          className="class-select-btn"
          placeholder="Choose Department"
          suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
          style={{ width: "150px" }}
          dropdownStyle={{ width: "150px" }}
        >
          <Option value="mathematics">Mathematics</Option>
          <Option value="science">Science</Option>
          <Option value="english">English</Option>
        </Select>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered={false}
        className="custom-table table"
        style={{ borderRadius: "8px", overflow: "hidden" }}
      />
    </div>
  );
};

export default DepartmentTable;
