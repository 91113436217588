import React, { useState } from "react";
import { Modal, Table, Input, Form, Select, Space, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../Component/pagination/index";
import TableSortArrows from "../tableSortArrows";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import bin from "../../Assets/Svg/bin.png";
import eye from "../../Assets/Svg/eye.png";
import edit from "../../Assets/Svg/edit.png";
import Feesadd from "../Fees/Feesadd";

const { confirm } = Modal;
const { Option } = Select;

const allData = [
  {
    key: 1,
    name: "Accadamic Fees",
    employeeID: "ENG00201",
    role: "Admin",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
    class: "Class 1", // Added class
    feesStructure: 40000, // Added fees structure
  },
  {
    key: 2,
    name: "Accadamic Fee",
    employeeID: "ENG00202",
    role: "Admin",
    phoneNumber: "+91 9876543211",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
    class: "Class 2", // Added class
    feesStructure: 40000, // Added fees structure
  },
  {
    key: 3,
    name: "Accadamic Fee",
    employeeID: "ENG00203",
    role: "Admin",
    phoneNumber: "+91 9876543212",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    class: "Class 3", // Added class
    feesStructure: 40000, // Added fees structure
  },
  {
    key: 4,
    name: "Accadamic Fee",
    employeeID: "ENG00204",
    role: "Admin",
    phoneNumber: "+91 9876543213",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=4",
    class: "Class 4", // Added class
    feesStructure: 40000, // Added fees structure
  },
  {
    key: 5,
    name: "Accadamic Fee",
    employeeID: "ENG00204",
    role: "Admin",
    phoneNumber: "+91 9876543213",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=4",
    class: "Class 4", // Added class
    feesStructure: 40000, // Added fees structure
  },
  {
    key: 6,
    name: "Accadamic Fee",
    employeeID: "ENG00204",
    role: "Admin",
    phoneNumber: "+91 9876543213",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=4",
    class: "Class 4", // Added class
    feesStructure: 40000, // Added fees structure
  },
];

function Transport() {
  const [tableData, setTableData] = useState("");
  const [userType, setUserType] = useState("");
  const [modelOpen, setModelOpen] = useState(false);
  const [userDataList, setUserDataList] = useState("");
  const [totalCount, setTotalcount] = useState("");
  const [type, setType] = useState("");
  const [selectedId, setselectedId] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [model, setModel] = useState(false);

  const handlemodel = () => {
    setModel(true);
  };
  const handleCancel = () => {
    setModel(false);
  };
  const handleOk = () => {
    setModel(false);
  };

  const openOnChange = (value, type) => {
    if (type == "edit" || type == "view") {
      setselectedId(value.key);
      // setModelOpen(true);
      setType(type);
      setSelectedData(value);
    } else if (type == "add") {
      // setModelOpen(true);
      setType(type);
      setSelectedData({});
    }
  };

  const useronChange = async (value) => {
    if (value) {
      let finded = allData.filter((a) => a.Gradesec == value);
      setUserDataList(finded);
      setTotalcount(finded.length);
      setUserType(value);
    } else {
      setUserDataList(allData);
      setTotalcount(allData.length);
      setUserType("");
    }
  };
  const onClose = (action) => {
    setselectedId("");
    // setModelOpen(false);

    setSelectedData({});

    // if (action === 'save') {
    //   message.success('Draft saved successfully!');
    // } else if (action === 'publish') {
    //   message.success('Published successfully!');
    // }
  };
  console.log(tableData, "dfghj");

  const columns = [
    {
      title: <input type="checkbox" />,
      dataIndex: "checkbox",
      render: () => <input type="checkbox" />,
    },
    {
      title: "Fee Name",
      dataIndex: "name",

      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Class",
      dataIndex: "class",

      sorter: (a, b) => a.employeeID.localeCompare(b.employeeID),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Fees Structure",
      dataIndex: "feesStructure",

      sorter: (a, b) => a.role.localeCompare(b.role),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <>
          <img
            src={edit}
            alt="Delete"
            style={{
              width: "20px",
              height: "20px",
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange({}, "edit");
            }}
          />
          <img
            src={eye}
            alt="Delete"
            style={{
              width: "20px",
              height: "20px",
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange({}, "view");
            }}
          />
          <img
            src={bin}
            alt="Delete"
            style={{
              width: "20px",
              height: "20px",
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      {/* {!modelOpen ? ( */}
      <div className="tablecontainer">
        <div className="tableinnercontainer ">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div className="search-id">
              <Input
                // onChange={(e) => searchOnChange(e.target.value)}
                // value={search}
                placeholder="Search Transport fee"
                prefix={<SearchOutlined />}
                className="seachAccadamicemployee"
              />
            </div>

            <div className="nav-sectionBar">
              <div className="d-flex align-items-center gap-3">
                <div className="wholeselect">
                  <Select
                    value={userType || undefined}
                    onChange={useronChange}
                    className="selectdiv"
                    allowClear
                    suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    placeholder="Acadamic year"
                    style={{ width: "150px" }}
                    dropdownStyle={{ width: "150px" }}
                    options={[
                      {
                        value: "2020",
                        label: "2020",
                      },
                      {
                        value: "2022",
                        label: "2022",
                      },
                    ]}
                  />
                </div>

                <div className="btnaligwitselect">
                  <div>
                    <div
                      className="addbtn"
                      onClick={() => {
                        handlemodel();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        size="lg"
                        className="icon"
                        style={{ fontSize: "22px" }}
                      />
                      <p
                        style={{
                          margin: "0px",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Add
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          tableLayout="fixed"
          className=""
          pagination={false}
        />
        <Pagination
          data={allData}
          onPageChange={(data) => {
            setTableData(data);
          }}
          initialItemsPerPage={10}
        />
      </div>
      <Modal open={model} onCancel={handleCancel} onOk={handleOk}>
        <div className="row">
          <div className="col-md-6 ">
            <Form.Item name="dueDate" label="Fee Name">
              <Input placeholder="" />
            </Form.Item>
          </div>

          <div className="col-md-6 ">
            <Form.Item name="class" label="Class">
              <Input placeholder="" />
            </Form.Item>
          </div>
          <div className="col-md-6 ">
            <Form.Item name="totalfee" label="Total Fees">
              <Input placeholder="" />
            </Form.Item>
          </div>
        </div>
      </Modal>{" "}
      {/* ) : ( */}
      <>
        {/* <Feesadd
            // selectedData={selectedData}
            tableData={tableData}
            className="showGst"
            onlyvalue={true}
            onClose={onClose}
            type={type}
            selectedId={selectedId}
          /> */}
      </>
      {/* )} */}
    </div>
  );
}

export default Transport;
