import {SET_SUB_BREAD_CRUM} from '../action/Subbreadcrumaction'

const initialState = {
    subBreadCrum :'Welcome'
} 

export const subBreadCrumreducer =(state=initialState,action)=>{
switch (action.type) {
    case SET_SUB_BREAD_CRUM:
        return{
            ...state,subBreadCrum:action.payload
        }

    default:
        return state;
}
}