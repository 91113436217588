import React, { useState } from "react";
import {
  Table,
  Avatar,
  Tag,
  // Typography,
  Select,
  Layout,
  Card,
  Typography,
  Button,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Table } from "antd";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ViewStudent from "../../Component/Departmentadmin/Departmenttable.jsx";
// import { customPagination } from "../../helper";
import Pagination from "../../Component/pagination/index.jsx";

const cardData = {
  AnnualLeave: "3/12",
  MedicalLeave: "2/6",
  CasualLeave: "1/6",
};

const data = [
  {
    key: "1",
    name: "John Doe",
    grade: "10-B",
    age: 16,
    Mobileno: "7603992425",
    Admission: "Emp001",
  },
  {
    key: "2",
    name: "Jane Smith",
    grade: "11-A",
    age: 17,
    Mobileno: "7603992425",
    Admission: "Emp002",
  },
  {
    key: "3",
    name: "Alice Johnson",
    grade: "12-A",
    age: 18,
    Mobileno: "7603992425",
    Admission: "Emp003",
  },
];

const Grades = ({ setIsOpen, isOpen, settabVisible }) => {
  const [userArray, setUserArray] = useState(data);
  const [moduleOpen, setModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [teb, setTeb] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);

  const openOnChange = (value, teb) => {
    settabVisible(false);
    if (teb === "view") {
      setModuleOpen(true);
      setSelectedDate(value);
    } else {
      setModuleOpen(false);
      setSelectedDate({});
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    { title: "Admission", dataIndex: "Admission", key: "Admission" },
    { title: "Mobile No", dataIndex: "Mobileno", key: "Mobileno" },
    {
      title: "Action",
      dataIndex: "status",
      align: "center",
      render: (_, record) => (
        <div>
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
        </div>
      ),
    },
  ];
  console.log(isOpen, "isopen");

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const objectLength = Object.keys(userArray).length;

  return (
    <div>
      <>
        {!moduleOpen ? (
          <div className="studenttable">
            {/* <button onClick={() => setIsOpen(!isOpen)}>Back1</button> */}
            {/* <h4>Student Table</h4> */}

            <Table
              dataSource={tableData}
              columns={columns}
              pagination={false}
            />
            <Pagination
              data={userArray}
              onPageChange={(data) => {
                setTableData(data);
              }}
              initialItemsPerPage={10}
            />
          </div>
        ) : (
          <ViewStudent
            data={selectedDate}
            isOpen={moduleOpen}
            setIsOpen={setModuleOpen}
            settabVisible={settabVisible}
            pagination={false}
          />
        )}
      </>
    </div>
  );
};
export default Grades;
