import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Button,
  Badge,
  Avatar,
  Select,
  Space,
  notification,
  Tag,
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// import './student.css'
import Sidebar from "../layout/base/Sidebar";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Studentopr from "../Component/Student/studentopr.jsx";
import Studentdetails from "./Studentdetails.jsx";
const { confirm } = Modal;

const alldatas = [
  {
    key: 46,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    Gradesec: "II-A",
    phoneNumber: "+91 9876543210",
    uniqueno: "61543677",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 47,
    name: "Aisha Fatima",
    employeeID: "ENG00202",
    Gradesec: "11-A",
    phoneNumber: "+91 9876543211",
    uniqueno: "61543678",

    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 48,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    Gradesec: "11-A",
    phoneNumber: "+91 9876543212",
    uniqueno: "61543679",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
];

function Student() {
  const [userData, setUserData] = useState(alldatas);
  return (
    <>
      <Studentdetails
        userData={userData}
        isEdit={false}
        count={userData.length}
      />
    </>
  );
}

export default Student;
