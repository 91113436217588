import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Select,
  Row,
  notification,
  Form,
  Col,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { fetchApi, customPagination } from "../../helper";
import Pagination from "../pagination/index";

const { Option } = Select;
const { confirm } = Modal;

const initialData = [
  {
    key: 46,
    name: "Computer Science",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 45,
    name: " Science",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 46,
    name: " tamil",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 47,
    name: " tamil",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 48,
    name: " tamil",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 49,
    name: " tamil",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 50,
    name: " tamil",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 51,
    name: " tamil",
    subjectID: "TAM123IL",
    Grade: "11-E",
    group: "1,2,3 group",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  // Ensure all records have consistent keys.
];
function Subject() {
  const [userData, setUserData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);
  const [totalCount, setTotalCount] = useState(initialData.length);
  const [openModal, setOpenModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [userArray, setUserArray] = useState([]);
  // const [userData, setUserData] = useState(initialData);
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");
  const [userDataList, setUserDataList] = useState(initialData);
  const [form] = Form.useForm();
  const [subjectDetails, setSubjectDetails] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(false);
  const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);
  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const resetModalState = () => {
    setEditingRecord(null);
    form.resetFields();
    setOpenModal(false);
  };

  const openEditModal = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      name: record.name,
      subjectID: record.subjectID,
      Grade: record.Grade,
      group: record.group,
    });
    setOpenModal(true);
  };

  const confirmDelete = (record) => {
    confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      onOk() {
        setUserData((prev) => prev.filter((item) => item.key !== record.key));
        setTotalCount((prev) => prev - 1);
        notification.success({ message: "Record deleted successfully!" });
      },
    });
  };

  const handleSearch = (value) => {
    const filtered = userData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setTotalCount(filtered.length);
  };
  const useronChange = (value) => {
    if (value) {
      const filtered = initialData.filter(
        (a) => a.Gradesec?.toLowerCase() === value?.toLowerCase()
      );
      setUserDataList(filtered);
      setTotalcount(filtered.length);
      setUserType(value);
    } else {
      setUserDataList(initialData);
      setTotalcount(initialData.length);
      setUserType("");
    }
  };
  const searchOnChange = (value) => {
    const data = initialData.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.admissionID.toLowerCase().includes(value.toLowerCase())
    );
    setUserDataList(data);
    setSearch(value);
    setTotalcount(data.length);
  };

  const handleSave = (values) => {
    if (editingRecord) {
      // Edit logic
      setUserData((prev) =>
        prev.map((item) =>
          item.key === editingRecord.key ? { ...item, ...values } : item
        )
      );
      let payload = {
        method: "put",
        url: `api/v1/subject/${editingRecord.key}`,
        data: editingRecord,
      };
      fetchApi(payload)
        .then((res) => {
          if (res.message) {
            notification.open({
              type: "success",
              message: "Record updated successfully!",
              className: "",
            });
          } else if (res?.error) {
            notification.open({
              type: "error",
              message: "Record updated field!",
              className: "",
            });
          }
        })
        .catch((error) => {
          notification.open({
            type: "error",
            message: error.message,
            className: "",
          });
        });
    } else {
      // Add logic

      const newRecord = { ...values, key: Date.now() };
      setUserData((prev) => [...prev, newRecord]);
      let payload = {
        method: "post",
        url: "api/v1/subject",
        data: newRecord,
      };
      fetchApi(payload)
        .then((res) => {
          if (res.message) {
            notification.open({
              type: "success",
              message: "record added successfully!",
              className: "",
            });
          } else if (res?.error) {
            notification.open({
              type: "error",
              message: "record add field!",
              className: "",
            });
          }
        })
        .catch((error) => {
          notification.open({
            type: "error",
            message: error.message,
            className: "",
          });
        });
    }
    resetModalState();
  };

  useEffect(() => {
    setFilteredData(userData);
    setTotalCount(userData.length);
  }, [userData]);

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    { title: "Subject Name", dataIndex: "name", align: "center" },
    { title: "Subject Code", dataIndex: "subjectID", align: "center" },

    {
      title: "Actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            onClick={() => openEditModal(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => confirmDelete(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
        </div>
      ),
    },
  ];

  const objectLength = Object.keys(userArray).length;
  const handleSubjectChange = (e) => {
    const { name, value } = e?.target;
    setSubjectDetails({ ...subjectDetails, [name]: value });
  };
  const handleAddSubject = () => {
    let payload = {
      method: "post",
      url: "/subject",
      headers: { Authorization: `Bearer ${token}` },
      data: subjectDetails,
    };

    fetchApi(payload)
      .then((res) => {
        console.log(res, "fetchApisedrftgyhnjkml");
      })
      .catch((err) => {
        console.error("Add Subject Api Error : ", err);
      });
  };

  const handleGetSubject = () => {
    let payload = {
      method: "get",
      url: "/api/v1/subjects",
      headers: { Authorization: `Bearer ${token}` },
    };

    fetchApi(payload)
      .then((res) => {
        console.log(res, "fetchApi Get Subject Response");
      })
      .catch((err) => {
        console.error("Get Subject Api Error: ", err);
      });
  };

  console.log(subjectDetails, "rdxtcfgvhbjnkml");
  return (
    <>
      <div className="tablecontainer">
        <div className="tableinnercontainer">
          <div className="d-md-flex justify-content-between align-items-center ">
            <div className="search-id">
              <Input
                placeholder="Search by Subject Name"
                prefix={<SearchOutlined />}
                onChange={(e) => handleSearch(e.target.value)}
                className="seachAccadamicemployee"
              />
            </div>
            {/* <div className="nav-sectionBar"> */}
            <div className="d-lg-flex  align-items-center ">
              <div className="d-flex justify-content-center px-3">
                <Select
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  className="wholeselect"
                  placeholder="Select type"
                  // style={{ margin: 0, marginLeft: "2rem" }}
                  // onChange={handleChange}
                  allowClear
                  // value={action}
                  options={[
                    {
                      value: "primary",
                      label: "primary",
                    },
                    {
                      value: "secondary",
                      label: "secondary",
                    },
                    {
                      value: "HigherSecondary",
                      label: "HigherSecondary",
                    },
                  ]}
                />
              </div>
              {/* <div className="addbtnaccsettsubject"> */}
              <div className="d-flex justify-content-center px-3">
                <div className="btnacccon">
                  <div className="addbtn" onClick={() => setOpenModal(true)}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="lg"
                      className="icon"
                      style={{ fontSize: "22px" }}
                    />
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Add
                    </p>
                  </div>
                  <Pagination
                    customClass="d-none d-xl-flex"
                    // class-page-show.style.display="none";
                    onlyPagtination={true}
                    data={userDataList}
                    onPageChange={(data) => {
                      setTableData(data);
                    }}
                    initialItemsPerPage={10}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          className="table-datas"
          style={{ marginTop: "20px", borderRadius: "8px" }}
        />
        <Pagination
          data={filteredData}
          onPageChange={(data) => {
            setTableData(data);
          }}
          initialItemsPerPage={10}
        />
      </div>

      <Modal
        open={openModal}
        onCancel={resetModalState}
        maskClosable={false}
        onOk={() => handleAddSubject()}
        okText="Save"
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <h3 style={{ color: "#00ADC4", textAlign: "center" }}>
          {editingRecord ? "Edit Subject" : "Add Subject"}
        </h3>
        <hr />
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Subject Name"
                name="subject_name"
                rules={[
                  { required: true, message: "Please enter subject name!" },
                ]}
              >
                <Input
                  className="inputbox"
                  name="subject_name"
                  onChange={(e) => handleSubjectChange(e)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Subject Code"
                name="subject_code"
                rules={[
                  { required: true, message: "Please enter subject code!" },
                ]}
              >
                <Input
                  className="inputbox"
                  name="subject_code"
                  onChange={(e) => handleSubjectChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default Subject;
