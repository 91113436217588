import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Select, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

const { Option } = Select;
const { Title } = Typography;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const FeesRevenueChart = () => {
  const [selectedDepartment, setSelectedDepartment] = useState("all");

  // Sample data for the departments
  const departmentData = {
    all: {
      revenue: [
        50000, 100000, 80000, 120000, 150000, 130000, 140000, 120000, 150000,
        170000, 160000, 200000,
      ],
      fees: [
        30000, 60000, 40000, 90000, 120000, 100000, 110000, 95000, 125000,
        140000, 130000, 180000,
      ],
    },
    mathematics: {
      revenue: [
        40000, 90000, 70000, 100000, 130000, 110000, 120000, 100000, 130000,
        150000, 140000, 180000,
      ],
      fees: [
        20000, 50000, 30000, 80000, 100000, 90000, 100000, 85000, 105000,
        120000, 110000, 150000,
      ],
    },
    science: {
      revenue: [
        30000, 70000, 50000, 80000, 100000, 90000, 110000, 90000, 110000,
        140000, 130000, 170000,
      ],
      fees: [
        10000, 40000, 20000, 70000, 90000, 80000, 90000, 75000, 95000, 110000,
        100000, 140000,
      ],
    },
    english: {
      revenue: [
        45000, 85000, 65000, 110000, 140000, 125000, 135000, 115000, 145000,
        165000, 155000, 190000,
      ],
      fees: [
        25000, 55000, 35000, 85000, 115000, 95000, 105000, 90000, 120000,
        135000, 125000, 170000,
      ],
    },
  };

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value || "all");
  };

  const department = departmentData[selectedDepartment] || {
    revenue: [],
    fees: [],
  };

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Revenue",
        data: department?.revenue,
        borderColor: "#6ea8fe",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(143, 124, 250,0.5)");
          gradient.addColorStop(1, "rgba(226, 247, 210, 0.5)");
          return gradient;
        },
        fill: true,
        borderWidth: 0,
        pointRadius: 0,
        tension: 0.4,
      },
      {
        label: "Fees",
        data: department?.fees,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, "rgba(0, 173, 196, 1)"); // Adjust opacity to reduce merging
          gradient.addColorStop(1, "rgba(0, 83, 94, 1)");
          return gradient;
        },
        borderWidth: 0,
        pointRadius: 0,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `₹ ${context.raw.toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value >= 100000 ? `${value / 100000} L` : `${value} K`;
          },
        },
        grid: {
          color: "#f0f0f0",
        },
      },
    },
  };

  return (
    <div style={{ margin: "auto", maxWidth: "100%" }}>
      <div
        className="class-student-data mt-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Title
          level={5}
          style={{
            margin: 0,
            fontSize: "14px",
            fontFamily: "Poppins",
            color: "#333",
          }}
        >
          Fees & Revenue
        </Title>
        <Select
          allowClear
          className="class-select-btn"
          placeholder="Choose Year"
          suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
          onChange={handleDepartmentChange}
          style={{ width: "150px" }}
          dropdownStyle={{ width: "150px" }}
        >
          {/* <Option value="All">Choose Year</Option> */}
          <Option value="mathematics">2000</Option>
          <Option value="science">2010</Option>
          <Option value="english">2020</Option>
        </Select>
      </div>
      <Line data={data} options={options} className="linecharts" />
    </div>
  );
};

export default FeesRevenueChart;
