import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Layout, Menu, Typography, Tabs, Breadcrumb, Button } from "antd";
import Institute from "../Component/Institute/Institute";
import Subject from "../Component/Subject/Subject";
import Class from "../Component/Class/Class";
import Vehicle from "../Component/vehicle/vehicle";
import Department from "../Component/Department/Department";
import Billing from "../Component/Billing/Billing";
import Classinfo from "../Component/Departmentadmin/Classinfo";
import Classtimetable from "../Component/Departmentadmin/Classtimetable";
import Coursemanaegement from "../Component/Departmentadmin/Coursemanagement";
import Teacherupload from "../Component/Departmentadmin/Teacherupload";

const { TabPane } = Tabs;
function DepartmentAdmincon() {
  const navigate = useNavigate();
  const { source } = useParams();
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState("Class info");

  useEffect(() => {
    const source = searchParams.get("tab");
    if (source) {
      setTab(source);
    }
  }, [searchParams]);

  // console.log(source, "3a4wse5drftigyui");
  const changeTab = (value) => {
    navigate(`/department-admin?${value}`);
    setTab(value);
  };
  const breadcrumbItems = [
    { path: "/department-admin", label: "Department Admin" },
    { path: `/department-admin?tab=${tab}`, label: tab },
    // { label: activeTab === "class" ? "Class" : "Teacher", path: "" },
  ];

  return (
    <div>
      <Breadcrumb
        separator=">>"
        style={{ marginBottom: "16px" }}
        className="custom-breadcrumb"
      >
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item key={index}>
            {index < breadcrumbItems.length - 1 ? (
              <a href={item.path}>{item.label}</a>
            ) : (
              item.label
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <div className="Tabcontainer">
        <Tabs
          defaultActiveKey={tab}
          tabBarGutter={48}
          centered
          onChange={changeTab}
          // activeKey={source || ""}
          className="tabviewsubcon"
        >
          <TabPane
            tab={<p style={{ margin: "0px" }}>Class info</p>}
            key="Class info"
            className="tabbtn"
          ></TabPane>
          <TabPane
            tab={<p style={{ margin: "0px" }}>Class Timetable</p>}
            key="classtime table"
          ></TabPane>
          <TabPane
            tab={<p style={{ margin: "0px" }}>Course Management</p>}
            key="Course Management"
          ></TabPane>
        </Tabs>

        {tab == "Class info" ? (
          <Classinfo />
        ) : tab == "classtime table" ? (
          <Classtimetable />
        ) : tab == "Course Management" ? (
          <Coursemanaegement />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default DepartmentAdmincon;
