import React from "react";
import DriverDetails from "../../Pages/DriverDetails";
import Studenttable from "../../Component/Report/Studenttable";
import Acadamicemployee from "../../Component/Report/Acadamictable";
import Drivertable from "./Drivertable";
function index({ type }) {

    

  return (
    <div>
      {type == "studenttable" ? (
        <>
         <Studenttable />
        </>
      ) : type == "employeetable"?(
        <>
            <Acadamicemployee />
        </>
      ):type == "drivertable"?(
        <>
            <Drivertable />
        </>
      ):null}
    </div>
  );
}

export default index;
