import React,{useState} from "react";


export const Report = () => (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svg-background">
    <defs>
     
    </defs>
    <path class="cls-1" d="M6.15,13.62c2.91.48,5.44-1.54,5.78-4.22.2-1.55-.24-2.92-1.31-4.06-1.07-1.14-2.42-1.62-4.02-1.54.36-.39.77-.58,1.26-.59.59-.01,1.19,0,1.78,0,1.29,0,2.57,0,3.86,0,.07,0,.13,0,.23,0,0,.07,0,.14,0,.2,0,1.06,0,2.12,0,3.19,0,1.12.74,1.86,1.86,1.86,1.06,0,2.11,0,3.17,0h.22v.21c0,3,0,6,0,8.99,0,1.14-.73,1.87-1.86,1.87-3.03,0-6.06,0-9.09,0-1.14,0-1.87-.74-1.87-1.88,0-1.27,0-2.54,0-3.8v-.21ZM16.65,14.57c0-.66,0-1.31,0-1.97,0-.08,0-.17-.03-.25-.08-.27-.35-.45-.63-.41-.3.04-.51.29-.51.62,0,1.33,0,2.66,0,3.99,0,.37.24.63.58.64.34,0,.58-.26.58-.65,0-.66,0-1.31,0-1.97ZM11.98,15.44c0-.38,0-.75,0-1.13,0-.36-.25-.62-.58-.62-.33,0-.59.26-.59.61,0,.76,0,1.52,0,2.28,0,.35.26.61.59.61.33,0,.57-.26.58-.62,0-.38,0-.75,0-1.13ZM14.31,15.74c0-.28,0-.56,0-.84,0-.01,0-.02,0-.04,0-.34-.25-.6-.58-.6-.32,0-.58.25-.59.59,0,.58,0,1.15,0,1.73,0,.34.27.59.59.59.32,0,.57-.26.57-.6,0-.28,0-.56,0-.84ZM9.65,16.31c0-.1,0-.21,0-.31-.01-.31-.25-.55-.55-.57-.31-.01-.58.22-.6.53-.02.22-.01.45,0,.67.02.31.29.55.6.54.31-.01.55-.26.56-.58,0-.1,0-.19,0-.29Z"/>
    <path class="cls-1" d="M6.45,5.01c0,.07,0,.13,0,.2,0,.95,0,1.9,0,2.86,0,.76.49,1.25,1.25,1.25.95,0,1.91,0,2.86,0,.07,0,.13,0,.2,0-.13,1.37-1.44,3.17-3.67,3.2-2.05.03-3.77-1.6-3.84-3.62-.08-2.25,1.66-3.71,3.2-3.89Z"/>
    <path class="cls-1" d="M18.91,7.28c-.08,0-.14,0-.2,0-1.05,0-2.1,0-3.15,0-.44,0-.67-.23-.67-.67,0-1.07,0-2.14,0-3.2v-.18c.46.05.82.25,1.11.57.54.6,1.09,1.19,1.63,1.79.3.33.6.65.89.98.18.2.31.43.38.71Z"/>
    <path class="cls-1" d="M10.76,8.14h-3.14v-3.13c1.33.13,2.88,1.32,3.14,3.13Z"/>
  </svg>
);

export const Event = () => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"

    >
      <defs>
        <style>
          {`.cls-1 {
              stroke-width: 0px;
            }`}
        </style>
      </defs>
      <path
        className="cls-1"
        d="M4.71,9.74h14.52c0,.07,0,.13,0,.19,0,1.87,0,3.73,0,5.6,0,1.48-1.1,2.58-2.58,2.58-3.12,0-6.24,0-9.35,0-1.48,0-2.58-1.1-2.58-2.58,0-1.87,0-3.73,0-5.6v-.19ZM10.45,12.9h0c-.16,0-.33-.01-.49,0-.29,0-.49.15-.57.38-.08.24,0,.48.24.65.26.19.52.39.79.57.08.06.1.11.07.2-.11.31-.21.62-.3.93-.09.28-.02.52.19.67.21.15.46.14.7-.03.25-.18.5-.36.75-.55.1-.08.17-.09.28,0,.24.19.49.36.73.54.26.19.51.2.72.04.21-.16.27-.4.18-.69-.1-.3-.19-.6-.29-.89-.03-.1-.02-.15.07-.21.26-.18.51-.37.76-.55.25-.18.34-.41.26-.66-.08-.25-.29-.39-.59-.39-.31,0-.63,0-.94,0-.11,0-.16-.03-.2-.14-.09-.29-.19-.59-.28-.88-.1-.3-.29-.46-.56-.46-.26,0-.45.16-.55.45-.05.14-.09.29-.14.43-.06.19-.08.46-.21.55-.14.1-.4.04-.61.05Z"
      />
      <path
        className="cls-1"
        d="M19.23,9.17H4.74c-.3-1.94.81-3.32,2.53-3.35.26,0,.51,0,.8,0,0,.28,0,.56,0,.83,0,.37.15.68.45.9.36.26.75.3,1.15.1.39-.19.6-.52.62-.96.01-.28,0-.57,0-.87h3.35c0,.28,0,.57,0,.85.02.47.24.81.67,1,.43.19.83.11,1.19-.19.25-.21.37-.49.37-.82,0-.28,0-.56,0-.84.84-.04,1.66-.05,2.36.5.64.5.97,1.16.99,1.97,0,.28,0,.57,0,.86Z"
      />
      <path
        className="cls-1"
        d="M8.62,5.96c0-.23,0-.45,0-.68,0-.33.25-.57.56-.57.3,0,.55.24.55.56,0,.47,0,.93,0,1.4,0,.32-.25.56-.55.56-.31,0-.56-.24-.56-.57,0-.23,0-.47,0-.7Z"
      />
      <path
        className="cls-1"
        d="M15.32,5.97c0,.23,0,.45,0,.68,0,.33-.24.57-.55.57-.31,0-.56-.24-.56-.57,0-.46,0-.92,0-1.38,0-.33.25-.57.56-.56.3,0,.54.24.55.56,0,.01,0,.02,0,.03,0,.22,0,.44,0,.66Z"
      />
    </svg>
  );
};

// export const Parent = ({color}) => {
//   const [isActive, setIsActive] = useState(false);

//   return (
//     <svg
//       data-name="Layer 1"
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//       className={`svg-background ${isActive ? "active" : ""}`}
//       onMouseEnter={() => setIsActive(true)}
//       onMouseLeave={() => setIsActive(false)}
//     >
//       <defs>
//         <style>
//           {`.cls-1 {
//               stroke-width: 0px;
//             }`}
//         </style>
//       </defs>
//       <path
//         className="cls-1"
//         d="M2.48,14.28c.06-.25.1-.51.18-.76.5-1.51,1.77-2.45,3.32-2.46,1.08,0,2.15,0,3.23,0,.62,0,1.25-.02,1.87.04,1.08.11,1.9.68,2.51,1.59-.24.16-.49.3-.7.48-.65.56-.95,1.3-.95,2.17,0,1.46,0,2.92,0,4.39v.24h-.25c-2.99,0-5.98,0-8.98,0-.08,0-.16.01-.24.02,0-1.9,0-3.81,0-5.71Z"
//       />
//       <path
//         className="cls-1"
//         d="M12.69,19.96c0-.35,0-.68,0-1.02,0-1.21,0-2.41,0-3.62,0-.82.36-1.45,1.08-1.81.22-.11.47-.18.71-.18,1.73-.02,3.46-.02,5.19,0,.93,0,1.76.8,1.79,1.76.04,1.62.01,3.24.01,4.88h-8.78Z"
//       />
//       <path
//         className="cls-1"
//         d="M11.23,6.99c0,1.64-1.29,2.99-2.9,3.01-1.61.01-2.92-1.33-2.92-3,0-1.66,1.28-2.99,2.89-3.01,1.59-.02,2.92,1.34,2.93,3Z"
//       />
//       <path
//         className="cls-1"
//         d="M19.23,9.87c0,1.22-.96,2.22-2.15,2.22-1.18,0-2.14-1-2.14-2.22,0-1.22.96-2.22,2.14-2.22,1.18,0,2.15,1,2.15,2.22Z"
//       />
//     </svg>
//   );
// };
export const Parent = ({ color }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={`svg-background ${isActive ? "active" : ""}`}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <defs>
        <style>
          {`.cls-1 {
              fill: #ffffff;
              stroke-width: 0px;
            }`}
        </style>
      </defs>
      <path
        className="cls-1"
        d="M2.48,14.28c.06-.25.1-.51.18-.76.5-1.51,1.77-2.45,3.32-2.46,1.08,0,2.15,0,3.23,0,.62,0,1.25-.02,1.87.04,1.08.11,1.9.68,2.51,1.59-.24.16-.49.3-.7.48-.65.56-.95,1.3-.95,2.17,0,1.46,0,2.92,0,4.39v.24h-.25c-2.99,0-5.98,0-8.98,0-.08,0-.16.01-.24.02,0-1.9,0-3.81,0-5.71Z"
      />
      <path
        className="cls-1"
        d="M12.69,19.96c0-.35,0-.68,0-1.02,0-1.21,0-2.41,0-3.62,0-.82.36-1.45,1.08-1.81.22-.11.47-.18.71-.18,1.73-.02,3.46-.02,5.19,0,.93,0,1.76.8,1.79,1.76.04,1.62.01,3.24.01,4.88h-8.78Z"
      />
      <path
        className="cls-1"
        d="M11.23,6.99c0,1.64-1.29,2.99-2.9,3.01-1.61.01-2.92-1.33-2.92-3,0-1.66,1.28-2.99,2.89-3.01,1.59-.02,2.92,1.34,2.93,3Z"
      />
      <path
        className="cls-1"
        d="M19.23,9.87c0,1.22-.96,2.22-2.15,2.22-1.18,0-2.14-1-2.14-2.22,0-1.22.96-2.22,2.14-2.22,1.18,0,2.15,1,2.15,2.22Z"
      />
    </svg>
  );
};

export const Circular = () => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <defs>
        {/* <style>
          {`.cls-1 {
              stroke-width: 0px;
              fill: #FFFFFF;
            }`}
        </style> */}
      </defs>
      <path
        className="cls-1"
        d="M13.03,21.64c-.33-.15-.42-.41-.42-.76,0-3.16,0-6.32,0-9.49,0-.49.2-.7.69-.7,2.52,0,5.05,0,7.57,0,.35,0,.61.09.76.42v10.11c-.09.19-.23.32-.41.41h-8.19ZM17.13,15.61c-.54,0-1.08,0-1.62,0-.09,0-.19,0-.28.04-.24.08-.39.33-.37.57.02.26.22.49.48.5,1.18.01,2.37,0,3.55,0,.09,0,.19-.06.27-.12.19-.15.25-.38.17-.61-.08-.24-.27-.38-.54-.38-.56,0-1.11,0-1.67,0ZM17.13,17.86c-.54,0-1.08,0-1.62,0-.1,0-.2.01-.29.05-.24.09-.39.36-.34.61.05.29.28.47.61.47,1.09,0,2.18,0,3.27,0,.13,0,.26-.04.37-.1.21-.12.29-.38.23-.61-.07-.25-.27-.41-.54-.41-.56,0-1.13,0-1.69,0ZM15.99,13.35c-.18,0-.36,0-.54,0-.32.01-.56.24-.57.54-.01.31.21.57.54.58.37.02.75.02,1.13,0,.32-.01.55-.26.54-.56,0-.31-.24-.55-.57-.56-.18,0-.35,0-.53,0Z"
      />
      <path
        className="cls-1"
        d="M2.4,7.84c0-1.59,0-3.18,0-4.77,0-.47.21-.68.67-.68,2.56,0,5.12,0,7.68,0,.45,0,.66.22.66.67,0,3.2,0,6.4,0,9.6,0,.45-.21.67-.66.67-2.57,0-5.13,0-7.7,0-.44,0-.65-.22-.65-.66,0-1.61,0-3.22,0-4.83ZM6.93,7.3c-.54,0-1.09,0-1.63,0-.1,0-.2.01-.29.05-.24.09-.39.36-.34.61.05.29.28.47.61.47,1.09,0,2.18,0,3.27,0,.13,0,.26-.04.37-.1.21-.12.29-.38.23-.61-.07-.25-.27-.41-.54-.41-.56,0-1.11,0-1.67,0ZM6.91,10.68c.52,0,1.04,0,1.56,0,.11,0,.22,0,.32-.04.25-.08.41-.35.38-.6-.04-.29-.27-.48-.6-.49-1.03,0-2.07,0-3.1,0-.1,0-.2,0-.3,0-.28.03-.5.26-.51.54,0,.28.19.52.47.57.08.01.16.01.24.02.51,0,1.03,0,1.54,0ZM5.76,5.05c-.18,0-.36,0-.54,0-.32.01-.55.24-.57.54-.01.31.21.57.54.58.37.02.75.02,1.13,0,.32-.01.55-.27.54-.56,0-.31-.24-.54-.57-.55-.18,0-.35,0-.53,0Z"
      />
      <path
        className="cls-1"
        d="M9.5,18.26c-.07,0-.14,0-.21,0-.77,0-1.54,0-2.31,0-.41,0-.64-.23-.64-.64,0-.84,0-1.68,0-2.52,0-.38.23-.64.56-.64.33,0,.56.26.56.64,0,.67,0,1.34,0,2.02h2.02c0-.05,0-.11,0-.16,0-.22.1-.39.29-.49.2-.11.41-.11.59.03.32.25.64.52.95.8.26.23.23.61-.04.84-.27.24-.55.47-.83.7-.2.16-.42.21-.65.09-.22-.11-.32-.31-.3-.56,0-.03,0-.06,0-.11Z"
      />
      <path
        className="cls-1"
        d="M16.57,6.91h-2.02c0,.06,0,.12,0,.17,0,.22-.1.39-.29.49-.19.1-.4.11-.57-.03-.34-.26-.67-.53-.98-.82-.23-.21-.22-.58.02-.79.3-.27.62-.53.93-.78.18-.14.39-.14.58-.04.2.1.3.27.3.5,0,.05,0,.1,0,.17.07,0,.14,0,.21,0,.76,0,1.53,0,2.29,0,.43,0,.65.22.65.64,0,.84,0,1.68,0,2.52,0,.38-.23.64-.56.64-.33,0-.56-.26-.56-.64,0-.67,0-1.34,0-2.02Z"
      />
    </svg>
  );
};
