import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Badge,
  Avatar,
  Space,
  notification,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// import './userManagement.css'
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Noticeopr from "../Component/Notice/Noticeopr.jsx";
import Sidebar from "../layout/base/Sidebar.jsx";
import TableSortArrows from "../Component/tableSortArrows.jsx";
import { useDispatch } from "react-redux";
import { breadcrum } from "../action/Subbreadcrumaction.js";
// import { customPagination } from "../helper";
import Pagination from "../Component/pagination/index.jsx";

const { confirm } = Modal;
const allData = [
  {
    key: 1,
    NoticeName: "Retirement Age",
    audience: "Admin",
    publishedOn: "21 - Sep - 2024",
    status: "Published",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 2,
    NoticeName: "Annual Event",
    audience: "All",
    publishedOn: "15 - Sep - 2024",
    status: "Published",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 3,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "21 - Sep - 2024",
    status: "Unpublished",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 4,
    NoticeName: "Exam schedule",
    audience: "All",
    publishedOn: "15 - Sep - 2024",
    status: "Published",
    avatarUrl: "https://i.pravatar.cc/40?img=4",
  },
];
function Events() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [eventModelVisibility, setEventModalVisibility] = useState(false);

  const daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1); // Days for the calendar
  const events = Array(24).fill({
    title: "Teachers Meeting",
    time: "9:30 AM - 12:00 PM",
    date: "23",
    day: "wed",
  });
  const showModal = () => {
    setIsModalVisible(true);
  };
  const eventModel = (key) => {
    setEventModalVisibility(true);
    console.log(key);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEventModalVisibility(false);
  };
  const handleOk = () => {
    // You can add logic to save the event here
    console.log("Event added!");
    setIsModalVisible(false); // Close the modal after submitting
  };

  const getCurrentMonth = () => {
    const date = new Date();
    return date.toLocaleString("default", { month: "long" });
  };
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className="container-fluid mt-4">
      <div className="row">
        <div className="col-md-8">
          <div className="calendar-container border rounded p-3">
            <div className="calendar-header d-flex justify-content-between align-items-center">
              <span
                style={{
                  background: " #ECF0F3",
                  boxshadow:
                    "4px 4px 8px 0px #E0E2E4, -4px -4px 8px 0px #F8F9FA",
                }}
              >
                {getCurrentMonth()} {getCurrentYear()}
              </span>
              <h5>Monthly</h5>
              <button
                className="addeventbtn btn btn-primary d-flex align-items-center"
                onClick={showModal}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Add Event
              </button>{" "}
            </div>
            <div className="calendar-grid">
              {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                <div key={day} className=" text-center ">
                  {day}
                </div>
              ))}
              {daysInMonth.map((day) => (
                <div
                  key={day}
                  className="calendar-cell text-center"
                  onClick={eventModel}
                >
                  {day}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="events-container ">
            <div className="eventheader  d-flex justify-content-center align-items-center">
              <h5 className="my-3">Events</h5>
            </div>
            {events.map((event, index) => (
              <div className="eventitemcontainer py-2 mx-5">
                <div key={index} className="event-item">
                  <div className="rounded shadow-sm d-flex justify-content-start p-2 gap-3">
                    <div className=" align-items-center ">
                      <p className="fw-bold " style={{ color: "#00ADC4" }}>
                        {event.date}
                      </p>
                      <p className="fw-bold">{event.day}</p>
                    </div>
                    <div className="border border-1"></div>
                    <div className="d-flex align-items-center m-0  flex-column">
                      <p className="mb-0 fw-bold">{event.title}</p>
                      <p className="text-muted small">{event.time}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        visible={eventModelVisibility}
        onCancel={handleCancel}
        footer={null}
      >
        <h6>Teachers Meeting</h6>
        <p>06/01/2025</p>
        <p>Time:9:30 Am - 12 :00 Pm</p>
      </Modal>

      <Modal
        title="Add New Event"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        // footer={[
        //   <Button key="back" onClick={handleCancel}>
        //     Cancel
        //   </Button>,
        //   <Button key="submit" type="primary" onClick={handleOk}>
        //     Add Event
        //   </Button>,
        // ]}
      >
        {/* Add your form content here */}
        <form className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="eventName" className="form-label">
              Event Name
            </label>
            <input
              type="text"
              className="form-control"
              id="eventName"
              placeholder="Enter event name"
            />
          </div>
          <div className="col-md-12 mb-3">
            <label
              htmlFor="eventDate"
              className="form-label"
              placeholder="Enter a Date"
            >
              Event Date
            </label>
            <input type="date" className="form-control" id="eventDate" />
          </div>
          <div className="col-md-6 mb-3 ">
            <label htmlFor="eventDate" className="form-label">
              Start Time
            </label>
            <input type="time" className="form-control" id="eventDate3" />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="eventDate" className="form-label">
              End Time
            </label>
            <input type="time" className="form-control" id="eventDate4" />
          </div>
        </form>
      </Modal>
    </div>

    // <Noticeopr
    //   record={selectedDate}
    //   onClose={onClose}
    //   type={teb}
    //   selectedId={selectedId}
    // />
  );
}

export default Events;
