import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Badge,
  Avatar,
  Space,
  notification,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// import "./userManagement.css";
import TableSortArrows from "../Component/tableSortArrows";
import { useDispatch } from "react-redux";
import { breadcrum } from "../action/Subbreadcrumaction";

import { useNavigate } from "react-router-dom";
import Noticeopr from "../Component/Notice/Noticeopr.jsx";
import Sidebar from "../layout/base/Sidebar";
import Circularopr from "../Component/circular/Circularopr.jsx";
// import { customPagination } from "../helper";
import Pagination from "../Component/pagination";

const { confirm } = Modal;

const allData = [
  {
    key: 1,
    NoticeName: "Retirement Age",
    audience: "Admin",
    publishedOn: "21 - Sep - 2024",
    status: "Published",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 2,
    NoticeName: "Annual Event",
    audience: "All",
    publishedOn: "15 - Sep - 2024",
    status: "Published",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 3,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "",
    status: "Unpublished",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 4,
    NoticeName: "Exam schedule",
    audience: "All",
    publishedOn: "15 - Sep - 2024",
    status: "Published",
    avatarUrl: "https://i.pravatar.cc/40?img=4",
  },
  {
    key: 5,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "",
    status: "Unpublished",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 6,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "",
    status: "Unpublished",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 7,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "",
    status: "Unpublished",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 8,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "",
    status: "Unpublished",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 9,
    NoticeName: "Transportation",
    audience: "Department",
    publishedOn: "",
    status: "Unpublished",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
];

function Circular() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  const [userData, setUserData] = useState(allData);
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);

  // Ant Design Pagination Functions

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };
  const openOnChange = (value, teb) => {
    if (teb == "edit" || teb == "view") {
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb == "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    } else {
      confirmDelete();
    }
  };

  useEffect(() => {
    setTotalcount(allData.length);
  }, []);
  const confirmDelete = () => {
    confirm({
      // title: <h5>Confirm Delete</h5>,
      // content: <p>Are you sure you want to Delete?</p>,
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      onOk() {
        deleteonClick();
      },
      onCancel() {},
      okButtonProps: {
        className: "custom-save-button",
      },
      cancelButtonProps: {
        className: "custom-cancel-button",
      },
    });
  };
  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted successfully",
      className: "",
    });
  };
  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedDate({});
  };
  const columns = [
    {
      title: <input type="checkbox" />,
      dataIndex: "checkbox",
      render: () => <input type="checkbox" />,
    },

    {
      title: "NoticeName ",
      dataIndex: "NoticeName",
      sorter: (a, b) => a.NoticeName.localeCompare(b.NoticeName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Audience",
      dataIndex: "audience",
    },
    {
      title: "Published On",
      dataIndex: "publishedOn",
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${
              status === "Published" ? "#00ADC4" : "#EC7C74"
            }`,
            color: status === "Published" ? "#fff" : "#fff",
            background:
              status === "Published"
                ? "linear-gradient(98.22deg, #00ADC4 -13.56%, #00535E 133.98%)"
                : "#EC7C74",
            width: "90px",
            textAlign: "center",
            padding: "5px 0",
            borderRadius: "4px",
            width: "100px",
          }}
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "edit")}
          />
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => confirmDelete(record)}
          />
        </div>
      ),
    },
  ];
  const useronChange = async (value) => {
    if (value) {
      let finded = allData.filter((a) => a.role == value);
      setUserData(finded);
      setTotalcount(finded.length);
      setUserType(value);
    } else {
      setUserData(allData);
      setTotalcount(allData.length);
    }
  };
  const searchOnChange = (value) => {
    let data = allData.filter((item) =>
      item.NoticeName.toLowerCase().includes(value.toLowerCase())
    );
    setUserData(data);
    setSearch(value);
    setTotalcount(data.length);
  };

  //pagination
  const objectLength = Object.keys(allData).length;
  const setbreadcrum = (arg) => {
    dispatch(breadcrum(arg));
  };

  return (
    <>
      <div>
        {!editModuleOpen ? (
          <div className="tablecontainer">
            <div className="tableinnercontainer">
              <div className="d-md-flex justify-content-between align-items-center ">
                <div className="search-id">
                  <Input
                    onChange={(e) => searchOnChange(e.target.value)}
                    value={search}
                    placeholder="Search by Circular Name"
                    prefix={<SearchOutlined />}
                    className="seachAccadamicemployee"
                  />
                </div>

                {/* <div className="nav-sectionBar"> */}
                <div className="d-lg-flex align-items-center ">
                  <div className="d-flex justify-content-center px-3 gap-5">
                    <div className="btnacccon">
                      <div
                        className="addbtn"
                        onClick={() => openOnChange(null, "add")}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          size="lg"
                          className="icon"
                          style={{ fontSize: "22px" }}
                        />
                        <p
                          style={{
                            margin: "0px",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Add
                        </p>
                      </div>
                      <Pagination
                        customClass="d-none d-xl-flex"
                        // class-page-show.style.display="none";
                        onlyPagtination={true}
                        data={userData}
                        onPageChange={(data) => {
                          setTableData(data);
                        }}
                        initialItemsPerPage={10}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={tableData}
              pagination={false}
              className="table-datas"
            />
            <Pagination
              data={userData}
              onPageChange={(data) => {
                setTableData(data);
              }}
              initialItemsPerPage={10}
            />
          </div>
        ) : (
          <Circularopr
            record={selectedDate}
            onClose={onClose}
            type={teb}
            selectedId={selectedId}
          />
        )}
      </div>
    </>
  );
}

export default Circular;
