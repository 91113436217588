import React from "react";
import { Doughnut } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import boygirl from "../../../Assets/Svg/boygirl.svg";
import { Select, Typography } from "antd";
import "./studentatteenance.css";
const { Title } = Typography;

const { Option } = Select;

const AttendanceChart = () => {
  const boysData = {
    datasets: [
      {
        data: [55, 5],
        backgroundColor: ["#FAE27C", "#EC7C74"],
        borderWidth: 0,
      },
    ],
  };

  const girlsData = {
    datasets: [
      {
        data: [100],
        backgroundColor: ["#ffff", "#ffff"],
        borderWidth: 0,
      },
    ],
  };

  const overallData = {
    datasets: [
      {
        data: [20, 80],
        backgroundColor: ["#EC7C74", "#C3EBFA"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: "75%",
    plugins: {
      tooltip: { enabled: false },
    },
  };

  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <div className="class-student-data d-md-flex  mt-2 justify-content-between align-items-center">
        <Title
          level={5}
          style={{
            margin: 0,
            fontSize: "14px",
            fontFamily: "Poppins, sans-serif",
            color: "#333",
          }}
        >
          Student's Attendance
        </Title>
        <div className="d-flex justify-content-center d-md-flex align-items-center flex-md-row  gap-1">
          <Select
            allowClear
            className="class-select-btn"
            placeholder="Choose Class"
            suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
            style={{ width: "150px" }}
            dropdownStyle={{ width: "150px" }}
          >
            <Option
              value="mathematics"
              style={{ boxshadow: "0 10px 20px rgba(255, 255, 255, 0.15)" }}
            >
              11
            </Option>
            <Option value="science">12</Option>
            <Option value="english">10</Option>
          </Select>
          <Select
            allowClear
            className="class-select-btn"
            placeholder="Choose Section"
            suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
            style={{ width: "150px" }}
            dropdownStyle={{ width: "150px" }}
          >
            <Option value="mathematics">A</Option>
            <Option value="science">B</Option>
            <Option value="english">C</Option>
          </Select>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          // display: "inline-block",
          // left: "11%",
          // right: "11%",
        }}
      >
        {/* <div style={{ width: '15rem', height: '15rem' }}>
          <div style={{ position: "absolute", top: 0, left: 0, width: '10rem', }}>
            <Doughnut data={overallData} options={options} width={300} height={300} />
          </div> outdent
          <div style={{ position: "absolute", top: 25, left: 25, width: '5rem', }}>
            <Doughnut data={girlsData} options={options} width={250} height={250} />
          </div>
          <div style={{ position: "absolute", top: 25, left: 25, width: '7rem', }}>
            <Doughnut data={boysData} options={options} width={240} height={240} />
          </div>
        </div> */}

        <div style={{ position: "relative", display: "inline-block" }}>
          <div
            style={{
              // width: "50px",
              // height: "50px",
              display: "flex",
              justifyContent: "center",
              // border: "solid 1px red",
            }}
          >
            <div
              style={{
                position: "absolute",
                // border: "solid 1px blue",
                marginTop: "30px",
              }}
            >
              <Doughnut
                data={overallData}
                options={options}
                width={250}
                height={250}
              />
            </div>
            <div
              style={{
                position: "absolute",

                // width: "20rem",
                // border: "solid 1px red",
                marginTop: "55px",
              }}
            >
              <Doughnut
                data={girlsData}
                options={options}
                width={200}
                height={200}
              />
            </div>
            <div
              style={{
                position: "absolute",
                // top: 58,
                width: "10rem",
                // border: "solid 1px",
                marginTop: "90%",
              }}
            >
              <Doughnut
                data={boysData}
                options={options}
                width={150}
                height={100}
              />
            </div>
          </div>

          <div
            style={{
              // position: "absolute",
              marginTop: "120px",
              width: "80px",
              // border: "solid 1px red",
              borderRadius: "50px",
            }}
          >
            <img src={boygirl} alt="hi" />
          </div>
        </div>

        <div className="chart-labelsforstudent" style={{ marginTop: "100px" }}>
          <div>
            <div
              style={{
                // border: "solid 1px red",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="labelpresent">
                <span
                  className="dot"
                  style={{
                    backgroundColor: "#FAE27C",
                    width: "10px",
                    height: "10px",
                  }}
                ></span>{" "}
                <br />
                <span className="percentage"></span>
                <span
                  style={{
                    color: "#9B9B9B",
                    fontSize: "10px",
                    fontFamily: "poppins",
                  }}
                >
                  Girls(96%)
                </span>
              </div>

              <div className="labelabsent" style={{ marginLeft: "20px" }}>
                <span
                  className="dot"
                  style={{
                    backgroundColor: "#C3EBFA",
                  }}
                ></span>
                <span className="percentage"></span> <br />
                <span
                  style={{
                    color: "#9B9B9B",
                    fontSize: "10px",
                    fontFamily: "poppins",
                  }}
                >
                  Boys(95%)
                </span>
              </div>
            </div>
          </div>

          <div className="labelabsent">
            <span
              className="dot"
              style={{
                backgroundColor: "#EC7C74",
              }}
            ></span>
            {/* <span>overall</span> */}
            <span className="percentage"></span> <br />
            <span
              style={{
                color: "#9B9B9B",
                fontSize: "10px",
                fontFamily: "poppins",
              }}
            >
              Overall(7%)
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceChart;
