import React, { useState } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";

const ForgotPassword = () => {
  const [currentStep, setCurrentStep] = useState(1); // 1: Email, 2: OTP, 3: Password
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleEmailSubmit = () => {
    if (!email) {
      message.error("Please enter your email");
      return;
    }
    // Simulate API call
    message.success("OTP sent to your email!");
    setCurrentStep(2);
  };

  const handleOtpVerify = () => {
    if (!otp) {
      message.error("Please enter the OTP");
      return;
    }
    // Simulate OTP verification
    message.success("OTP verified!");
    setCurrentStep(3);
  };

  const handlePasswordReset = () => {
    if (newPassword !== confirmPassword) {
      message.error("Passwords do not match");
      return;
    }
    if (!newPassword || !confirmPassword) {
      message.error("Please enter all password fields");
      return;
    }
    // Simulate password reset
    message.success("Password reset successfully!");
  };

  return (
    <Row justify="center" style={{ minHeight: "100vh", alignItems: "center" }}>
      <Col xs={22} sm={18} md={12} lg={8}>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Forgot Password ?
        </h2>
        <Form layout="vertical">
          {currentStep === 1 && (
            <>
              <Form.Item label="Email" required>
                <Input
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="modifybutton"
                  block
                  onClick={handleEmailSubmit}
                >
                  Send OTP
                </Button>
              </Form.Item>
            </>
          )}

          {currentStep === 2 && (
            <>
              <Form.Item label="OTP" required>
                <Input
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="modifybutton"
                  block
                  onClick={handleOtpVerify}
                >
                  Verify OTP
                </Button>
              </Form.Item>
            </>
          )}

          {currentStep === 3 && (
            <>
              <>
                <Form.Item label="New Password" required>
                  <Input.Password
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Confirm Password" required>
                  <Input.Password
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    className="modifybutton"
                    block
                    onClick={handlePasswordReset}
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </>
              <Form.Item>
                <Button
                  className="backtologin"
                  block
                  onClick={handlePasswordReset}
                >
                  <a href="/">Back to Login</a>
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
