import React, { useState } from "react";
import Excel from "../Assets/png/Excel.png";
import Index from "../Component/Report/index";

function Report() {
  const [selectedReport, setSelectedReport] = useState(null);
  const [openModel, setOpenModel] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [type, setType] = useState();

  const reports = [
    {
      title: "Teachers Report",
      updatedDate: "2 Jan 2025",
      tableData: "Teachers Table Content",
    },
    {
      title: "Students Report",
      updatedDate: "2 Jan 2025",
      tableData: "Students Table Content",
    },
    {
      title: "Drivers Report",
      updatedDate: "2 Jan 2025",
      tableData: "Drivers Table Content",
    },
    {
      title: "Fees Collected",
      updatedDate: "2 Jan 2025",
      tableData: "Fees Collected Table Content",
    },
  ];
  const employeetable = () => {
    console.log("empolyee table");
  };

  const openOnChange = (value, type) => {
    if (type == "employeetable") {
      setselectedId(value?.key);
      setOpenModel(true);
      setType(type);
      setSelectedDate(value);
      // console.log(type);
    } else if (type == "studenttable") {
      setselectedId(value?.key);
      setOpenModel(true);
      setType(type);
      setSelectedDate(value);
    } else if (type == "drivertable") {
      setselectedId(value?.key);
      setOpenModel(true);
      setType(type);
      setSelectedDate(value);
    }
  };

  return (
    <div className="reportcontainer">
      <div className="reports-container px-sm-5">
        {!openModel ? (
          <>
            <div className="row g-4">
              <div className="col-lg-4 col-md-6">
                <div className="report-card">
                  <div className="image-section">
                    <img src={Excel} alt="Report 1 thumbnail" />
                  </div>
                  <div className="content-section">
                    <div className="text-container">
                      <h4 className="title">Teachers Report</h4>
                      <p className="date">2025-01-02</p>
                    </div>
                  </div>
                  <div className="button-section">
                    <button
                      className="btn-view"
                      onClick={() => {
                        openOnChange({}, "employeetable");
                      }}
                    >
                      View
                    </button>
                    <button className="btn-export">Export</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="report-card">
                  <div className="image-section">
                    <img src={Excel} alt="Report 2 thumbnail" />
                  </div>
                  <div className="content-section">
                    <div className="text-container">
                      <h4 className="title">Students Report</h4>
                      <p className="date">2025-01-02</p>
                    </div>
                  </div>
                  <div className="button-section">
                    <button
                      className="btn-view"
                      onClick={() => {
                        openOnChange({}, "studenttable");
                      }}
                    >
                      View
                    </button>
                    <button className="btn-export">Export</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="report-card">
                  <div className="image-section">
                    <img src={Excel} alt="Report 3 thumbnail" />
                  </div>
                  <div className="content-section">
                    <div className="text-container">
                      <h4 className="title">Drivers Report</h4>
                      <p className="date">2025-01-02</p>
                    </div>
                  </div>
                  <div className="button-section">
                    <button
                      className="btn-view"
                      onClick={() => {
                        openOnChange({}, "drivertable");
                      }}
                    >
                      View
                    </button>
                    <button className="btn-export">Export</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="report-card">
                  <div className="image-section">
                    <img src={Excel} alt="Report 4 thumbnail" />
                  </div>
                  <div className="content-section">
                    <div className="text-container">
                      <h4 className="title">Fees Collected</h4>
                      <p className="date">2025-01-02</p>
                    </div>
                  </div>
                  <div className="button-section">
                    <button className="btn-view">View</button>
                    <button className="btn-export">Export</button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Index type={type} />
          </>
        )}
      </div>
    </div>
  );
}

export default Report;
