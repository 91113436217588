import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { breadcrum } from "../action/Subbreadcrumaction";
import {
  Modal,
  Table,
  Input,
  Avatar,
  Select,
  Space,
  notification,
  Tag,
  Spin,
  Popover,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import {
  faPlus,
  faPenToSquare,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import Academicopr from "../Component/Acadamicemployeecev/Acadamicopr";
import TableSortArrows from "../Component/tableSortArrows";
import EmptyDataPage from "../Component/EmptyDataPage";
import { getRandomColor } from "../helper";
import Pagination from "./pagination";

const { confirm } = Modal;
const { Option } = Select;

function Acadamicemployee({ userData, isEdit, count }) {
  // onlycheckbox;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  const [userArray, setUserArray] = useState([]);
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [search, setSearch] = useState("");
  const [excelData, setExcelData] = useState(null);
  const [openUploaddataListing, setOpenUploaddataListing] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [tableData, setTableData] = useState([]);

  const [loader, setLoader] = useState(false);

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  // const [gender, setGender] = useState("");
  // const onFinish = (values) => {
  //   console.log("gender", gender);
  //   if (gender.length <= 0) {
  //     setShowError(true);
  //   } else {
  //   }

  //   message.success(
  //     `${type === "edit" ? "Employee updated" : "Employee added"} successfully!`
  //   );
  //   form.resetFields();
  //   onClose();
  // };
  useEffect(() => {
    handlerefresh();
  }, [userData]);

  const handlerefresh = async () => {
    if (userData.length) {
      console.log(userData.length, "userdataemp");

      await asyncForEach(userData, async (oneDoc) => {
        if (!oneDoc.avatarUrl) {
          let color = getRandomColor();
          let logo = oneDoc?.name[0].toUpperCase();
          oneDoc["color"] = color;
          oneDoc["logo"] = logo;
        }
      });
      setUserArray(userData);
    }
  };
  // const pageSizeChange = async (page, pageSize) => {
  //   setLoader(true);
  //   let ctr = {};
  //   ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
  //   ctr._limit = pageSize;

  //   console.log(ctr, "awsetdiuyil");

  //   setCurrentPage(page);
  //   setPageSizeTotal(pageSize);
  //   setLoader(false);
  // };

  const openOnChange = (value, teb) => {
    if (teb == "edit" || teb == "view") {
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb == "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    } else {
      confirmDelete();
    }
  };
  const objectLength = Object.keys(userArray).length;

  console.log(objectLength, "ufcytfyiv");
  useEffect(() => {
    setTotalcount(count);
  }, [count]);
  const confirmDelete = () => {
    confirm({
      // title: <h5>Confirm Delete</h5>,
      // content: <p>Are you sure you want to Delete?</p>,
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",

      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      onOk() {
        deleteonClick();
      },
      onCancel() {
        // console.log('Cancel');
      },
      okButtonProps: {
        className: "custom-save-button",
      },
      cancelButtonProps: {
        className: "custom-cancel-button",
      },
    });
  };
  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted",
      className: "",
    });
  };
  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedDate({});
  };

  // Table columns configuration

  const columns = [
    ...(isEdit
      ? [
          {
            title: <input type="checkbox" />,
            render: (record) => (
              <input
                type="checkbox"
                checked={selectedRows.includes(record.key)}
                onChange={() => handleCheckboxChange(record)}
              />
            ),
          },
        ]
      : []),
    {
      title: "Teacher Name",
      dataIndex: "name",
      width: 350,
      render: (text, record) => (
        <Space>
          {record.avatarUrl ? (
            <Avatar src={record.avatarUrl} />
          ) : (
            <Avatar style={{ backgroundColor: record.color }}>
              {record.logo}
            </Avatar>
          )}
          {text}
        </Space>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "EmployeeID",
      dataIndex: "employeeID",

      sorter: (a, b) => a.employeeID.localeCompare(b.employeeID),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Role",
      dataIndex: "role",

      sorter: (a, b) => a.role.localeCompare(b.role),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },

    {
      title: "Status",
      dataIndex: "status",

      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "#EC7C74"}`,
            color: status === "Active" ? "#fff" : "#fff",
            background:
              status === "Active"
                ? "linear-gradient(98.22deg, #00ADC4 -13.56%, #00535E 133.98%)"
                : "#EC7C74",
            width: "90px",
            textAlign: "center",
            padding: "5px 0",
            borderRadius: "4px",
          }}
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  isEdit &&
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => {
              openOnChange(record, "edit");
              setbreadcrum("Edit Employee");
            }}
          />
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => confirmDelete(record)}
          />
        </div>
      ),
    });
  const useronChange = async (value) => {
    if (value) {
      let finded = userData.filter((a) => a.role == value);
      setUserArray(finded);
      setTotalcount(finded.length);
      setUserType(value);
    } else {
      setUserArray(userData);
      setTotalcount(userData.length);
    }
  };

  const handleCheckboxChange = (record) => {
    const selectedKeys = [...selectedRows];
    if (selectedKeys.includes(record.key)) {
      // Deselect the row
      setSelectedRows(selectedKeys.filter((key) => key !== record.key));
    } else {
      // Select the row
      setSelectedRows([...selectedKeys, record.key]);
    }
  };

  console.log(userData, "3245yuijo");
  const searchOnChange = (value) => {
    let data = userData.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.employeeID.toLowerCase().includes(value.toLowerCase())
    );
    setUserArray(data);
    setSearch(value);
    setTotalcount(data.length);
  };
  //bulk upload
  const renderOptions = () => (
    <div className="modelpopover">
      <button
        onClick={() => {
          openOnChange(null, "add");
          setbreadcrum("Add Employee");
        }}
        className="manualAdd"
      >
        Add Teacher
      </button>
      <button
        onClick={() => setOpenUploaddataListing(true)}
        className="bulkAdd"
      >
        Bulk Upload
      </button>
    </div>
  );

  const handleOk = () => {
    try {
      if (excelData.length) {
        //api
        setExcelData([]);

        notification.open({
          type: "success",
          message: "data added successfuly",
          className: "",
        });
      } else {
        notification.open({
          type: "error",
          message: "No data",
          className: "",
        });
      }
      setOpenUploaddataListing(false);
    } catch (e) {
      notification.open({
        type: "error",
        message: e.message,
        className: "",
      });
    }
  };
  const handleCancel = () => {
    setExcelData([]);

    setOpenUploaddataListing(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        if (jsonData.length) {
          setExcelData(jsonData);
        }
      } catch (err) {
        console.error(err);
      }
    };

    reader.readAsArrayBuffer(file);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        ` selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record?.name === "Disabled User",
      name: record?.name,
    }),
  };
  // const handleGenderChange = (value) => {
  //   setGender(value);
  //   setShowError(false);
  // };
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };

  const xlsxColumns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    {
      title: "Teacher Name",
      dataIndex: "Name",
    },

    {
      title: "Employee ID",
      dataIndex: "EMPID",
    },
    {
      title: "Role",
      dataIndex: "Role",
    },
    {
      title: "Phone Number",
      dataIndex: "MobileNumber",
    },
    {
      title: "Status",
      dataIndex: "Status",
    },
  ];

  const setbreadcrum = (arg) => {
    dispatch(breadcrum(arg));
  };

  console.log(tableData, "hhh");

  return (
    <>
      {!editModuleOpen ? (
        <div className="tablecontainer">
          <div className="tableinnercontainer">
            {/* <div className="d-md-flex justify-content-between align-items-center"> */}
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div className="search-id">
                <Input
                  onChange={(e) => searchOnChange(e.target.value)}
                  value={search}
                  placeholder="Search by Employee Name or ID"
                  prefix={<SearchOutlined />}
                  className="seachAccadamicemployee"
                />
              </div>
              <div className="d-lg-flex align-items-center">
                <div className="d-flex justify-content-center px-3">
                  <div className="btnacccon">
                    {!isEdit ? (
                      <Popover placement="bottom" content={renderOptions()}>
                        <div className="addbtn">
                          <FontAwesomeIcon
                            icon={faPlus}
                            size="lg"
                            className="icon"
                            style={{ fontSize: "22px" }}
                          />
                          <p
                            style={{
                              margin: "0px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Add
                          </p>
                        </div>
                      </Popover>
                    ) : null}

                    <Select
                      allowClear
                      className="class-select-btn"
                      placeholder="Bulk Select"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      // className="inputbox"
                      // value={gender}
                      // onChange={handleGenderChange}
                      style={{ width: "150px" }}
                      dropdownStyle={{ width: "150px" }}
                    >
                      <Option
                        value="Active"
                        // onClick={() => setOpenUploaddataListing(true)}
                        // onOk={handleOk}
                        // okText="Save"
                        // onCancel={handleCancel}
                      >
                        Active
                      </Option>
                      <Option
                        value="Inactive"
                        // onClick={() => setOpenUploaddataListing(true)}
                        // onOk={handleOk}
                        // okText="Save"
                        // onCancel={handleCancel}
                      >
                        Inactive
                      </Option>
                    </Select>

                    <Pagination
                      customClass="d-none d-xl-flex"
                      // class-page-show.style.display="none";
                      onlyPagtination={true}
                      data={userArray}
                      onPageChange={(data) => {
                        setTableData(data);
                      }}
                      initialItemsPerPage={10}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Spin spinning={loader}>
            <Table
              columns={columns}
              dataSource={tableData}
              tableLayout="fixed"
              className="table-datas"
              pagination={false}
            />
          </Spin>
          <Pagination
            data={userArray}
            onPageChange={(data) => {
              setTableData(data);
            }}
            initialItemsPerPage={10}
          />
        </div>
      ) : (
        <Academicopr
          record={selectedDate}
          onClose={onClose}
          type={teb}
          selectedId={selectedId}
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: (selectedRowKeys) => setSelectedRows(selectedRowKeys),
          }}
        />
      )}
      <Modal
        title={"Teacher data"}
        className=""
        zIndex={1000000}
        open={openUploaddataListing}
        width={1000}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancel}
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <>
          <div className="bulkuploadinnerbtn" style={{ marginBottom: "20px" }}>
            <div className="file-upload-wrapper">
              <label
                htmlFor="file-upload"
                className="custom-file-upload-button"
              >
                Upload File
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".xlsx"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
            </div>
            <button
              onClick={() => {
                const link = document.createElement("a");
                link.href = "/path/to/your/template.xlsx";
                link.download = "Student_Data_Template.xlsx";
                link.click();
              }}
              className="download-template-button"
              style={{
                backgroundColor: "#00ADC4",
                color: "#fff",
                border: "none",
                padding: "8px 16px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Download Template
            </button>
          </div>
          {excelData?.length ? (
            <Table
              columns={xlsxColumns}
              dataSource={excelData}
              onChange={handleChange}
              // pagination={{
              //   pageSize: 10,
              //   defaultCurrent: 1,
              //   total: xlsxColumns.length,
              // }}
              pagination={false}
              className="modelcon"
              scroll={{
                y: 200, // Vertical scroll height in pixels
                x: 90, // Horizontal scroll if needed
              }}
              style={{ borderRadius: "8px" }}
            />
          ) : (
            <EmptyDataPage description="No records found" />
          )}
        </>
      </Modal>
    </>
  );
}

export default Acadamicemployee;
