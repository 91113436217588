import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  faPlus,
  faPenToSquare,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Table,
  Input,
  Avatar,
  Select,
  Space,
  notification,
  Tag,
  Popover,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { SearchOutlined } from "@ant-design/icons";
import Studentopr from "../Component/Student/studentopr.jsx";
import * as XLSX from "xlsx";
import EmptyDataPage from "../Component/EmptyDataPage";
import TableSortArrows from "../Component/tableSortArrows";
import { useDispatch } from "react-redux";
import { breadcrum } from "../action/Subbreadcrumaction";
import Pagination from "../Component/pagination/index.jsx";

const { confirm } = Modal;
const { Option } = Select;

const alldatas = [
  {
    key: 46,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    uniqueno: "12345",
    Gradesec: "9-B",
    phoneNumber: "+91 9876543210",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },

  {
    key: 47,
    name: "Aisha Fatima",
    employeeID: "ENG00202",
    uniqueno: "12634",
    role: "Teacher",
    Gradesec: "9-A",
    phoneNumber: "+91 9876543211",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 48,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    Gradesec: "10-B",
    uniqueno: "129934",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 49,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    uniqueno: "1234",
    Gradesec: "10-A",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 50,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    Gradesec: "11-A",
    phoneNumber: "+91 9876543210",
    status: "Active",
    uniqueno: "123004",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 51,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    Gradesec: "11-B",
    uniqueno: "12300004",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 52,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    uniqueno: "12345",
    Gradesec: "9-B",
    phoneNumber: "+91 9876543210",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
];

function Studentdetails({ userData, isEdit, count }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  const [userDataList, setUserDataList] = useState(alldatas);
  const [userArray, setUserArray] = useState();
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");
  const [data, setData] = useState(alldatas);
  const [pageSize, setPageSize] = useState();
  const [total, setTotal] = useState(alldatas.length);
  const [excelData, setExcelData] = useState(null);
  const [openUploaddataListing, setOpenUploaddataListing] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [currentbootPage, setBootCurrentPage] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startDragPosition, setStartDragPosition] = useState({ x: 0, y: 0 });
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [tableData, setTableData] = useState([]);

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const openOnChange = (value, teb) => {
    if (teb == "edit" || teb == "view") {
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb == "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    } else {
      confirmDelete();
    }
  };

  useEffect(() => {
    setTotalcount(alldatas.length);
  }, []);
  const confirmDelete = () => {
    confirm({
      // title: <h5>Confirm Delete</h5>,
      // content: <p>Are you sure you want to Delete?</p>,
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      onOk() {
        deleteonClick();
      },
      onCancel() {},
      okButtonProps: {
        className: "custom-save-button",
      },
      cancelButtonProps: {
        className: "custom-cancel-button",
      },
    });
  };
  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted",
      className: "",
    });
  };

  const onSelectAllChange = (checked) => {
    if (checked) {
      // Select all rows
      setSelectedRowKeys(userDataList.map((row) => row.key));
    } else {
      // Deselect all rows
      setSelectedRowKeys([]);
    }
  };

  const handleMouseDown = (e) => {
    console.log(e, "ertdyuio");
    e.stopPropagation(); // Prevent event propagation to parent elements
    setDragging(true);
    setStartDragPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      setPosition({
        x: e.clientX - startDragPosition.x,
        y: e.clientY - startDragPosition.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const renderActionUI = () => (
    <div
      className="renderuicomp"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onMouseDown={handleMouseDown}
      style={{
        width: "70vh",
        height: "50vh",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: `${position.y}px`,
          left: `${position.x}px`,
        }}
      >
        <input
          type="checkbox"
          checked
          readOnly
          style={{ marginRight: "10px" }}
        />
        <span style={{ marginRight: "20px" }}>
          {selectedRowKeys.length} Data
        </span>
        <span style={{ marginRight: "20px", cursor: "pointer", color: "red" }}>
          Remove
        </span>
        <span style={{ marginRight: "20px", cursor: "pointer" }}>Active</span>
        <span style={{ marginRight: "20px", cursor: "pointer" }}>Inactive</span>
      </div>
    </div>
  );
  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedDate({});
  };
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };
  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to the first page
  };

  const searchOnChange = (value) => {
    const data = alldatas.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.employeeID.toLowerCase().includes(value.toLowerCase())
    );
    setUserDataList(data);
    setSearch(value);
    setTotalcount(data.length);
  };
  const onRowCheckboxChange = (record) => {
    const updatedSelection = selectedRowKeys.includes(record.key)
      ? selectedRowKeys.filter((key) => key !== record.key)
      : [...selectedRowKeys, record.key];

    setSelectedRowKeys(updatedSelection);
  };
  const [slidervisibility, setSlidervisibility] = useState(false);
  const bulkupload = (value) => {
    setSlidervisibility(true);
    setOpenUploaddataListing(true);
  };
  //pagination
  const objectLength = Object.keys(userDataList).length;

  const columns = [
    ...(isEdit
      ? [
          {
            title: <input type="checkbox" />,
            render: (record) => (
              <input
                type="checkbox"
                // checked={selectedRows.includes(record.key)}
                // onChange={() => handleCheckboxChange(record)}
              />
            ),
          },
        ]
      : []),
    // {
    //   title: (
    //     <input
    //       type="checkbox"
    //       checked={selectedRowKeys.length === userDataList.length}
    //       indeterminate={
    //         selectedRowKeys.length > 0 &&
    //         selectedRowKeys.length < userDataList.length
    //       }
    //       onChange={(e) => onSelectAllChange(e.target.checked)}
    //     />
    //   ),
    //   dataIndex: "checkbox",
    //   render: (_, record) => (
    //     <input
    //       type="checkbox"
    //       checked={selectedRowKeys.includes(record.key)}
    //       onChange={() => onRowCheckboxChange(record)}
    //     />
    //   ),
    // },

    {
      title: "Student Name",
      dataIndex: "name",
      render: (text, record) => (
        <Space>
          <Avatar src={record.avatarUrl} />
          {text}
        </Space>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Unique No",
      dataIndex: "uniqueno",
    },
    {
      title: "Admission NO",
      dataIndex: "employeeID",
    },
    {
      title: "Grade & sec",
      dataIndex: "Gradesec",
      sorter: (a, b) => a.Gradesec.localeCompare(b.Gradesec),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "#EC7C74"}`,
            color: status === "Active" ? "#fff" : "#fff",
            background:
              status === "Active"
                ? "linear-gradient(98.22deg, #00ADC4 -13.56%, #00535E 133.98%)"
                : "#EC7C74",
            width: "90px",
            textAlign: "center",
            padding: "5px 0",
            borderRadius: "4px",
          }}
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];
  isEdit &&
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => {
              openOnChange(record, "edit");
              setbreadcrum("Edit Student");
            }}
          />
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => {
              openOnChange(record, "view");
              setbreadcrum("view Student");
            }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => confirmDelete(record)}
          />
        </div>
      ),
    });

  const useronChange = async (value) => {
    if (value) {
      let finded = alldatas.filter((a) => a.Gradesec == value);
      setUserDataList(finded);
      setTotalcount(finded.length);
      setUserType(value);
    } else {
      setUserDataList(alldatas);
      setTotalcount(alldatas.length);
      setUserType("");
    }
  };

  const renderOptions = () => (
    <div className="modelpopover">
      <button
        onClick={() => {
          openOnChange(null, "add");
          setbreadcrum("Add Student");
        }}
        className="manualAdd"
      >
        Add Student
      </button>
      <button onClick={bulkupload} className="bulkAdd">
        Bulk Upload
      </button>
    </div>
  );
  console.log(openUploaddataListing, "model");
  const handleOk = () => {
    try {
      if (excelData.length) {
        //api
        setExcelData([]);

        notification.open({
          type: "success",
          message: "data added successfuly",
          className: "",
        });
      } else {
        notification.open({
          type: "error",
          message: "No data",
          className: "",
        });
      }
      setOpenUploaddataListing(false);
    } catch (e) {
      notification.open({
        type: "error",
        message: e.message,
        className: "",
      });
    }
  };
  const handleCancel = () => {
    setExcelData([]);

    setOpenUploaddataListing(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        if (jsonData.length) {
          setExcelData(jsonData);
        }
      } catch (err) {
        console.error(err);
      }
    };

    reader.readAsArrayBuffer(file);
  };
  const xlsxColumns = [
    {
      title: "Teacher Name",
      dataIndex: "Name",
    },

    {
      title: "Employee ID",
      dataIndex: "EMPID",
    },
    {
      title: "Role",
      dataIndex: "Role",
    },
    {
      title: "Phone Number",
      dataIndex: "MobileNumber",
    },
    {
      title: <div> Status</div>,
      dataIndex: "Status",
    },
  ];

  const setbreadcrum = (arg) => {
    dispatch(breadcrum(arg));
  };

  return (
    <div>
      {!editModuleOpen ? (
        <div className="tablecontainer">
          <div className="tableinnercontainer ">
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div className="search-id">
                <Input
                  onChange={(e) => searchOnChange(e.target.value)}
                  value={search}
                  placeholder="Search by Student Name or ID"
                  prefix={<SearchOutlined />}
                  className="seachAccadamicemployee"
                />
              </div>

              <div className="nav-sectionBar">
                <div className="d-flex flex-sm-column flex-md-column flex-lg-row align-items-center gap-3">
                  <div className="wholeselect">
                    <Select
                      value={userType || undefined}
                      onChange={useronChange}
                      className="selectdiv"
                      allowClear
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      placeholder="Select Grade"
                      style={{ width: "150px" }}
                      dropdownStyle={{ width: "150px" }}
                      options={[
                        {
                          value: "9-A",
                          label: "9-A",
                        },
                        {
                          value: "9-B",
                          label: "9-B",
                        },
                        {
                          value: "10-A",
                          label: "10-A",
                        },
                        {
                          value: "10-B",
                          label: "10-B",
                        },
                        {
                          value: "11-A",
                          label: "11-A",
                        },
                        {
                          value: "11-B",
                          label: "11-B",
                        },
                        {
                          value: "12-A",
                          label: "12-A",
                        },
                      ]}
                    />
                  </div>

                  <div className="btnaligwitselect">
                    {!isEdit ? (
                      <div>
                        <Popover placement="bottom" content={renderOptions()}>
                          <div className="addbtn">
                            <FontAwesomeIcon
                              icon={faPlus}
                              size="lg"
                              className="icon"
                              style={{ fontSize: "22px" }}
                            />
                            <p
                              style={{
                                margin: "0px",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Add
                            </p>
                          </div>
                        </Popover>
                      </div>
                    ) : null}
                  </div>
                  <Pagination
                    customClass="d-none d-xl-flex "
                    // class-page-show.style.display="none";
                    onlyPagtination={true}
                    data={userDataList}
                    onPageChange={(data) => {
                      setTableData(data);
                    }}
                    initialItemsPerPage={10}
                  />
                </div>
              </div>
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={tableData}
            className="table-datas tablecontent"
            pagination={false}
          />
          <Pagination
            data={userDataList}
            onPageChange={(data) => {
              setTableData(data);
            }}
            initialItemsPerPage={10}
          />
        </div>
      ) : (
        <div>
          <Studentopr
            record={selectedDate}
            onClose={onClose}
            type={teb}
            selectedId={selectedId}
          />
        </div>
      )}
      <Modal
        title={"Student Data"}
        className=""
        open={openUploaddataListing}
        width={1000}
        zIndex={100000}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancel}
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <div className="bulkuploadinnerbtn" style={{ marginBottom: "20px" }}>
          <div className="file-upload-wrapper">
            <label htmlFor="file-upload" className="custom-file-upload-button">
              Upload File
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".xlsx"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
          </div>
          <button
            onClick={() => {
              const link = document.createElement("a");
              link.href = "/path/to/your/template.xlsx"; // Replace with your file's path
              link.download = "Student_Data_Template.xlsx"; // File name for download
              link.click();
            }}
            className="download-template-button"
            style={{
              backgroundColor: "#00ADC4",
              color: "#fff",
              border: "none",
              padding: "8px 16px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Download Template
          </button>
        </div>
        {excelData?.length ? (
          <Table
            columns={xlsxColumns}
            dataSource={excelData}
            style={{ borderRadius: "2px" }}
            pagination={false}
          />
        ) : (
          <EmptyDataPage description="No records found" />
        )}
      </Modal>
    </div>
  );
}
export default Studentdetails;
