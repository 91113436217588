import React, { useState } from "react";
import {
  Input,
  Select,
  Upload,
  Button,
  Switch,
  DatePicker,
  Form,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import avatar from "../../Assets/png/avatar.jpg";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

function Driveropr({ record, onClose, type, selectedId }) {
  const [imageSrc, setImageSrc] = useState(avatar);

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageSrc(e.target.result);
    };
    reader.readAsDataURL(file);
    return false;
  };

  const MAX_FILE_SIZE = 500 * 1024; // 500 KB in bytes

  const handleBeforeUpload = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      message.error("File size exceeds 500 KB. Please upload a smaller file.");
      return Upload.LIST_IGNORE; // Prevent upload
    }
    return true; // Allow upload
  };

  return (
    <>
      {!selectedId ? (
        <div className="Accodamicwholeopr">
          {/* <h2>Add Driver</h2> */}
          <Form layout="vertical" style={{ borderRadius: "7px" }}>
            <div className="imagewholecontainer">
              <div className="imagedivcon">
                <div className="imagedivinnercon">
                  <img src={imageSrc} alt="image" />
                </div>
              </div>
              <div className="upload">
                <Upload
                  maxCount={1}
                  beforeUpload={handleImageUpload}
                  showUploadList={false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    className="uploadbackground"
                  >
                    Upload
                  </Button>
                </Upload>
              </div>
            </div>
            {/* <div className="imagedivcon">
              <div className="imagedivinnercon">
                <div>
                  <img
                    src={"https://i.pravatar.cc/40?img=12"}
                    alt="ii"
                    style={{ width: 212 }}
                  />
                  
                </div>
              </div>
            </div> */}
            <h3 className="textformat">Driver Information</h3>

            <div className="Driverform">
              <div className="driverrowform">
                <div className="row">
                  <div className="col-md-4">
                    <Form.Item
                      name="FullName"
                      label="FullName"
                      style={{ marginTop: "20px" }}
                      rules={[
                        { required: true, message: "Please Enter FullName!" },
                      ]}
                    >
                      <Input placeholder="FullName" className="inputcon" />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="Dateofbirth"
                      label="Date Of Birth"
                      style={{ marginTop: "20px" }}
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Date Of Birth!",
                        },
                      ]}
                    >
                      <Input placeholder="Date Of Birth" className="inputcon" />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="EmailId"
                      label="Email ID"
                      style={{ marginTop: "20px" }}
                      rules={[
                        { required: true, message: "Please Enter Email ID!" },
                      ]}
                    >
                      <Input placeholder="Email Id" className="inputcon" />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="address"
                      label="Address"
                      rules={[
                        { required: true, message: "Please Enter Address!" },
                      ]}
                    >
                      <Input placeholder="Address" className="inputcon" />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="Assigendvechcle"
                      label="Assigend Vechcle"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Assigend Vechcle!",
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                        placeholder="Select Assigend Vechcle"
                        className="inputbox"
                        style={{ width: "100%" }}
                      >
                        <Option value="TN 22 AL6789">TN 22 AL6789</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="Dateofjoining"
                      label="Date of Joining"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Date of Joining!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Date of Joining"
                        className="inputcon"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h3 className="textformat">Other Information</h3>
              <div className="Driverform">
                <div className="driverrowform">
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Item
                        name="License Number "
                        label="License Number "
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter License Number !",
                          },
                        ]}
                      >
                        <Input
                          placeholder="License Number"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        name="Aadhar Number"
                        label="Aadhar Number"
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Aadhar Number!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Aadhar Number"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        name="Assigned Route"
                        label=" Assigned Route"
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Assigned Route!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Assigned Route"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        name="Staff Mobile Number"
                        label="Staff Mobile Number"
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Staff Mobile Number!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Staff Mobile Number"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        name="Transportation Staff"
                        label="Transportation Staff"
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Transportation Staff!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Transportation Staff"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-buttons">
              <Button
                className="modifybutton"
                htmlType="submit"
                onClick={() => {}}
              >
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </div>
          </Form>
        </div>
      ) : (
        <div className="Accodamicwholeopr">
          <div className="imagedivcon">
            <div className="imagedivinnercon">
              <div>
                <img
                  src={"https://i.pravatar.cc/40?img=12"}
                  alt="ii"
                  style={{ width: 212 }}
                />
              </div>
            </div>
          </div>
          {/* <h3 style={{ fontFamily: 'poppins', fontWeight: '400', fontStyle: 'bold' }}>{type == 'edit' ? `Edit driver` : `View driver`}</h3> */}
          <h3 className="textformat">Driver Information</h3>
          <Form layout="vertical" style={{ borderRadius: "7px" }}>
            <div className="Driverform">
              <div className="driverrowform">
                <div className="row">
                  <div className="col-md-4">
                    <Form.Item
                      name="FullName"
                      label="Full Name"
                      style={{ marginTop: "20px" }}
                      rules={[
                        { required: true, message: "Please Enter Full Name!" },
                      ]}
                    >
                      <Input placeholder="FullName" className="inputcon" />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="Dateofbirth"
                      label="Date Of Birth"
                      style={{ marginTop: "20px" }}
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Date Of Birth!",
                        },
                      ]}
                    >
                      <Input placeholder="Date Of Birth" className="inputcon" />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="EmailId"
                      label="Email ID"
                      style={{ marginTop: "20px" }}
                      rules={[
                        { required: true, message: "Please Enter Email ID!" },
                      ]}
                    >
                      <Input placeholder="Email Id" className="inputcon" />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="address"
                      label="Address"
                      rules={[
                        { required: true, message: "Please Enter Address!" },
                      ]}
                    >
                      <Input placeholder="Address" className="inputcon" />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="Assigendvechcle"
                      label="Assigend Vechcle"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Assigend Vechcle!",
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                        placeholder="Select Assigend Vechcle"
                        className="inputbox"
                        style={{ width: "100%" }}
                      >
                        <Option value="highschool">Assigend Vechcle</Option>
                        <Option value="bachelor">Bachelor's Degree</Option>
                        <Option value="master">Master's Degree</Option>
                        <Option value="phd">PhD</Option>
                        <Option value="diploma">Diploma</Option>
                        <Option value="other">Other</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="Dateofjoining"
                      label="Date of Joining"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Date of Joining!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Date of Joining"
                        className="inputcon"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h3 className="textformat">Other Information</h3>
              <div className="Driverform">
                <div className="driverrowform">
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Item
                        name="License Number"
                        label="License Number"
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter License Number!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="License Number"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        name="Aadhar Number"
                        label="Aadhar Number"
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Aadhar Number!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Aadhar Number"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        name="Assigned Route"
                        label=" Assigned Route"
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Assigned Route!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Assigned Route"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        name="Staff Mobile Number"
                        label="Staff Mobile Number"
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Staff Mobile Number!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Staff Mobile Number"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        name="Transportation Staff"
                        label="Transportation Staff"
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Transportation Staff!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Transportation Staff"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <div className="form-buttons">
            {type == "edit" ? (
              <Button className="modifybutton">Update</Button>
            ) : null}
            <Button onClick={onClose}>Cancel</Button>
          </div>
        </div>
      )}
    </>
  );
}

export default Driveropr;
