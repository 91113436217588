import React, { useState, useEffect } from "react";
import { Form, Input, Button, Upload, Row, Col, message, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import LogoImg from "../../Assets/png/LogoImg.png";
import Logo from "../../Assets/png/icon.png";
import "./institute.css";
import { fetchApi } from "../../helper";
import axios from "axios";

const Institute = () => {
  const [form] = Form.useForm();
  const [imagesrc, setImageSrc] = useState();
  const [imagesrcs, setImageSrcs] = useState();
  const [fileName, setFileName] = useState("");
  // const [themeColor, setThemeColor] = useState("#00ADC4");
  const [isLoder, setIsLoder] = useState(false);
  const [schoollogo, setSchoolLogo] = useState({});
  const [schoolicon, setSchoolIcon] = useState({});
  const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);

  const [formData, setFormData] = useState({
    school_name: "",
    primaryemail: "",
    contactnumber: "",
    school_address: "",
    city: "",
    state: "",
    country: "",
    area: "",
    adminname: "",
    adminemail: "",
    adminphonenumber: "",
    schooltype: "",
    branchname: "",
    branchaddress: "",
    branchadminemail: "",
    branchadminmobilenumber: "",
    branchadminname: "",
    branchcontactnumber: "",
    password: "",
    schoollogo: {},
    schoolicon: {},
  });

  // useEffect(() => {
  //   let payload = {
  //     method: "GET",
  //     headers: { Authorization: `Bearer ${token}` },
  //     url: "/user",
  //   };

  //   fetchApi(payload).then((response) => {
  //     console.log(response, "response");
  //     // window.location.href = "/";
  //     message.success("Successfully Updated!");
  //   });
  //   const savedTheme = localStorage.getItem("themeColor");
  //   if (savedTheme) {
  //     setThemeColor(savedTheme);
  //   }
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
        url: "/user",
      };

      try {
        const response = await fetchApi(payload);
        if (response?.data) {
          const fetchedData = response?.data?.data;
          console.log(fetchedData, "134seudtyi");
          setFormData(fetchedData);

          // Populate form fields
          // form.setFieldsValue(fetchedData);
          // message.success("Data loaded successfully!");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to load data.");
      }
    };

    fetchData();
  }, []);
  console.log(formData, "13A4SRDYUI");
  const update = async () => {
    setIsLoder(true);

    let payload = {
      method: "put",
      url: `/user/${formData?.school_id}`,
      headers: { Authorization: `Bearer ${token}` },
      data: formData,
    };
    fetchApi(payload)
      .then((res) => {
        console.log(res, "fetchApisedrftgyhnjkml");
        setIsLoder(false);
      })
      .catch((err) => {
        console.error("Add institde Api Error : ", err);
      });
  };
  const onClose = () => {
    message.info("Action cancelled.");
  };
  const onFinish = (values) => {
    let obj = {
      name: formData.school_name ?? null,
      email: formData.primaryemail ?? null,
      password: formData.password ?? null,
      institutename: formData.school_name ?? null,
      school: {
        school_name: formData.school_name ?? null,
        primaryemail: formData.primaryemail ?? null,
        contactnumber: formData.contactnumber ?? null,
        school_address: formData.school_address ?? null,
        city: formData.city ?? null,
        state: formData.state ?? null,
        country: formData.country ?? null,
        area: formData.area ?? null,
        theme: formData.theme ?? null,
        adminname: formData.adminname ?? null,
        adminemail: formData.adminemail ?? null,
        adminphonenumber: formData.adminphonenumber ?? null,
        schooltype: formData.schooltype ?? null,
        branchname: formData.branchname ?? null,
        branchaddress: formData.branchaddress ?? null,
        branchcontactnumber: formData.branchcontactnumber ?? null,
        branchadminname: formData.branchadminname ?? null,
        branchadminemail: formData.branchadminemail ?? null,
        branchadminmobilenumber: formData.branchadminmobilenumber ?? null,
        schoollogo: formData.schoollogo ?? null,
        schoolicon: formData.schoolicon ?? null,
      },
    };

    console.log(values, "1234567890");
  };

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      // reader.result;
    };
    reader.readAsDataURL(file);
    return false;
  };
  const handleImageUploads = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      //    reader.result;
    };
    reader.readAsDataURL(file);
    return false;
  };

  const handleFileChange = (file) => {
    if (file && file.name) {
      setFileName(file.name);
    }
    return false;
  };
  const handleUpload = async (file) => {
    if (!file) {
      alert("Please select a file first");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      let payload = {
        method: "POST",
        url: "auth/file",
        data: formData,
      };
      fetchApi(payload).then((response) => {
        setSchoolLogo(response.fileInfo);
      });
    } catch (error) {
      console.error("Upload error:", error);
    }
  };
  const handleUpload1 = async (file) => {
    if (!file) {
      alert("Please select a file first");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      let payload = {
        method: "POST",
        url: "auth/file",
        data: formData,
      };
      fetchApi(payload).then((response) => {
        setSchoolIcon(response.fileInfo);
      });
    } catch (error) {
      console.error("Upload error:", error);
    }
  };
  // const handleThemeChange = (e) => {
  //   const newColor = e.target.value;
  //   setThemeColor(newColor);
  //   localStorage.setItem("themeColor", newColor);
  // };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  };
  console.log(
    formData,
    form,
    formData.schoolicon,
    formData.schoollogo,
    "jhgfhdf"
  );
  return (
    <Spin spinning={isLoder}>
      <div key={formData?.school_id} className="institutecontainer">
        <div
          className="boxshadowinstitute"
          style={{
            borderRadius: "10px",
            padding: "30px 15px",
            boxshadow:
              "18px 18px 30px 0px #D1D9E640,-18px -18px 30px 0px #FFFFFF",
          }}
        >
          <div className="row">
            <div className="col-md-6">
              <div>
                <p
                  style={{
                    fontSize: "16px",
                    // color: "#5D5C5C",
                    fontWeight: "600",
                  }}
                >
                  School Logo
                </p>
              </div>
              <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-3">
                <div className="imageshadow">
                  <div className="Wholeinstitutelogo">
                    <img
                      src={formData?.schoollogo.filePath}
                      alt={formData?.schoollogo.originalName}
                      className="Wholeinstitutelogoimage"
                    />
                  </div>
                </div>

                <div className="calss-upload-logo">
                  <Upload
                    beforeUpload={handleUpload}
                    showUploadList={false}
                    name="schoollogo"
                    defaultValue={formData?.schoollogo.filePath}
                  >
                    <Button
                      style={{
                        borderRadius: "8px",
                        background: "#F6F4F4",
                        fontSize: "16px",
                        color: "#5D5C5C",
                        fontWeight: "600",
                        border: "#F0F0F0",
                      }}
                    >
                      Replace Log
                    </Button>
                  </Upload>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <p
                  style={{
                    fontSize: "16px",
                    // color: "#5D5C5C",
                    fontWeight: "600",
                  }}
                >
                  School Icon
                </p>
              </div>
              <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-3">
                <div className="imageshadow">
                  <div className="Wholeinstitutelogo">
                    <img
                      className="Wholeinstitutelogoimage"
                      src={formData?.schoolicon.filePath}
                      alt={formData?.schoolicon.originalName}
                    />
                  </div>
                </div>

                <div className="calss-upload-logo">
                  <Upload
                    beforeUpload={handleUpload1}
                    showUploadList={false}
                    name="schoolicon"
                  >
                    <Button
                      style={{
                        borderRadius: "8px",
                        background: "#F6F4F4",
                        fontSize: "16px",
                        color: "#5D5C5C",
                        fontWeight: "600",
                        border: "#F0F0F0",
                      }}
                    >
                      Replace Icon
                    </Button>
                  </Upload>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Form
          form={form}
          // key={formData.school_name}
          layout="vertical"
          onFinish={onFinish}
          style={{ borderRadius: "5px" }}
        >
          <p
            style={{
              fontSize: "24px",
              marginTop: "20px",
              marginLeft: "20px",
              fontWeight: "500",
            }}
          >
            School / Institute Information
          </p>
          <div
            className="boxshadowinstitute"
            style={{
              borderRadius: "10px",
              padding: "10px",
              boxshadow:
                "18px 18px 30px 0px #D1D9E640,-18px -18px 30px 0px #FFFFFF",
            }}
          >
            <div className="admin-contect">
              <div className="row">
                <div className="col-md-12">
                  <Form.Item
                    label="School Name"
                    name="school_name"
                    // initialValue={formData?.school_name}
                    rules={[
                      { required: true, message: " School Name is required!" },
                    ]}
                  >
                    <Input
                      onChange={(e) => handleChange(e)}
                      size="large"
                      placeholder="Enter School Name"
                      style={{ width: "100%" }}
                      name="school_name"
                      defaultValue={formData?.school_name}
                      // key={formData?.school_name}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  label="Primary Email"
                  name="primaryemail"
                  rules={[
                    { required: true, message: "Primary Email is required!" },
                    { type: "email", message: "Enter a valid email!" },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter Primary Email"
                    style={{ width: "100%" }}
                    name="primaryemail"
                    defaultValue={formData?.primaryemail}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  label="Contact Number"
                  name="contactnumber"
                  rules={[
                    { required: true, message: "Contact Number is required!" },
                    {
                      pattern: /^\d{10}$/,
                      message: "Enter a valid 10-digit Contact Number!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter Contact Number"
                    style={{ width: "100%" }}
                    name="contactnumber"
                    defaultValue={formData?.contactnumber}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <Col span={24}>
                <Form.Item
                  label="School Address"
                  name="school_address"
                  rules={[
                    { required: true, message: "School Address is required!" },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter School Address"
                    style={{ width: "100%" }}
                    name="school_address"
                    defaultValue={formData?.school_address}
                  />
                </Form.Item>
              </Col>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Form.Item
                  label="City"
                  name="city"
                  rules={[{ required: true, message: "City is required!" }]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter City"
                    style={{ width: "100%" }}
                    defaultValue={formData?.city}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  label="State"
                  name="state"
                  rules={[{ required: true, message: "State is required!" }]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter State"
                    style={{ width: "100%" }}
                    name="state"
                    defaultValue={formData?.state}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[{ required: true, message: "Country is required!" }]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter Country"
                    style={{ width: "100%" }}
                    name="country"
                    defaultValue={formData?.country}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  label="Area"
                  name="area"
                  rules={[{ required: true, message: "Area is required!" }]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter Area"
                    style={{ width: "100%" }}
                    name="area"
                    defaultValue={formData?.area}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Theme" name="theme">
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "10px",
                    }}
                  >
                    <Input
                      type="color"
                      name="theme"
                      // value={themeColor}
                      // onChange={handleThemeChange}
                      className="themehandlerinput"
                      variant="borderless"
                    />
                    <Input
                      onChange={(e) => handleChange(e)}
                      className="themeinputbox"
                      variant="borderless"
                      name="theme"
                      defaultValue={formData?.theme}
                    />
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
          <p
            style={{
              fontSize: "24px",
              marginTop: "20px",
              marginLeft: "20px",
              fontWeight: "500",
            }}
          >
            Admin Detail
          </p>

          <div
            style={{
              padding: "10px",
              marginTop: "10px",
              borderRadius: "10px",
            }}
            className="boxshadowinstitute"
          >
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  label="Admin Name"
                  name="adminname"
                  defaultValue={formData?.adminname}
                  rules={[
                    { required: true, message: "Admin Name is required!" },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter Admin Name"
                    style={{ width: "100%" }}
                    name="adminname"
                    defaultValue={formData?.adminname}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  label="Admin Email"
                  name="adminemail"
                  rules={[
                    { required: true, message: "Admin Email is required!" },
                    { type: "email", message: "Enter a valid Email!" },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter Admin Email"
                    style={{ width: "100%" }}
                    name="adminemail"
                    defaultValue={formData?.adminemail}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  label="Admin Phone Number"
                  name="adminphonenumber"
                  rules={[
                    {
                      required: true,
                      message: "Admin Phone Number is required!",
                    },
                    {
                      pattern: /^\d{10}$/,
                      message: "Enter a valid 10-digit Contact Number!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter Admin Phone Number"
                    style={{ width: "100%" }}
                    name="adminphonenumber"
                    defaultValue={formData?.adminphonenumber}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  label="School Type"
                  name="schooltype"
                  rules={[
                    { required: true, message: "School Type is required!" },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter School Type"
                    style={{ width: "100%" }}
                    name="schooltype"
                    defaultValue={formData?.schooltype}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <p
            style={{
              fontSize: "24px",
              marginTop: "20px",
              marginLeft: "20px",
              fontWeight: "500",
            }}
          >
            Branch Detail
          </p>
          <div
            className="boxshadowinstitute"
            style={{
              borderRadius: "10px",
              padding: "10px",
              marginTop: "10px",
              boxshadow:
                "18px 18px 30px 0px #D1D9E640,-18px -18px 30px 0px #FFFFFF",
            }}
          >
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  label="Branch Name"
                  name="branchname"
                  rules={[
                    { required: true, message: "Branch Name is required!" },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Branch Name"
                    name="branchname"
                    style={{ width: "100%" }}
                    defaultValue={formData?.branchname}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  label="Branch Address"
                  name="branchaddress"
                  rules={[
                    { required: true, message: "Branch Address is required!" },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter Branch Address"
                    style={{ width: "100%" }}
                    name="branchaddress"
                    defaultValue={formData?.branchaddress}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  label="Branch Contact Number"
                  name="branchcontactnumber"
                  rules={[
                    {
                      required: true,
                      message: "Branch Contact Number is required!",
                    },
                    {
                      pattern: /^\d{10}$/,
                      message: "Enter a valid 10-digit Contact Number!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter Branch Contact Number"
                    style={{ width: "100%" }}
                    name="branchcontactnumber"
                    defaultValue={formData?.branchcontactnumber}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  label="Branch Admin Name"
                  name="branchadminname"
                  rules={[
                    {
                      required: true,
                      message: "Branch Admin Name is required!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    name="branchadminname"
                    placeholder="Enter Branch Admin Name"
                    style={{ width: "100%" }}
                    defaultValue={formData?.branchadminname}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  label="Branch Admin Email"
                  name="branchadminemail"
                  rules={[
                    {
                      required: true,
                      message: "Branch Admin Email is required!",
                    },
                    { type: "email", message: "Enter a valid email!" },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter Branch Admin Email"
                    style={{ width: "100%" }}
                    name="branchadminemail"
                    defaultValue={formData?.branchadminemail}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  label="Branch Admin Mobile Number"
                  name="branchadminmobilenumber"
                  rules={[
                    {
                      required: true,
                      message: "Branch Admin Mobile Number is required!",
                    },
                    {
                      pattern: /^\d{10}$/,
                      message: "Enter a valid 10-digit Contact Number!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => handleChange(e)}
                    size="large"
                    placeholder="Enter Branch Admin Mobile Number"
                    style={{ width: "100%" }}
                    name="branchadminmobilenumber"
                    defaultValue={formData?.branchadminmobilenumber}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="form-buttons">
            <div>
              <Form.Item>
                <Button
                  // type="primary"
                  htmlType="submit"
                  className="ant-btn-variant-solid"
                  style={{ marginRight: "5px" }}
                  onClick={update}
                >
                  Update
                </Button>
                <Button
                  // type="primary"
                  htmlType="button"
                  className="ant-btn-variant-outlined"
                  onClick={onClose}
                  style={{
                    background: "#198754",
                    borderColor: "white",
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default Institute;
