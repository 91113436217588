import React, { useState } from "react";
import { Table, Tag } from "antd";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accdamicopr from "../../Component/Acadamicemployeecev/Acadamicopr";
import Pagination from "../pagination/index";

const alldatas = [
  {
    key: 46,
    teachername: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    phoneNumber: "+91 9876543210",
    status: "Active",
    Assignedclass: "11-A",
    tags: ["GK", "English", "Maths", "Computer Science"],

    avatarUrl: null,
  },
  {
    key: 47,
    teachername: "Aisha Fatima",
    employeeID: "ENG00202",
    role: "Teacher",
    phoneNumber: "+91 9876543211",
    status: "Inactive",
    Assignedclass: "12-A",
    tags: ["Tamil", "Science"],

    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 48,
    teachername: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    phoneNumber: "+91 9876543212",
    status: "Active",
    Assignedclass: "10-A",
    tags: ["Apptitude", "Maths"],

    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 49,
    teachername: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    phoneNumber: "+91 9876543212",
    status: "Active",
    Assignedclass: "12-A",
    tags: ["English", "Science"],

    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
];

function Teacher({ setIsOpen, isOpen, settabVisible }) {
  const [userArray, setUserArray] = useState(alldatas);
  const [moduleOpen, setModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [type, setType] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [tableData, setTableData] = useState([]);

  const openOnChange = (value, type) => {
    if (type === "view") {
      setType(type);
      setModuleOpen(true);
      setSelectedDate(value);
      settabVisible(false);
    } else {
      setModuleOpen(false);
      setSelectedDate({});
    }
  };

  const onClose = () => {
    setselectedId("");
    setModuleOpen(false);
    settabVisible(true);

    setSelectedDate({});
  };

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    {
      title: "Teacher",
      dataIndex: "teachername",
      align: "center",
    },
    {
      title: "Teacher ID",
      dataIndex: "employeeID",
      align: "center",
    },
    {
      title: "Assigned class",
      dataIndex: "Assignedclass",
      align: "center",
    },
    {
      title: "Subject",
      dataIndex: "tags",
      align: "center",
      render: (tags) => (
        <>
          {Array.isArray(tags) && tags.length > 0 ? (
            tags.map((tag) => {
              let color = tag.length > 5 ? "geekblue" : "green";
              if (tag === "loser") {
                color = "volcano";
              }
              return (
                <Tag
                  key={tag}
                  style={{
                    border: "1px dashed #00ADC4",
                    background: "#fff",
                    display: "inline-block",
                    padding: "5px 10px",
                    margin: "5px",
                  }}
                >
                  {tag.toUpperCase()}
                </Tag>
              );
            })
          ) : (
            <span>No tags available</span>
          )}
        </>
      ),
    },

    {
      title: "Action",
      dataIndex: "status",
      align: "center",

      render: (_, record) => (
        <div>
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
        </div>
      ),
    },
  ];

  console.log(moduleOpen, "moduleOpen");

  return (
    <div>
      {!moduleOpen ? (
        <div className="tablecontainer">
          <Table
            columns={columns}
            dataSource={tableData}
            tableLayout="fixed"
            className=""
            pagination={false}
          />
          <Pagination
            data={userArray}
            onPageChange={(data) => {
              setTableData(data);
            }}
            initialItemsPerPage={10}
          />
        </div>
      ) : (
        <Accdamicopr
          data={selectedDate}
          isOpen={moduleOpen}
          type={type}
          setIsOpen={setModuleOpen}
          onClose={onClose}
          record={selectedDate}
          selectedId={selectedId}
          settabVisible={settabVisible}
        />
      )}
    </div>
  );
}

export default Teacher;
