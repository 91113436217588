import React, { useState, useEffect } from "react";
import { Table, Input, Space, Avatar, Tag, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Pagination from "../pagination/index";
import TableSortArrows from "../../Component/tableSortArrows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;

const alldatas = [
  {
    key: 46,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    uniqueno: "12345",
    Gradesec: "9-B",
    phoneNumber: "+91 9876543210",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },

  {
    key: 47,
    name: "Aisha Fatima",
    employeeID: "ENG00202",
    uniqueno: "12634",
    role: "Teacher",
    Gradesec: "9-A",
    phoneNumber: "+91 9876543211",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 48,
    name: "Arjun Singh",
    employeeID: "ENG00203",
    role: "Teacher",
    Gradesec: "10-B",
    uniqueno: "129934",
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 49,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    uniqueno: "1234",
    Gradesec: "10-A",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 50,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    Gradesec: "11-A",
    phoneNumber: "+91 9876543210",
    status: "Active",
    uniqueno: "123004",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 51,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    Gradesec: "11-B",
    uniqueno: "12300004",
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
  {
    key: 52,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: "Teacher",
    uniqueno: "12345",
    Gradesec: "9-B",
    phoneNumber: "+91 9876543210",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
  },
];

function Studenttable() {
  const [tableData, setTableData] = useState([]);
  const [userArray, setUserArray] = useState(alldatas);
  const [search, setSearch] = useState("");
  const [totalcount, setTotalcount] = useState(userArray.length);
  const [userType, setUserType] = useState("");

  const useronChange = async (value) => {
    if (value) {
      let finded = alldatas.filter((a) => a.Gradesec == value);
      setUserArray(finded);
      setTotalcount(finded.length);
      setUserType(value);
    } else {
      setUserArray(alldatas);
      setTotalcount(alldatas.length);
      setUserType("");
    }
  };

  useEffect(() => {
    setTableData(userArray); // Initialize the table data with all users
  }, [userArray]);

  const searchOnChange = (value) => {
    const filteredData = userArray.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.employeeID.toLowerCase().includes(value.toLowerCase())
    );
    setTableData(filteredData);
    setSearch(value);
    setTotalcount(filteredData.length);
  };

  const columns = [
    {
      title: "Student Name",
      dataIndex: "name",
      render: (text, record) => (
        <Space>
          <Avatar src={record.avatarUrl} />
          {text}
        </Space>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Unique No",
      dataIndex: "uniqueno",
    },
    {
      title: "Admission NO",
      dataIndex: "employeeID",
    },
    {
      title: "Grade & sec",
      dataIndex: "Gradesec",
      sorter: (a, b) => a.Gradesec.localeCompare(b.Gradesec),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "#EC7C74"}`,
            color: status === "Active" ? "#fff" : "#fff",
            background:
              status === "Active"
                ? "linear-gradient(98.22deg, #00ADC4 -13.56%, #00535E 133.98%)"
                : "#EC7C74",
            width: "90px",
            textAlign: "center",
            padding: "5px 0",
            borderRadius: "4px",
          }}
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  return (
    <div>
      <div>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <div className="search-id">
            <Input
              onChange={(e) => searchOnChange(e.target.value)}
              value={search}
              placeholder="Search by Student or ID"
              prefix={<SearchOutlined />}
              className="seachAccadamicemployee"
            />
          </div>
          <div className="wholeselect">
            <Select
              value={userType || undefined}
              onChange={useronChange}
              className="selectdiv"
              allowClear
              suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
              placeholder="Select Grade"
              style={{ width: "150px" }}
              dropdownStyle={{ width: "150px" }}
              options={[
                {
                  value: "9-A",
                  label: "9-A",
                },
                {
                  value: "9-B",
                  label: "9-B",
                },
                {
                  value: "10-A",
                  label: "10-A",
                },
                {
                  value: "10-B",
                  label: "10-B",
                },
                {
                  value: "11-A",
                  label: "11-A",
                },
                {
                  value: "11-B",
                  label: "11-B",
                },
                {
                  value: "12-A",
                  label: "12-A",
                },
              ]}
            />
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          tableLayout="fixed"
          className="table-datas"
          pagination={false}
        />
        <Pagination
          data={userArray}
          onPageChange={(data) => {
            setTableData(data);
          }}
          initialItemsPerPage={10}
        />
      </div>
    </div>
  );
}

export default Studenttable;
