// import React from "react";
// import "./style/variable.css";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Home from "./layout/base/Home.jsx";
// import AcademicEmployee from "./Pages/Acadamicemployee.jsx";
// import Login from "./Pages/Login.jsx";
// import Register from "./Pages/Register.jsx";
// import Usermanagementcon from "../src/Pages/Usermanagementcon.jsx";
// import Dashboard from "./Pages/Dashboard.jsx";
// import Drivers from "./Pages/Drivers.jsx";
// import Student from "./Pages/Student.jsx";
// import Events from "./Pages/Events.jsx";
// import Sidebar from "./layout/base/Sidebar.jsx";
// import Circular from "./Pages/Circular.jsx";
// import Setting from "./Pages/setting.jsx";
// import Parent from "./Pages/Parent.jsx";
// import DepartmentAdmincon from "./Pages/DepartmentAdmincon.jsx";
// import Fees from "./Pages/Fees.jsx";
// import Report from "./Pages/Report.jsx";
// import Pagination from "./Component/pagination/index.jsx";

// function App() {
//   const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);

//   return (
//     // <div>
//     //   <Pagination />
//     // </div>
//     <Router>
//       <Routes>
//         {/* Routes for authenticated users */}
//         {token ? (
//           <Route path="/" element={<Sidebar />}>
//             <Route index element={<Dashboard />} /> {/* Default route */}
//             <Route path="academic-employee" element={<AcademicEmployee />} />
//             <Route path="user-management" element={<Usermanagementcon />} />
//             <Route path="drivers" element={<Drivers />} />
//             <Route path="students" element={<Student />} />
//             <Route path="parent" element={<Parent />} />
//             <Route path="events" element={<Events />} />
//             <Route path="fees" element={<Fees />} />
//             <Route path="reports" element={<Report />} />
//             <Route path="circular" element={<Circular />} />
//             <Route path="setting" element={<Setting />} />
//             <Route path="department-admin" element={<DepartmentAdmincon />} />
//           </Route>
//         ) : (
//           // Routes for unauthenticated users
//           <>
//             <Route path="/" element={<Login />} />
//             <Route path="register" element={<Register />} />
//           </>
//         )}
//       </Routes>
//     </Router>
//   );
// }

// export default App;

import React from "react";
import "./style/variable.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { token, decodeToken, getUserData } from "./helper";
import Home from "./layout/base/Home.jsx";
import AcademicEmployee from "./Pages/Acadamicemployee.jsx";
import Login from "./Pages/Login.jsx";
import Register from "./Pages/Register.jsx";
import Usermanagementcon from "../src/Pages/Usermanagementcon.jsx";
import Dashboard from "./Pages/Dashboard.jsx";
import Drivers from "./Pages/Drivers.jsx";
import Student from "./Pages/Student.jsx";
import Events from "./Pages/Events.jsx";
import Sidebar from "./layout/base/Sidebar.jsx";
import Circular from "./Pages/Circular.jsx";
import Setting from "./Pages/setting.jsx";
import Parent from "./Pages/Parent.jsx";
import DepartmentAdmincon from "./Pages/DepartmentAdmincon.jsx";
import Fees from "./Pages/Fees.jsx";
import Report from "./Pages/Report.jsx";
import Classroom from "./Pages/Teacher/Classroom.jsx";
import Grades from "./Pages/Teacher/Grades.jsx";
import Leave from "./Pages/Teacher/Leave.jsx";
import Studentattenance from "./Pages/Teacher/StudentAttenance.jsx";
import Coursemanagemant from "./Pages/Teacher/Coursemanagemant.jsx";
import ForgetPassword from "./Pages/Forgetpassword.jsx";

function App() {
  console.log(token, "qwerty");
  // const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);
  let userRole = decodeToken(token)?.role;
  console.log(userRole, "rtyuio");

  const ProtectedRoute = ({ element, allowedRoles }) => {
    return allowedRoles.includes(userRole) ? element : <Navigate to="/" />;
  };

  return (
    <Router>
      <Routes>
        {token ? (
          <Route path="/" element={<Sidebar />}>
            <Route index element={<Dashboard />} />
            <Route
              path="academic-employee"
              element={
                <ProtectedRoute
                  element={<AcademicEmployee />}
                  allowedRoles={["admin", "super-admin"]}
                />
              }
            />
            <Route
              path="user-management"
              element={
                <ProtectedRoute
                  element={<Usermanagementcon />}
                  allowedRoles={["super-admin"]}
                />
              }
            />
            <Route
              path="drivers"
              element={
                <ProtectedRoute
                  element={<Drivers />}
                  allowedRoles={["super-admin"]}
                />
              }
            />
            <Route
              path="students"
              element={
                <ProtectedRoute
                  element={<Student />}
                  allowedRoles={["super-admin"]}
                />
              }
            />
            <Route
              path="parent"
              element={
                <ProtectedRoute
                  element={<Parent />}
                  allowedRoles={["super-admin"]}
                />
              }
            />
            <Route
              path="events"
              element={
                <ProtectedRoute
                  element={<Events />}
                  allowedRoles={["super-admin"]}
                />
              }
            />
            <Route
              path="fees"
              element={
                <ProtectedRoute
                  element={<Fees />}
                  allowedRoles={["super-admin"]}
                />
              }
            />
            <Route
              path="reports"
              element={
                <ProtectedRoute
                  element={<Report />}
                  allowedRoles={["super-admin"]}
                />
              }
            />
            <Route
              path="circular"
              element={
                <ProtectedRoute
                  element={<Circular />}
                  allowedRoles={["super-admin"]}
                />
              }
            />
            <Route
              path="setting"
              element={
                <ProtectedRoute
                  element={<Setting />}
                  allowedRoles={["super-admin", "Teacher"]}
                />
              }
            />
            <Route
              path="department-admin"
              element={
                <ProtectedRoute
                  element={<DepartmentAdmincon />}
                  allowedRoles={["super-admin"]}
                />
              }
            />
            <Route
              path="classroom"
              element={
                <ProtectedRoute
                  element={<Classroom />}
                  allowedRoles={["Teacher"]}
                />
              }
            />
            <Route
              path="grade"
              element={
                <ProtectedRoute
                  element={<Grades />}
                  allowedRoles={["Teacher"]}
                />
              }
            />
            <Route
              path="Leave"
              element={
                <ProtectedRoute
                  element={<Leave />}
                  allowedRoles={["Teacher"]}
                />
              }
            />
            <Route
              path="studentattenance"
              element={
                <ProtectedRoute
                  element={<Studentattenance />}
                  allowedRoles={["Teacher"]}
                />
              }
            />
            <Route
              path="coursemanagemant"
              element={
                <ProtectedRoute
                  element={<Coursemanagemant />}
                  allowedRoles={["Teacher"]}
                />
              }
            />
          </Route>
        ) : (
          <Route>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgetpassword" element={<ForgetPassword />} />
          </Route>
        )}
      </Routes>
    </Router>
  );
}

export default App;
