import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Select,
  Row,
  notification,
  Form,
  Col,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// import './subject.css'
// import { fetchApi, customPagination } from "../../helper";
import Pagination from "../pagination/index";

const { confirm } = Modal;

const initialData = [
  {
    key: 46,

    Departmentname: "primary",
    Description: "From 1 to 5",
    status: "Active",
  },
  {
    key: 47,

    Departmentname: "primary",
    Description: "From 1 to 5",
    status: "Active",
  },
  {
    key: 48,

    Departmentname: "primary",
    Description: "From 1 to 5",
    status: "Active",
  },
  {
    key: 49,

    Departmentname: "Higher Secondary",
    Description: "From 1 to 5",
    status: "Active",
  },
  {
    key: 50,

    Departmentname: "Higher Secondary",
    Description: "From 1 to 5",
    status: "Active",
  },
  {
    key: 51,

    Departmentname: "primary",
    Description: "From 1 to 5",
    status: "Active",
  },
  {
    key: 52,

    Departmentname: "secondary",
    Description: "From 1 to 5",
    status: "Active",
  },
];

function Department() {
  const [userData, setUserData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);
  const [totalCount, setTotalCount] = useState(initialData.length);
  const [userArray, setUserArray] = useState([]);
  // const [userData, setUserData] = useState(initialData);
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");
  const [userDataList, setUserDataList] = useState(initialData);

  const [openModal, setOpenModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const resetModalState = () => {
    setEditingRecord(null);
    form.resetFields();
    setOpenModal(false);
  };

  const openEditModal = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      name: record.name,
      subjectID: record.subjectID,
      Grade: record.Grade,
      group: record.group,
    });
    setOpenModal(true);
  };

  const confirmDelete = (record) => {
    confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      onOk() {
        setUserData((prev) => prev.filter((item) => item.key !== record.key));
        setTotalCount((prev) => prev - 1);
        notification.success({ message: "Record deleted successfully!" });
      },
    });
  };

  const handleSearch = (value) => {
    const filtered = initialData.filter((item) =>
      item.Departmentname.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setTotalCount(filtered.length);
  };
  const useronChange = (value) => {
    if (value) {
      const filtered = initialData.filter(
        (a) => a.Gradesec?.toLowerCase() === value?.toLowerCase()
      );
      setUserDataList(filtered);
      setTotalcount(filtered.length);
      setUserType(value);
    } else {
      setUserDataList(initialData);
      setTotalcount(initialData.length);
      setUserType("");
    }
  };
  const searchOnChange = (value) => {
    const data = initialData.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.admissionID.toLowerCase().includes(value.toLowerCase())
    );
    setUserDataList(data);
    setSearch(value);
    setTotalcount(data.length);
  };
  const handleSave = (values) => {
    if (editingRecord) {
      // Edit logic
      setUserData((prev) =>
        prev.map((item) =>
          item.key === editingRecord.key ? { ...item, ...values } : item
        )
      );
      notification.success({ message: "Record updated successfully!" });
    } else {
      // Add logic
      const newRecord = { ...values, key: Date.now() };
      setUserData((prev) => [...prev, newRecord]);
      notification.success({ message: "Record added successfully!" });
    }
    resetModalState();
  };

  useEffect(() => {
    setFilteredData(userData);
    setTotalCount(userData.length);
  }, [userData]);

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    { title: "Department Name", dataIndex: "Departmentname" },
    { title: "Description", dataIndex: "Description" },
    {
      title: "Actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            onClick={() => openEditModal(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => confirmDelete(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
        </div>
      ),
    },
  ];

  const objectLength = Object.keys(initialData).length;

  return (
    <>
      <div className="tablecontainer">
        <div className="tableinnercontainer">
          <div className="d-md-flex justify-content-between align-items-center ">
            <div className="search-id">
              <Input
                placeholder="Search Subjectname"
                prefix={<SearchOutlined />}
                onChange={(e) => handleSearch(e.target.value)}
                className="seachAccadamicemployee"
              />
            </div>

            <div className="d-lg-flex align-items-center ">
              <div className="d-flex justify-content-center px-3">
                <div className="btnacccon">
                  <div className="addbtn" onClick={() => setOpenModal(true)}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="lg"
                      className="icon"
                      style={{ fontSize: "22px" }}
                    />
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Add
                    </p>
                  </div>
                  <Pagination
                    customClass="d-none d-xl-flex"
                    // class-page-show.style.display="none";
                    onlyPagtination={true}
                    data={userDataList}
                    onPageChange={(data) => {
                      setTableData(data);
                    }}
                    initialItemsPerPage={10}
                  />
                </div>
              </div>
              {/* <Pagination
                className="custom-pagination"
                total={objectLength}
                defaultCurrent={1}
                defaultPageSize={10}
                current={currentPage}
                pageSize={pageSizeTotal}
                onChange={pageSizeChange}
                itemRender={customPagination}
              /> */}
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          // pagination={{
          //   className: "custom-pagination",
          //   defaultCurrent: 1,
          //   total: objectLength,
          //   current: currentPage,
          //   pageSize: pageSizeTotal,
          //   onChange: pageSizeChange,
          //   showSizeChanger: true,
          //   pageSizeOptions: ["5", "10", "20", "50"],
          //   itemRender: customPagination,
          //   showTotal: (total, range) => (
          //     <div
          //       className=" d-sm-flex d-none"
          //       style={{
          //         left: 0,
          //         fontSize: "14px",
          //         color: "#595959",
          //         position: "absolute",
          //       }}
          //     >
          //       Showing {range[1]} of {total}
          //     </div>
          //   ),
          // }}
          pagination={false}
          style={{ marginTop: "20px", borderRadius: "8px" }}
        />
        <Pagination
          data={filteredData}
          onPageChange={(data) => {
            setTableData(data);
          }}
          initialItemsPerPage={10}
        />
      </div>

      <Modal
        open={openModal}
        onCancel={resetModalState}
        onOk={() => form.submit()}
        maskClosable={false}
        okText="Save"
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <h3 style={{ color: "#00ADC4", textAlign: "center" }}>
          {editingRecord ? "Edit Department" : "Add Department"}
        </h3>
        <hr />
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Depatment Name"
                name="Departmentname"
                rules={[
                  { required: true, message: "Please enter Depatment Name!" },
                ]}
              >
                <Input className="inputbox" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Description"
                name="Description"
                rules={[
                  { required: true, message: "Please enter Description!" },
                ]}
              >
                <Input className="inputbox" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default Department;
